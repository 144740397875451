"use client";

import { useState, useEffect } from "react";
import "../../Assests/css/CookieConsent.css";

const CookieConsent = ({
  position = "bottom",
  buttonText = "Accept All Cookies",
  customizeButtonText = "Manage Cookies",
  declineButtonText = "Necessary Cookies Only",
  cookieName = "cookie-consent",
  expires = 365,
  onAccept = () => {},
  onDecline = () => {},
  onCustomize = () => {},
  isOpen = false, // New prop to control visibility from parent
  onClose = () => {}, // New prop to notify parent when consent is closed
}) => {
  const [visible, setVisible] = useState(false);
  const [showPreferences, setShowPreferences] = useState(false);
  const [preferences, setPreferences] = useState({
    necessary: true, // Always required
    functional: true,
    analytics: true,
    marketing: true,
  });

  useEffect(() => {
    // Check if user has already made a choice
    const consentValue = localStorage.getItem(cookieName);
    if (!consentValue) {
      // If no consent found, show the banner
      setVisible(true);
      // Add class to body to prevent scrolling
      document.body.classList.add("cookie-consent-open");
    }
  }, [cookieName]);

  // New effect to handle external control of visibility
  useEffect(() => {
    if (isOpen) {
      setVisible(true);
      document.body.classList.add("cookie-consent-open");
    }
  }, [isOpen]);

  // Load saved preferences if available
  useEffect(() => {
    const savedConsent = localStorage.getItem(cookieName);
    if (savedConsent) {
      try {
        const parsedConsent = JSON.parse(savedConsent);
        if (parsedConsent.value) {
          setPreferences(parsedConsent.value);
        }
      } catch (error) {
        console.error("Error parsing saved cookie consent", error);
      }
    }
  }, [cookieName]);

  const acceptAll = () => {
    setPreferences({
      necessary: true,
      functional: true,
      analytics: true,
      marketing: true,
    });

    saveConsent({
      necessary: true,
      functional: true,
      analytics: true,
      marketing: true,
    });

    closeConsent();
    onAccept(preferences);
  };

  const declineAll = () => {
    const minimalConsent = {
      necessary: true,
      functional: false,
      analytics: false,
      marketing: false,
    };

    setPreferences(minimalConsent);
    saveConsent(minimalConsent);

    closeConsent();
    onDecline(minimalConsent);
  };

  const savePreferences = () => {
    saveConsent(preferences);
    closeConsent();
    onCustomize(preferences);
  };

  const closeConsent = () => {
    setVisible(false);
    setShowPreferences(false);
    // Remove class from body to allow scrolling
    document.body.classList.remove("cookie-consent-open");
    // Notify parent component
    onClose();
  };

  const saveConsent = (consentData) => {
    // Set expiration date
    const date = new Date();
    date.setTime(date.getTime() + expires * 24 * 60 * 60 * 1000);

    // Save to localStorage
    localStorage.setItem(
      cookieName,
      JSON.stringify({
        value: consentData,
        expiry: date.toISOString(),
      })
    );
  };

  const handlePreferenceChange = (key) => {
    setPreferences((prev) => ({
      ...prev,
      [key]: !prev[key],
    }));
  };

  const openPreferences = () => {
    setShowPreferences(true);
  };

  // If not visible, don't render anything
  if (!visible) {
    return null;
  }

  const positionClass = `cookie-consent-${position}`;

  return (
    <>
      <div className="cookie-consent-overlay"></div>
      <div className={`cookie-consent ${positionClass}`}>
        <div className="container-lg">
          {!showPreferences ? (
            // Main consent banner
            <div className="card">
              <div className="card-body">
                <h2 className="card-title">Cookie Consent</h2>
                <p className="card-text">
                  We use cookies to enhance your browsing experience, serve
                  personalized ads or content, and analyze our traffic. By
                  clicking "Accept All", you consent to our use of cookies. For
                  more information, please review our
                  <a href="/terms_of_use" target="_blank">
                    {" "}
                    Terms and Conditions
                  </a>{" "}
                  and{" "}
                  <a href="/privacy_policy" target="_blank">
                    Privacy Policy
                  </a>
                  .
                </p>
                <div className="btn-group cookie-btn">
                  <button
                    onClick={acceptAll}
                    className="btn btn-primary"
                    style={{ borderRadius: "10px" }}
                  >
                    Accept All Cookies
                  </button>
                  <button
                    onClick={openPreferences}
                    className="btn btn-outline-secondary"
                    style={{ borderRadius: "10px" }}
                  >
                    Manage Cookies
                  </button>
                  <button
                    onClick={declineAll}
                    className="btn btn-outline-secondary"
                    style={{ borderRadius: "10px" }}
                  >
                    Necessary Cookies Only
                  </button>
                </div>
              </div>
            </div>
          ) : (
            // Preferences panel
            <div className="card">
              <div className="card-body">
                <h2 className="card-title">Cookie Preferences</h2>
                <p className="card-text mb-3">
                  Customize your cookie preferences below. Some cookies are
                  necessary for the website to function properly.
                </p>

                <div className="cookie-preferences">
                  <div className="preference-item">
                    <div>
                      <h6 className="card-subtitle">Necessary Cookies</h6>
                      <p className="text-muted subpara">
                        Required for the website to function properly.
                      </p>
                    </div>

                    <label className="Cookie-product-switch">
                      <input
                        type="checkbox"
                        checked={preferences.necessary}
                        disabled={true}
                        id="necessaryCookies"
                      />
                      <span className="Cookie-product-slider"></span>
                    </label>
                  </div>

                  <div className="preference-item">
                    <div>
                      <h6 className="card-subtitle">Functional Cookies</h6>
                      <p className="text-muted">
                        Enable personalized features and settings.
                      </p>
                    </div>

                    <label className="Cookie-product-switch">
                      <input
                        type="checkbox"
                        checked={preferences.functional}
                        onChange={() => handlePreferenceChange("functional")}
                        id="functionalCookies"
                      />
                      <span className="Cookie-product-slider"></span>
                    </label>
                  </div>

                  <div className="preference-item">
                    <div>
                      <h6 className="card-subtitle">Analytics Cookies</h6>
                      <p className="text-muted">
                        Help us improve by collecting anonymous usage data.
                      </p>
                    </div>

                    <label className="Cookie-product-switch">
                      <input
                        type="checkbox"
                        checked={preferences.analytics}
                        onChange={() => handlePreferenceChange("analytics")}
                        id="analyticsCookies"
                      />
                      <span className="Cookie-product-slider"></span>
                    </label>
                  </div>

                  <div className="preference-item">
                    <div>
                      <h6 className="card-subtitle">Marketing Cookies</h6>
                      <p className="text-muted">
                        Used to deliver relevant ads and marketing campaigns.
                      </p>
                    </div>

                    <label className="Cookie-product-switch">
                      <input
                        type="checkbox"
                        checked={preferences.marketing}
                        onChange={() => handlePreferenceChange("marketing")}
                        id="marketingCookies"
                      />
                      <span className="Cookie-product-slider"></span>
                    </label>
                  </div>
                </div>

                <div className="btn-group mt-3">
                  <button
                    onClick={savePreferences}
                    className="btn btn-primary"
                    style={{ borderRadius: "10px" }}
                  >
                    Save Preferences
                  </button>
                  <button
                    onClick={() => setShowPreferences(false)}
                    className="btn btn-outline-secondary"
                    style={{ borderRadius: "10px" }}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default CookieConsent;
