import { useEffect } from "react";
import { Modal } from "react-bootstrap";
import SuccessFullModalTopImg from "../../Assests/imgs/page-header/SuccessFullModalTopImg.svg";

export default function SuccessModal({ show, handleCloseSuccModal, title,msg }) {
  useEffect(() => {
    if (show) {
      const timer = setTimeout(() => {
        handleCloseSuccModal();
      }, 5000);
      return () => clearTimeout(timer);
    }
  }, [show, handleCloseSuccModal]);
  return (
    <div className="SuccsMOdalcont">
      <Modal show={show} onHide={handleCloseSuccModal} centered>
        <Modal.Body className="text-center">
          <div className="contentContainerSUccesModal">
            <div className="col-6">
              <div>
                <img src={SuccessFullModalTopImg} alt="Success" width="100" />
              </div>
            </div>
            <div className="col-10">
              <div className="SuccCOntent">
                <h1 style={{fontSize:'19px'}}>{title}</h1>
                <p>{msg}</p>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}
