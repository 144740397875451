import React, { useState } from "react";
import { Link } from "react-router-dom";
import StarFilled from "../../Assests/imgs/page/tour-detail/star.svg";
import Avatar from "../../Assests/imgs/page/tour-detail/avatar.png";
import { FaStar } from "react-icons/fa";
import {
  save_review,
  server_post_data,
} from "../../ServiceConnection/serviceconnection";
import { retrieveData } from "../../LocalConnection/LocalConnection.js";
import {
  check_vaild_save,
  combiled_form_data,
  empty_form,
  formatDateString,
} from "../../CommonJquery/CommonJquery";
import { useParams } from "react-router-dom";
function Reviews({
  reviews,
  total,
  price,
  service,
  safety,
  entertainment,
  accessibility,
  support,
  total_review,
}) {
  const { id } = useParams();
  const customer_name = retrieveData("customer_name");
  const customer_email = retrieveData("customer_email");
  const [isAccordion, setIsAccordion] = useState(null);

  const handleAccordion = (key) => {
    setIsAccordion((prevState) => (prevState === key ? null : key));
  };

  const [PricingRating, setPricingRating] = useState(0);
  const [SafetyRating, setSafetyRating] = useState(0);
  const [EntertainmentRating, setEntertainmentRating] = useState(0);
  const [AccessibilityRating, setAccessibilityRating] = useState(0);
  const [SupportRating, setSupportRating] = useState(0);
  const [ServiceRating, setServiceRating] = useState(0);
  const [hoverRating, setHoverRating] = useState({
    Pricing: 0,
    Safety: 0,
    Entertainment: 0,
    Accessibility: 0,
    Support: 0,
    Service: 0,
  });
  const handleHover = (section, value) => {
    setHoverRating((prev) => ({ ...prev, [section]: value }));
  };

  const handleRatingClick = (section, value) => {
    if (section === "Pricing") {
      setPricingRating(value);
    } else if (section === "Safety") {
      setSafetyRating(value);
    } else if (section === "Entertainment") {
      setEntertainmentRating(value);
    } else if (section === "Accessibility") {
      setAccessibilityRating(value);
    } else if (section === "Support") {
      setSupportRating(value);
    } else if (section === "Service") {
      setServiceRating(value);
    }
    console.log(`${section} Selected Rating:`, value);
  };

  const handleSaveChangesdynamic = async (form_data) => {
    let valid_data = check_vaild_save(form_data);
    if (valid_data) {
      let fd_from = combiled_form_data(form_data);
      fd_from.append("car_id ", id);
      fd_from.append("price ", PricingRating);
      fd_from.append("service", ServiceRating);
      fd_from.append("safety", SafetyRating);
      fd_from.append("entertainment", EntertainmentRating);
      fd_from.append("accessibility", AccessibilityRating);
      fd_from.append("support", SupportRating);

      await server_post_data(save_review, fd_from)
        .then((Response) => {
          if (Response.error) {
          } else {
            empty_form(form_data);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  return (
    <div>
      <div className="group-collapse-expand">
        <button
          className={
            isAccordion == 4 ? "btn btn-collapse collapsed" : "btn btn-collapse"
          }
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#collapseReviews"
          aria-expanded="false"
          aria-controls="collapseReviews"
          onClick={() => handleAccordion(4)}
        >
          <h6>Rating & Reviews</h6>
          <svg
            width={12}
            height={7}
            viewBox="0 0 12 7"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M1 1L6 6L11 1"
              stroke=""
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
              fill="none"
            />
          </svg>
        </button>
        <div
          className={isAccordion == 4 ? "collapse" : "collapse show"}
          id="collapseReviews"
        >
          <div className="card card-body">
            <div className="head-reviews">
              <div className="review-left">
                <div className="review-info-inner">
                  <h6 className="neutral-1000">{total} / 5</h6>
                  <p className="text-sm-medium neutral-400">
                    ({total_review} reviews)
                  </p>
                  <div className="review-rate">
                    {" "}
                    <img src={StarFilled} alt="Travila" />
                    <img src={StarFilled} alt="Travila" />
                    <img src={StarFilled} alt="Travila" />
                    <img src={StarFilled} alt="Travila" />
                    <img src={StarFilled} alt="Travila" />
                  </div>
                </div>
              </div>
              <div className="review-right">
                <div className="review-progress">
                  <div className="item-review-progress">
                    <div className="text-rv-progress">
                      <p className="text-sm-bold">Price</p>
                    </div>
                    <div className="bar-rv-progress">
                      <div className="progress">
                        <div
                          className="progress-bar"
                          style={{ width: `${(price / 5) * 100}%` }}
                        >
                          {" "}
                        </div>
                      </div>
                    </div>
                    <div className="text-avarage">
                      <p>{price}/5</p>
                    </div>
                  </div>
                  <div className="item-review-progress">
                    <div className="text-rv-progress">
                      <p className="text-sm-bold">Service</p>
                    </div>
                    <div className="bar-rv-progress">
                      <div className="progress">
                        <div
                          className="progress-bar"
                          style={{ width: `${(service / 5) * 100}%` }}
                        >
                          {" "}
                        </div>
                      </div>
                    </div>
                    <div className="text-avarage">
                      <p>{service}/5</p>
                    </div>
                  </div>
                  <div className="item-review-progress">
                    <div className="text-rv-progress">
                      <p className="text-sm-bold">Safety</p>
                    </div>
                    <div className="bar-rv-progress">
                      <div className="progress">
                        <div
                          className="progress-bar"
                          style={{ width: `${(safety / 5) * 100}%` }}
                        >
                          {" "}
                        </div>
                      </div>
                    </div>
                    <div className="text-avarage">
                      <p>{safety}/5</p>
                    </div>
                  </div>
                  <div className="item-review-progress">
                    <div className="text-rv-progress">
                      <p className="text-sm-bold">Entertainment</p>
                    </div>
                    <div className="bar-rv-progress">
                      <div className="progress">
                        <div
                          className="progress-bar"
                          style={{ width: `${(entertainment / 5) * 100}%` }}
                        >
                          {" "}
                        </div>
                      </div>
                    </div>
                    <div className="text-avarage">
                      <p>{entertainment}/5</p>
                    </div>
                  </div>
                  <div className="item-review-progress">
                    <div className="text-rv-progress">
                      <p className="text-sm-bold">Accessibility</p>
                    </div>
                    <div className="bar-rv-progress">
                      <div className="progress">
                        <div
                          className="progress-bar"
                          style={{ width: `${(accessibility / 5) * 100}%` }}
                        >
                          {" "}
                        </div>
                      </div>
                    </div>
                    <div className="text-avarage">
                      <p>{accessibility}/5</p>
                    </div>
                  </div>
                  <div className="item-review-progress">
                    <div className="text-rv-progress">
                      <p className="text-sm-bold">Support</p>
                    </div>
                    <div className="bar-rv-progress">
                      <div className="progress">
                        <div
                          className="progress-bar"
                          style={{ width: `${(support / 5) * 100}%` }}
                        />
                      </div>
                    </div>
                    <div className="text-avarage">
                      <p>{support}/5</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="list-reviews">
              {reviews.slice(0, 3).map((review, index) => (
                <div className="item-review" key={index}>
                  <div className="head-review">
                    <div className="author-review">
                      <p className="nameInitials" style={{ fontSize: "24px" }}>
                        {review.name
                          .split(" ")
                          .map((word) => word[0])
                          .join("")
                          .toUpperCase()}
                      </p>
                      <div className="author-info">
                        <p className="text-lg-bold">{review.name}</p>
                        <p className="text-sm-medium neutral-500">
                          {formatDateString(review.entry_date)}
                        </p>
                      </div>
                    </div>
                    <div className="rate-review">
                      {[...Array(Math.floor(review.overall))].map(
                        (_, starIndex) => (
                          <img
                            key={starIndex}
                            src={StarFilled}
                            alt="RCL User"
                          />
                        )
                      )}
                    </div>
                  </div>
                  <div className="content-review">
                    <p className="text-sm-medium neutral-800">
                      {review.comment}
                    </p>
                  </div>
                </div>
              ))}
            </div>
            {/* <nav aria-label="Page navigation example">
              <ul className="pagination">
                <li className="page-item">
                  <Link className="page-link" to="#" aria-label="Previous">
                    <span aria-hidden="true">
                      <svg
                        width={12}
                        height={12}
                        viewBox="0 0 12 12"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M6.00016 1.33325L1.3335 5.99992M1.3335 5.99992L6.00016 10.6666M1.3335 5.99992H10.6668"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </span>
                  </Link>
                </li>
                <li className="page-item">
                  <Link className="page-link" to="#">
                    1
                  </Link>
                </li>
                <li className="page-item">
                  <Link className="page-link active" to="#">
                    2
                  </Link>
                </li>
                <li className="page-item">
                  <Link className="page-link" to="#">
                    3
                  </Link>
                </li>
                <li className="page-item">
                  <Link className="page-link" to="#">
                    4
                  </Link>
                </li>
                <li className="page-item">
                  <Link className="page-link" to="#">
                    5
                  </Link>
                </li>
                <li className="page-item">
                  <Link className="page-link" to="#">
                    ...
                  </Link>
                </li>
                <li className="page-item">
                  <Link className="page-link" to="#" aria-label="Next">
                    <span aria-hidden="true">
                      <svg
                        width={12}
                        height={12}
                        viewBox="0 0 12 12"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M5.99967 10.6666L10.6663 5.99992L5.99968 1.33325M10.6663 5.99992L1.33301 5.99992"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </span>
                  </Link>
                </li>
              </ul>
            </nav> */}
          </div>
        </div>
      </div>
      <div className="group-collapse-expand">
        <button
          className={
            isAccordion == 5 ? "btn btn-collapse collapsed" : "btn btn-collapse"
          }
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#collapseAddReview"
          aria-expanded="false"
          aria-controls="collapseAddReview"
          onClick={() => handleAccordion(5)}
        >
          <h6>Add a review</h6>
          <svg
            width={12}
            height={7}
            viewBox="0 0 12 7"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M1 1L6 6L11 1"
              stroke=""
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
              fill="none"
            />
          </svg>
        </button>
        <div
          className={isAccordion == 5 ? "collapse" : "collapse show"}
          id="collapseAddReview"
        >
          <div className="card card-body">
            <div className="box-type-reviews">
              <div className="row">
                <div className="col-lg-4">
                  <div className="box-type-review">
                    <p className="text-sm-bold text-type-rv">Price</p>
                    <p className="rate-type-review">
                      {[1, 2, 3, 4, 5].map((value) => (
                        <FaStar
                          key={`Pricing-${value}`}
                          className={`star ${
                            value <= (hoverRating.Pricing || PricingRating)
                              ? "filled"
                              : "unfilled"
                          }`}
                          onClick={() => handleRatingClick("Pricing", value)}
                          onMouseEnter={() => handleHover("Pricing", value)}
                          onMouseLeave={() => handleHover("Pricing", 0)}
                        />
                      ))}
                    </p>
                  </div>
                  <div className="box-type-review">
                    <p className="text-sm-bold text-type-rv">Service</p>
                    <p className="rate-type-review">
                      {[1, 2, 3, 4, 5].map((value) => (
                        <FaStar
                          key={`Service-${value}`}
                          className={`star ${
                            value <= (hoverRating.Service || ServiceRating)
                              ? "filled"
                              : "unfilled"
                          }`}
                          onClick={() => handleRatingClick("Service", value)}
                          onMouseEnter={() => handleHover("Service", value)}
                          onMouseLeave={() => handleHover("Service", 0)}
                        />
                      ))}
                    </p>
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="box-type-review">
                    <p className="text-sm-bold text-type-rv">Safety</p>
                    <p className="rate-type-review">
                      {[1, 2, 3, 4, 5].map((value) => (
                        <FaStar
                          key={`Safety-${value}`}
                          className={`star ${
                            value <= (hoverRating.Safety || SafetyRating)
                              ? "filled"
                              : "unfilled"
                          }`}
                          onClick={() => handleRatingClick("Safety", value)}
                          onMouseEnter={() => handleHover("Safety", value)}
                          onMouseLeave={() => handleHover("Safety", 0)}
                        />
                      ))}
                    </p>
                  </div>
                  <div className="box-type-review">
                    <p className="text-sm-bold text-type-rv">Entertainment</p>
                    <p className="rate-type-review">
                      {[1, 2, 3, 4, 5].map((value) => (
                        <FaStar
                          key={`Entertainment-${value}`}
                          className={`star ${
                            value <=
                            (hoverRating.Entertainment || EntertainmentRating)
                              ? "filled"
                              : "unfilled"
                          }`}
                          onClick={() =>
                            handleRatingClick("Entertainment", value)
                          }
                          onMouseEnter={() =>
                            handleHover("Entertainment", value)
                          }
                          onMouseLeave={() => handleHover("Entertainment", 0)}
                        />
                      ))}
                    </p>
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="box-type-review">
                    <p className="text-sm-bold text-type-rv">Accessibility</p>
                    <p className="rate-type-review">
                      {" "}
                      {[1, 2, 3, 4, 5].map((value) => (
                        <FaStar
                          key={`Accessibility-${value}`}
                          className={`star ${
                            value <=
                            (hoverRating.Accessibility || AccessibilityRating)
                              ? "filled"
                              : "unfilled"
                          }`}
                          onClick={() =>
                            handleRatingClick("Accessibility", value)
                          }
                          onMouseEnter={() =>
                            handleHover("Accessibility", value)
                          }
                          onMouseLeave={() => handleHover("Accessibility", 0)}
                        />
                      ))}
                    </p>
                  </div>
                  <div className="box-type-review">
                    <p className="text-sm-bold text-type-rv">Support</p>
                    <p className="rate-type-review">
                      {" "}
                      {[1, 2, 3, 4, 5].map((value) => (
                        <FaStar
                          key={`Support-${value}`}
                          className={`star ${
                            value <= (hoverRating.Support || SupportRating)
                              ? "filled"
                              : "unfilled"
                          }`}
                          onClick={() => handleRatingClick("Support", value)}
                          onMouseEnter={() => handleHover("Support", value)}
                          onMouseLeave={() => handleHover("Support", 0)}
                        />
                      ))}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="box-form-reviews">
              <h6 className="text-md-bold neutral-1000 mb-15">
                Leave feedback
              </h6>
              <form id="reviewsubmit">
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group">
                      <input
                        name="customer_name"
                        className="form-control"
                        type="text"
                        placeholder="Your name"
                        defaultValue={customer_name || ""}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <input
                        name="customer_email"
                        className="form-control"
                        defaultValue={customer_email || ""}
                        type="text"
                        placeholder="Email address"
                      />
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="form-group">
                      <textarea
                        name="comment_show"
                        className="form-control"
                        placeholder="Your comment"
                        defaultValue={""}
                      />
                    </div>
                  </div>
                  <div className="col-md-12">
                    <button
                      className="btn btn-black-lg-square"
                      onClick={() => handleSaveChangesdynamic("reviewsubmit")}
                    >
                      Submit Review
                      <svg
                        width={16}
                        height={16}
                        viewBox="0 0 16 16"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M8 15L15 8L8 1M15 8L1 8"
                          stroke=""
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          fill="none"
                        />
                      </svg>
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Reviews;
