import React, { useState } from "react";
import Layout from "../Layout/Layout";
import { Link } from "react-router-dom";
import Termplc from "../../Assests/imgs/page-header/Termplc.jpg";

const TermsOfUse = () => {
  return (
    <Layout headerStyle={2} footerStyle={1}>
      <div>
        <div className="page-header-2 pt-30 background-body">
          <div className="custom-container position-relative mx-auto">
            <div className="bg-overlay rounded-12 overflow-hidden">
              <img
                className="w-100 h-100 img-fluid img-banner"
                src={Termplc}
                alt="Carento"
              />
            </div>
            <div className="container position-absolute z-1 top-50 start-50 pb-70 translate-middle text-center">
              <h2 className="text-white mt-4">Terms And Services</h2>
              {/* <span className="text-white text-lg-medium">
                Search and find your best car rental with an easy way
              </span> */}
            </div>
          </div>

          {/**--------Content T&C----------- */}
          <div className="container-lg">
            {" "}
            <div className="termsData">
              <h4>Last revised: December 12, 2024</h4>
              <h3>
                PLEASE READ THESE TERMS OF SERVICE CAREFULLY. THEY CONTAIN
                IMPORTANT INFORMATION THAT AFFECTS YOUR RIGHTS, REMEDIES, AND
                OBLIGATIONS. THEY INCLUDE AN AGREEMENT TO ARBITRATE (UNLESS YOU
                OPT OUT). THESE TERMS ALSO INCLUDE A PROHIBITION OF CLASS AND
                REPRESENTATIVE ACTIONS AND NON-INDIVIDUALIZED RELIEF FOR ALL
                MATTERS IN EITHER COURT OR ARBITRATION, VARIOUS LIMITATIONS AND
                EXCLUSIONS, A CLAUSE THAT GOVERNS THE JURISDICTION, VENUE, AND
                GOVERNING LAW OF DISPUTES, EXCEPT WHERE PROHIBITED, AND
                OBLIGATIONS TO COMPLY WITH APPLICABLE LAWS AND REGULATIONS.
              </h3>

              <div className="mt-3 listdataT">
                {" "}
                <li>Introduction</li>
                <li>Introduction</li>
                <li>Introduction</li>
              </div>
              <div className="introDuc">
                <h1>Intruduction</h1>
                <p>
                  Here is a version of the provided text using "Lorem Ipsum" to
                  make it copyright-free: Lorem Ipsum Inc. (“Lorem Ipsum”, “we”,
                  or “us”), provides an online sharing platform that connects
                  users with individuals seeking to access resources. Lorem
                  Ipsum is accessible online, including at loremipsum.com, and
                  as an application for mobile devices. The Lorem Ipsum
                  websites, blog, mobile applications, and associated services
                  are collectively referred to as “the Services.” By accessing
                  or using the Services, including by communicating with us or
                  other Lorem Ipsum users, you agree to comply with, and be
                  legally bound by, the provisions of these Terms of Service
                  (these “Terms”), whether or not you become a registered user
                  of the Services. These Terms govern your access to and use of
                  the Services and constitute a binding legal agreement between
                  you and Lorem Ipsum.
                </p>
                <p className="mt-2">
                  These Terms, together with the cancellation policy,
                  nondiscrimination policy, applicable insurance terms and
                  certificates, roadside assistance terms, and additional
                  policies (together, the “Policies”) form the “Agreement”
                  between you and Lorem Ipsum (each a “Party” and together, “the
                  Parties”). Additionally, Lorem Ipsum provides a Resource
                  Sharing Agreement that summarizes the terms of each
                  reservation, accessible in the Services for any booked or
                  previous trips, which you may use as proof of a reservation.
                </p>
                <p className="mt-2">
                  <span>Modification.</span>Lorem Ipsum reserves the right, at
                  our sole discretion, to modify the Services or to modify the
                  Agreement, including these Terms, at any time. If we modify
                  these Terms, we will post the modification on the Services. We
                  will also update the “Last Revised” date at the top of these
                  Terms. If you continue to access or use the Services after we
                  have posted a modification or have provided you with notice of
                  a modification, you are indicating that you agree to be bound
                  by the modified terms. If the modified terms are not
                  acceptable to you, your sole recourse is to stop using and
                  accessing the Services and close your Lorem Ipsum Account
                  within 30 days. If you choose to close your Lorem Ipsum
                  Account, the previous effective version of these Terms will
                  apply to you, unless you use the Services during the
                  intervening 30-day period, in which case the new version of
                  these Terms will apply to you.
                </p>
              </div>
              <div className="enligiblityDiv">
                <h1>Eligibility, registration, verification</h1>
                <h5>Eligibility</h5>
                <p>
                  The Services are intended solely for guests who meet our
                  eligibility requirements in the location where the vehicle is
                  booked and hosts who are 21 or older, except in the United
                  Kingdom and France where we permit hosts age 18 to list
                  eligible vehicles. Any use of the Services by anyone that does
                  not meet these eligibility requirements is expressly
                  prohibited.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default TermsOfUse;
