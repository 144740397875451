import { Link } from "react-router-dom";
import Author from "../../Assests/imgs/page/homepage1/userDDD.jpeg";
import Logo from "../../Assests/imgs/template/rapidB.png";
import Close from "../../Assests/imgs/template/icons/close.png";
import { useEffect, useState } from "react";
import {
  removeData,
  retrieveData,
} from "../../LocalConnection/LocalConnection";
import {
  server_post_data,
  get_footer_data_website,
} from "../../ServiceConnection/serviceconnection";
import { handleError } from "../../CommonJquery/CommonJquery";
export default function Offcanvas({
  isOffcanvas,
  handleOffcanvas,
  isMobileMenu,
}) {
  const customer_id = retrieveData("customer_id");
  const customer_name = retrieveData("customer_name");
  const customer_email = retrieveData("customer_email");
  const [isLogin, setIsLogin] = useState(false);

  useEffect(() => {
    const storedValue = customer_id;
    if (!customer_id) {
      setIsLogin(false);
      return;
    } else {
      if (storedValue !== "0") {
        setIsLogin(true);
      }
    }
  }, [isLogin, customer_id]);

  const Logout = () => {
    removeData("/");
  };
  const handleNavigate = () => {
    window.location.href = "/profile";
  };
  const [FooterData, setFooterData] = useState([]);

  useEffect(() => {
    master_data_get();
  }, []);

  const master_data_get = async () => {
    const fd = new FormData();
    // fd.append("flag", 1);
    await server_post_data(get_footer_data_website, fd)
      .then((Response) => {
        if (Response.error) {
          handleError(Response.message);
        } else {
          setFooterData(Response.message.booking_data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <>
      <div
        className={`sidebar-canvas-wrapper perfect-scrollbar button-bg-2 ${isOffcanvas ? "sidebar-canvas-visible" : ""
          }`}
      >
        <div className="sidebar-canvas-container">
          <div className="sidebar-canvas-head">
            <div className="sidebar-canvas-logo">
              <Link href="/" className="d-flex" style={{ padding: "0.5rem 0" }}>
                <img
                  style={{ width: "100px" }}
                  className="light-mode"
                  alt="Rapid Cars"
                  src={Logo}
                />
                <img className="dark-mode" alt="Rapid Cars" src={Logo} />
              </Link>
            </div>
            <div className="sidebar-canvas-lang">
              <button className="close-canvas" onClick={handleOffcanvas}>
                <img alt="Rapid Cars" src={Close} />
              </button>
            </div>
          </div>
          <div className="sidebar-canvas-content">
            {isLogin === true && (
              <div className="box-author-profile">
                <div className="card-author">
                  <div className="card-image">
                    <img
                      src={Author}
                      alt="Rapid Cars"
                      onClick={handleNavigate}
                      style={{ cursor: "pointer" }}
                    />
                  </div>
                  <div className="card-info">
                    <p className="text-md-bold neutral-1000">{customer_name}</p>
                    <p className="text-xs neutral-1000">{customer_email}</p>
                  </div>
                </div>
                <Link onClick={Logout} className="btn btn-black">
                  Logout
                </Link>
              </div>
            )}
            {isLogin === false && (
              <Link
                className="btn btn-signin background-brand-2 text-dark"
                to="/login"
              >
                <svg
                  className="mb-1"
                  xmlns="http://www.w3.org/2000/svg"
                  width={12}
                  height={12}
                  viewBox="0 0 12 12"
                  fill="none"
                >
                  <path
                    d="M1 12C1 12 0 12 0 11C0 10 1 7 6 7C11 7 12 10 12 11C12 12 11 12 11 12H1ZM6 6C6.79565 6 7.55871 5.68393 8.12132 5.12132C8.68393 4.55871 9 3.79565 9 3C9 2.20435 8.68393 1.44129 8.12132 0.87868C7.55871 0.316071 6.79565 0 6 0C5.20435 0 4.44129 0.316071 3.87868 0.87868C3.31607 1.44129 3 2.20435 3 3C3 3.79565 3.31607 4.55871 3.87868 5.12132C4.44129 5.68393 5.20435 6 6 6Z"
                    fill="#101010"
                  />
                </svg>
                Sign in
              </Link>
            )}

            <ul className="mobile-menu font-heading navLinksPhone">
              <li>
                <Link to="/profile">Profile</Link>
              </li>
              <li>
                <Link to="/">Home</Link>
              </li>
              <li>
                <Link to="/my_bookings">My Bookings</Link>
              </li>
              <li>
                <Link to="/about">About</Link>
              </li>
              <li>
                <Link to="/services">Services</Link>
              </li>
              <li>
                <Link to="/blogs">Blog</Link>
              </li>
              <li>
                <Link to="/contact">Contact</Link>
              </li>
              <li>
                <Link to="/favourite">Favourite</Link>
              </li>
            </ul>
            <div className="box-contactus">
              <h6 className="title-contactus neutral-1000">Contact Us</h6>
              <div className="contact-info mt-2">
                <p className="hour-work-2 text-md-medium neutral-1000">
                  Hours: 9:00 - 17:00, Mon - Sat
                </p>{" "}
                <a
                  href={`https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(
                    "1400 Renaissance Dr UNIT 201, Park Ridge, IL 60068, United States"
                  )}`}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-blue-500 underline"
                >
                  <p className="hour-work-2 text-md-medium neutral-1000">
                    {FooterData.website_address}
                  </p>
                </a>
                <a
                  href={`mailto:${FooterData.website_email}`}
                  className="email-2 text-md-medium neutral-1000"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {FooterData.website_email}
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      {isOffcanvas && (
        <div className="body-overlay-1" onClick={handleOffcanvas} />
      )}
    </>
  );
}
