import { Link, useNavigate } from "react-router-dom";
import { useHistory } from "../Sections/HistoryContext";
import { Button, Modal } from "react-bootstrap";
import Layout from "../Layout/Layout";
import { useState, useEffect } from "react";
import Google from "../../Assests/imgs/template/popup/google.svg";
import SuccessFullModalTopImg from "../../Assests/imgs/banners/mail_icon.gif";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import axios from "axios";
import { useGoogleLogin } from "@react-oauth/google";
import {
  server_post_data,
  user_registation_login,
  forgot_password_user,
  google_login,
} from "../../ServiceConnection/serviceconnection";
import {
  combiled_form_data,
  handleEmailChange,
  handleAphabetsChange,
  empty_form,
  validateEmail,
} from "../../CommonJquery/CommonJquery";
import { storeData } from "../../LocalConnection/LocalConnection";
import { retrieveData } from "../../LocalConnection/LocalConnection";
import Cookies from "js-cookie";
export default function Login() {
  const navigate = useNavigate();
  const { previousPath } = useHistory();
  const last_link = previousPath;


  const [showLoaderAdmin, setShowLoaderAdmin] = useState(false);
  const [isLogin, setIsLogin] = useState(0);
  const [error, setError] = useState("");
  const [show, setShow] = useState(false);
  const [rememberMe, setRememberMe] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [formData, setFormData] = useState({
    email: "",
    name: "",
    password: "",
    confirmPassword: "",
    agreeToTerms: false,
  });
  const handleLogin = () => {
    setIsLogin(0);
    setError("");
  };

  const handleSignup = () => {
    setIsLogin(1);
    setError("");
  };
  const handleforgot = () => {
    setIsLogin(2);
    setError("");
  };

  const toggleLogin = () => {
    localStorage.setItem("isLogin", true);
    window.location.reload();
    navigate("/");
  };

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: type === "checkbox" ? checked : value,
    }));
  };
  let GOOGLE_CLIENT_ID =
    "166804490112-bvhkf2hulsrea4499d7pf5jgh2sjnchm.apps.googleusercontent.com";
  let GOOGLE_CLIENT_SECRET = "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";
  let GOOGLE_REDIRECTION_URL = "http://localhost:3000";
  GOOGLE_REDIRECTION_URL = "https://www.rapidcarleasing.com";

  const handleSignUp = (e) => {
    e.preventDefault();

    // Check if passwords match

    // Save the user data to localStorage
    const userData = {
      username: formData.username,
      email: formData.email,
      password: formData.password,
    };
    console.log(userData);
    localStorage.setItem("user", JSON.stringify(userData));
    toggleLogin();
    // Optionally redirect to a different page or clear the form
    setFormData({
      username: "",
      email: "",
      password: "",
      confirmPassword: "",
      agreeToTerms: false,
    });
  };

  const [formDataLogin, setFormDataLogin] = useState({
    email: "",
    password: "",
  });

  const handleChangeLogin = (e) => {
    const { name, value } = e.target;
    setFormDataLogin((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmitLogin = (e) => {
    e.preventDefault();

    // Retrieve user data from localStorage
    const storedUser = JSON.parse(localStorage.getItem("user"));

    if (storedUser) {
      const { email, password } = storedUser;

      // Validate credentials
      if (
        (formDataLogin.email === email ||
          formDataLogin.email === storedUser.username) &&
        formDataLogin.password === password
      ) {
        navigate("/");
        setError(""); // Clear any previous errors
        toggleLogin(); // Call the toggleLogin function to update the state
      } else {
        setError("Invalid email/username or password");
      }
    } else {
      setError("No user found. Please register first.");
    }
  };
  useEffect(() => {
    const savedEmail = Cookies.get("saved_email");
    const savedPassword = Cookies.get("saved_password");
    const rememberMe = Cookies.get("remember_me") === "true";

    if (rememberMe) {
      setFormDataLogin({
        email: savedEmail || "",
        password: savedPassword || "",
      });
      setRememberMe(true);
    }
  }, []);
  const handle_login = async (form_data) => {
    setShowLoaderAdmin(true);
    if (formDataLogin.email === " " || formDataLogin.password === " ") {
      setError("Please enter login credentials");
      return;
    }
    if (!validateEmail(formDataLogin.email)) {
      setError("Please Enter Vaild Email Id");
      return;
    }
    if (formDataLogin.password.length < 5) {
      setError("Please Enter Vaild Password");
      return;
    }
    //------------------Remember Me logic
    if (rememberMe) {
      Cookies.set("saved_email", formDataLogin.email, { expires: 30 });
      Cookies.set("saved_password", formDataLogin.password, { expires: 30 });
      Cookies.set("remember_me", true, { expires: 30 });
    } else {
      Cookies.remove("saved_email");
      Cookies.remove("saved_password");
      Cookies.remove("remember_me");
    }

    let fd_from = combiled_form_data(form_data, null);
    fd_from.append("flag", "0");
    await server_post_data(user_registation_login, fd_from)
      .then((Response) => {
        if (Response.error) {
          console.log(Response.message);
          setError(Response.message);
        } else {
          console.log(Response.message.customer_data[0]);
          let customer_data = Response.message.customer_data[0];
          storeData("Auth_token", customer_data.Auth_token);
          storeData("customer_email", customer_data.email);
          storeData("customer_name", customer_data.name);
          storeData("customer_id", customer_data.primary_id);
          storeData("dob_date", customer_data.dob_date);
          storeData(
            "driving_expiration_date",
            customer_data.driving_expiration_date
          );
          storeData("emergency_contacts", customer_data.emergency_contacts);
          storeData("emergency_contacts1", customer_data.emergency_contacts1);
          storeData(
            "emergency_contacts_name",
            customer_data.emergency_contacts_name
          );
          storeData(
            "emergency_contacts_name1",
            customer_data.emergency_contacts_name1
          );
          storeData(
            "emergency_contacts_relation",
            customer_data.emergency_contacts_relation
          );
          storeData(
            "emergency_contacts_relation1",
            customer_data.emergency_contacts_relation1
          );
          const loginDateTime = new Date().toISOString(); // Format: YYYY-MM-DDTHH:MM:SS.sssZ
          storeData("login_date_time", loginDateTime);
          storeData("present_map_address", customer_data.present_map_address);

          const appli_link_path = retrieveData("appli_link_path");
          const current_data = retrieveData("current_data");
          if (appli_link_path === "appli_link_path") {
            console.log(appli_link_path);
            navigate("/application_form", {
              state: current_data,
            });
          } else {
            navigate("/");
          }
          setError("");
        }
        setShowLoaderAdmin(false);
      })
      .catch((error) => {
        console.log(error);
        setShowLoaderAdmin(false);
      });
  };

  const handleGoogleLogin = async (userDetails) => {
    setShowLoaderAdmin(true);

    try {
      if (!userDetails.email || !userDetails.name) {
        setError("Invalid user details received from Google.");
        setShowLoaderAdmin(false);
        return;
      }

      // Prepare form data
      const fd_from = new FormData();
      fd_from.append("email", userDetails.email);
      fd_from.append("name", userDetails.name);
      fd_from.append("picture", userDetails.picture);

      // Call the backend API for Google login
      const response = await server_post_data(google_login, fd_from);

      if (response.error) {
        console.log(response.message);
        setError(response.message);
      } else {
        console.log("Login Success:", response.message.customer_data[0]);
        const customer_data = response.message.customer_data[0];

        // Store user data in local storage
        storeData("Auth_token", customer_data.Auth_token);
        storeData("customer_email", customer_data.email);
        storeData("customer_name", customer_data.name);
        storeData("customer_id", customer_data.primary_id);
        const loginDateTime = new Date().toISOString(); // Format: YYYY-MM-DDTHH:MM:SS.sssZ
        storeData("login_date_time", loginDateTime);
        console.log("previousPath_googlelogin", previousPath);
        console.log("previousPath", last_link);
        const appli_link_path = retrieveData("appli_link_path");
          const current_data = retrieveData("current_data");
          if (appli_link_path === "appli_link_path") {
            console.log(appli_link_path);
            navigate("/application_form", {
              state: current_data,
            });
          } else {
            navigate("/");
          }
        setError("");
      }
    } catch (error) {
      console.error("Error during Google login:", error);
      setError("An error occurred during login. Please try again.");
    } finally {
      setShowLoaderAdmin(false);
    }
  };

  const handle_save_user = async (form_data) => {
    setShowLoaderAdmin(true);
    if (
      formData.name === " " ||
      formData.email === " " ||
      formData.password === " "
    ) {
      setError("Please enter Valid Account details");
      return;
    }
    console.log(formData);
    if (!validateEmail(formData.email)) {
      setError("Please Enter Vaild Email Id");
      return;
    }
    if (formData.password.length < 5) {
      setError("Please Enter Vaild Password");
      return;
    }
    if (formData.password !== formData.confirmPassword) {
      setError("Passwords do not match");
      return;
    }
    // Check if terms and conditions are agreed to
    if (!formData.agreeToTerms) {
      setError("You must agree to the terms and conditions");
      return;
    }
    let fd_from = combiled_form_data(form_data, null);
    fd_from.append("flag", "1");
    await server_post_data(user_registation_login, fd_from)
      .then((Response) => {
        if (Response.error) {
          console.log(Response.message);
          setError(Response.message);
        } else {
          console.log(Response.message);
          setError(Response.message);
          // empty_form(form_data);
          window.location.reload();
          setFormData({
            email: "",
            name: "",
            password: "",
            confirmPassword: "",
            agreeToTerms: false,
          });
        }
        setShowLoaderAdmin(false);
      })
      .catch((error) => {
        console.log(error);
        setShowLoaderAdmin(false);
      });
  };
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setshowConfirmPassword] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword((prevState) => {
      const newState = !prevState;
      if (newState) {
        console.log("Password shown");
      } else {
        console.log("Password hidden");
      }

      return newState;
    });
  };
  const toggleConfirmPasswordVisibility = () => {
    setshowConfirmPassword((prevState) => {
      const newState = !prevState;
      if (newState) {
        console.log("Password shown");
      } else {
        console.log("Password hidden");
      }

      return newState;
    });
  };

  const resetpassword = async () => {
    let fd_from = new FormData();
    fd_from.append("useremail", formData.email);
    await server_post_data(forgot_password_user, fd_from)
      .then((Response) => {
        if (Response.error) {
          console.log(Response.message);
        } else {
          console.log(Response.message);
          setError("");
          // empty_form(form_data);
          window.location.reload();
          setFormData({
            email: "",
            name: "",
            password: "",
            confirmPassword: "",
            agreeToTerms: false,
          });
        }
        setShowLoaderAdmin(false);
      })
      .catch((error) => {
        console.log(error);
        setShowLoaderAdmin(false);
      });
  };

  const login = useGoogleLogin({
    onSuccess: async (codeResponse) => {
      try {
        // Exchange the authorization code for an access token
        const response = await axios.post(
          "https://oauth2.googleapis.com/token",
          null,
          {
            params: {
              code: codeResponse.code, // This is the authorization code
              client_id: GOOGLE_CLIENT_ID,
              client_secret: GOOGLE_CLIENT_SECRET,
              redirect_uri: GOOGLE_REDIRECTION_URL,
              grant_type: "authorization_code",
            },
            headers: {
              "Content-Type": "application/x-www-form-urlencoded",
            },
          }
        );

        const accessToken = response.data.access_token;

        // Fetch user details using the access token
        const userInfoResponse = await axios.get(
          "https://www.googleapis.com/oauth2/v3/userinfo",
          {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          }
        );

        // Extract user details
        const userDetails = userInfoResponse.data;
        console.log("User Details:", userDetails);

        handleGoogleLogin(userDetails);

        // You can now use userDetails for further processing
        // Example: save the user data in your state or send it to your backend
      } catch (error) {
        console.error("Error during Google Sign-In:", error);
        if (error.response) {
          console.error("Response data:", error.response.data);
        }
      }
    },
    flow: "auth-code", // Using authorization code flow
    scope:
      "https://www.googleapis.com/auth/userinfo.profile https://www.googleapis.com/auth/userinfo.email", // Scopes to fetch profile and email
  });

  const handleRememberMeChange = () => {
    setRememberMe(!rememberMe);
  };
  return (
    <>
      <Layout>
        {isLogin == 0 && (
          <div className="container pt-70 pb-70">
            <div className="row">
              <div className="col-lg-5 mx-auto">
                <div className="border rounded-3 px-md-5 px-3 ptb-50">
                  <div className="login-content">
                    <div className="text-center">
                      <p className="neutral-1000 px-4 py-2 bg-2 text-sm-bold rounded-12 d-inline-flex align-items-center">
                        Sign in
                      </p>
                      <h4 className="neutral-1000">Welcome back</h4>
                      {error && (
                        <p className="text-danger text-center">{error}</p> // Display error message
                      )}
                    </div>
                    <div className="form-login mt-30">
                      <form id="login_user">
                        <div className="form-group">
                          <label htmlFor="email" className="sr-only">
                            Email or Username
                          </label>
                          <input
                            id="email"
                            name="email"
                            className="form-control username"
                            type="text"
                            placeholder="Email / Username"
                            value={formDataLogin.email}
                            onChange={handleChangeLogin}
                            required
                          />
                        </div>
                        <div className="form-group">
                          <label htmlFor="password" className="sr-only">
                            Password
                          </label>
                          <div className="input-group">
                            <input
                              id="password"
                              name="password"
                              className="form-control password"
                              type={showPassword ? "text" : "password"}
                              placeholder="****************"
                              value={formDataLogin.password}
                              onChange={handleChangeLogin}
                              required
                            />
                            <div className="input-group-append">
                              <button
                                type="button"
                                onClick={togglePasswordVisibility}
                                className="EyeBtnSHowHide"
                                tabIndex="-1"
                                aria-label="Toggle password visibility"
                              >
                                {showPassword ? <FaEyeSlash /> : <FaEye />}
                              </button>
                            </div>
                          </div>
                        </div>
                        <div className="form-group">
                          <div className="box-remember-forgot">
                            <div className="remeber-me">
                              <label className="text-xs-medium neutral-500">
                                <input
                                  className="cb-remember"
                                  type="checkbox"
                                  checked={rememberMe}
                                  onChange={handleRememberMeChange}
                                />
                                Remember me
                              </label>
                            </div>
                            <div className="forgotpass">
                              <Link
                                className="text-xs-medium neutral-500"
                                onClick={handleforgot}
                              >
                                Forgot password?
                              </Link>
                            </div>
                          </div>
                        </div>
                        <div className="form-group mb-30">
                          <button
                            type="button"
                            className="btn btn-primary w-100"
                            onClick={() => handle_login("login_user")}
                          >
                            Sign in
                            <svg
                              width={16}
                              height={16}
                              viewBox="0 0 16 16"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M8 15L15 8L8 1M15 8L1 8"
                                stroke="currentColor"
                                strokeWidth="1.5"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                            </svg>
                          </button>
                        </div>
                        <div className="box-button-logins">
                          <Link
                            className="btn btn-login btn-google mr-10"
                            onClick={login}
                          >
                            <img src={Google} alt="Rapid Cars" />
                            <span className="text-sm-bold">
                              Sign in with Google
                            </span>
                          </Link>
                        </div>
                        <p className="text-sm-medium neutral-500 text-center mt-20">
                          Don’t have an account?{" "}
                          <Link className="neutral-1000" onClick={handleSignup}>
                            Register Here!
                          </Link>
                        </p>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}

        {isLogin == 1 && (
          <div className="container pt-70 pb-70">
            <div className="row">
              <div className="col-lg-5 mx-auto">
                <div className="register-content border rounded-3 px-md-5 px-3 ptb-50">
                  <div className="text-center">
                    <p className="neutral-1000 px-4 py-2 bg-2 text-sm-bold rounded-12 d-inline-flex align-items-center">
                      Register
                    </p>
                    <h4 className="neutral-1000">Create an Account</h4>
                    {error && (
                      <p className="text-danger text-center">{error}</p> // Display error message
                    )}
                  </div>
                  <div className="form-login mt-30">
                    <form id="sign_up">
                      <div className="form-group">
                        <input
                          className="form-control username"
                          type="text"
                          placeholder="Full Name"
                          name="name"
                          onChange={(e) => {
                            handleAphabetsChange(e);
                            handleChange(e);
                          }}
                          required
                        />
                      </div>
                      <div className="form-group">
                        <input
                          className="form-control email"
                          type="text"
                          placeholder="email"
                          name="email"
                          onChange={(e) => handleChange(e)}
                          required
                        />
                      </div>
                      <div className="form-group">
                        <div className="input-group">
                          <input
                            className="form-control password"
                            type={showPassword ? "text" : "password"}
                            placeholder="Password"
                            name="password"
                            defaultValue={formData.password}
                            onChange={handleChange}
                            required
                          />
                          <div className="input-group-append">
                            <button
                              type="button"
                              onClick={togglePasswordVisibility}
                              className="EyeBtnSHowHide"
                              tabIndex="-1"
                              aria-label="Toggle password visibility"
                            >
                              {showPassword ? <FaEyeSlash /> : <FaEye />}
                            </button>
                          </div>
                        </div>
                      </div>

                      <div className="form-group">
                        <div className="input-group">
                          <input
                            className="form-control confirm-password"
                            type={showConfirmPassword ? "text" : "password"}
                            placeholder="Confirm Password"
                            name="confirmPassword"
                            defaultValue={formData.confirmPassword}
                            onChange={handleChange}
                            required
                          />
                          <div className="input-group-append">
                            <button
                              type="button"
                              onClick={toggleConfirmPasswordVisibility}
                              className="EyeBtnSHowHide"
                              tabIndex="-1"
                              aria-label="Toggle password visibility"
                            >
                              {showConfirmPassword ? <FaEyeSlash /> : <FaEye />}
                            </button>
                          </div>
                        </div>
                      </div>
                      <div className="form-group my-3">
                        <div className="box-remember-forgot">
                          <div className="remember-me d-flex align-items-center neutral-500">
                            <input
                              className="cb-remember"
                              type="checkbox"
                              name="agreeToTerms"
                              defaultChecked={formData.agreeToTerms}
                              onChange={handleChange}
                            />
                            <span>I agree to terms and conditions</span>
                          </div>
                        </div>
                      </div>
                      <div className="form-group mb-30">
                        <button
                          type="button"
                          className="btn btn-primary w-100"
                          onClick={() => handle_save_user("sign_up")}
                        >
                          Sign up
                          <svg
                            width={16}
                            height={16}
                            viewBox="0 0 16 16"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M8 15L15 8L8 1M15 8L1 8"
                              stroke="currentColor"
                              strokeWidth="1.5"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                          </svg>
                        </button>
                        <div className="SuccsMOdalcont">
                          <Modal show={show} onHide={handleClose} centered>
                            <Modal.Header closeButton>
                              <Modal.Title style={{ fontSize: "18px" }}>
                                Email Verification
                              </Modal.Title>
                            </Modal.Header>
                            <Modal.Body className="text-center">
                              <div className="contentContainerSUccesModal">
                                <div className="col-6">
                                  <div>
                                    <img
                                      src={SuccessFullModalTopImg}
                                      alt="Success"
                                      width="100"
                                    />
                                  </div>
                                </div>
                                <div className="">
                                  <div className="SuccCOntent">
                                    <p>
                                      {" "}
                                      Check your mail and verify your email ID
                                      by clicking the link. After verification,
                                      please log in here.
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </Modal.Body>
                            <Modal.Footer>
                              <Button
                                className="d-none d-xl-inline-block btn btn-signin background-brand-2 text-dark"
                                onClick={handleClose}
                              >
                                Close
                              </Button>
                            </Modal.Footer>
                          </Modal>
                        </div>
                      </div>
                      <div className="box-button-logins">
                        <Link
                          className="btn btn-login btn-google mr-10"
                          onClick={login}
                        >
                          <img src={Google} alt="Rapid Cars" />
                          <span className="text-sm-bold">
                            Sign up with Google
                          </span>
                        </Link>
                      </div>
                      <p className="text-sm-medium neutral-500 text-center mt-20">
                        Have an account?{" "}
                        <Link className="neutral-1000" onClick={handleLogin}>
                          Login Here!
                        </Link>
                      </p>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        {isLogin == 2 && (
          <div className="container pt-70 pb-70">
            <div className="row">
              <div className="col-lg-5 mx-auto">
                <div className="register-content border rounded-3 px-md-5 px-3 ptb-50">
                  <div className="text-center">
                    <p className="neutral-1000 px-4 py-2 bg-2 text-sm-bold rounded-12 d-inline-flex align-items-center">
                      Forgot Password
                    </p>
                    <h4 className="neutral-1000">Reset your Password</h4>
                    {error && (
                      <p className="text-danger text-center">{error}</p> // Display error message
                    )}
                  </div>
                  <div className="form-login mt-30">
                    {/* <form onSubmit={handleSignUp}> */}
                    {/* <form > */}
                    <div className="form-group">
                      <input
                        className="form-control email"
                        type="email"
                        placeholder="Email"
                        name="email"
                        defaultValue={formData.email}
                        onChange={handleChange}
                        required
                      />
                    </div>
                    <div className="form-group my-3">
                      <div className="box-remember-forgot">
                        <div className="remember-me d-flex align-items-center neutral-500">
                          <span>
                            Enter your resgistered email id to reset your
                            password
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="form-group mb-30">
                      <button
                        type="submit"
                        className="btn btn-primary w-100"
                        onClick={() => resetpassword()}
                      >
                        Send Reset Link
                        <svg
                          width={16}
                          height={16}
                          viewBox="0 0 16 16"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M8 15L15 8L8 1M15 8L1 8"
                            stroke="currentColor"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                      </button>
                    </div>
                    <p className="text-sm-medium neutral-500 text-center mt-20">
                      Don’t have an account?{" "}
                      <Link className="neutral-1000" onClick={handleSignup}>
                        Register Here!
                      </Link>
                    </p>
                    {/* </form> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </Layout>
    </>
  );
}
