import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Autoplay, Navigation, Pagination } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import Service1 from "../../Assests/imgs/new/service01.jpg";
import Service2 from "../../Assests/imgs/new/service2.jpg";
import Service3 from "../../Assests/imgs/new/service3.jpg";
import CtaImg1 from "../../Assests/imgs/new/service4.jpg";
import Banner from "../../Assests/imgs/page-header/Group2.png";
import RightArrow from "../../Assests/imgs/template/icons/arrow-right.svg";
import CounterUp from "../Elements/CounterUp";
import Layout from "../Layout/Layout";
import Blog from "../Sections/Blogs";
import Testimonials from "../Sections/Testimonials";

const services = [
  {
    id: 1,
    title: "Daily and Weekly Car Rentals",
    description:
      "Flexible rental options available for both short-term and weekly needs, ideal for vacations or business trips.",
    image: Service1,
  },
  {
    id: 2,
    title: "Long-Term Rentals",
    description:
      "Convenient and cost-effective solutions for those needing a vehicle for an extended period, with discounted rates.",
    image: Service2,
  },
  {
    id: 3,
    title: "Car Leasing Options",
    description:
      "Drive in style with our selection of high-end vehicles, perfect for leasing or on rent with discounted rates.",
    image: Service3,
  },
];

const swiperImages = [CtaImg1, CtaImg1];

const features = [
  "Detailed vehicle descriptions and images",
  "Filter options by vehicle type, size, and features",
  "Availability information in real-time",
];

const Services = () => {
  const [isOpen, setOpen] = useState(false);

  const swiperGroup1 = {
    modules: [Autoplay, Pagination, Navigation],
    slidesPerView: 1,
    spaceBetween: 50,
    slidesPerGroup: 1,
    loop: true,
    navigation: {
      nextEl: ".swiper-button-next",
      prevEl: ".swiper-button-prev",
    },
    pagination: {
      el: ".swiper-pagination-group-1",
      clickable: true,
    },
    autoplay: {
      delay: 100000,
    },
  };
  return (
    <Layout>
      <div>
        <div className="page-header pt-30 background-body">
          <div className="custom-container position-relative mx-auto">
            <div className="bg-overlay rounded-12 overflow-hidden">
              <img
                className="w-100 h-100 img-banner"
                src={Banner}
                alt="Rapid Cars"
              />
            </div>
            <div className="container position-absolute z-1 top-50 start-50 translate-middle">
              <h2 className="text-white">Our Services</h2>
              <span className="text-white text-xl-medium">
                Perfect service, top experts
              </span>
            </div>
            <div className="background-body position-absolute z-1 top-100 start-50 translate-middle px-3 py-2 rounded-12 border d-flex gap-3 @@navigation-page">
              <Link to="/" className="neutral-700 text-md-medium">
                Home
              </Link>
              <span>
                <img src={RightArrow} alt="Rapid Cars" />
              </span>
              <Link className="neutral-1000 text-md-bold">Services</Link>
            </div>
          </div>
        </div>
        {/* section-1 */}
        <section className="section-1 py-96 background-body">
          <div className="container">
            <div className="row align-items-end">
              <div className="col-lg-7">
                <h3 className="neutral-1000">
                  Comprehensive <span className="text-primary">Car Rental</span>{" "}
                  Services to Meet All Your Needs
                </h3>
              </div>
              <div className="col-lg-5">
                <p className="text-lg-medium neutral-500">
                  From daily rentals to long-term solutions, we offer a
                  comprehensive range of vehicles and services to suit every
                  need and budget.
                </p>
              </div>
            </div>
            <div className="row mt-50">
              {services.map((service) => (
                <div className="col-lg-4 col-md-6" key={service.id}>
                  <div className="card-news background-card hover-up mb-24">
                    <div className="card-image">
                      <img src={service.image} alt="Rapid Cars" />
                    </div>
                    <div className="card-info">
                      <div className="card-title mb-3">
                        <Link
                          className="text-xl-bold neutral-1000"
                          to="/blog-details"
                        >
                          {service.title}
                        </Link>
                        <p className="text-md-medium neutral-500 mt-2">
                          {service.description}
                        </p>
                      </div>
                      <div className="card-program">
                        <div className="endtime">
                          <div className="card-button">
                            <Link className="btn btn-primary2" to="/listing">
                              Get Started
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </section>
        {/* CTA Section */}
        <section className="section-box-banner-3 banner-2 background-body">
          <div className="container pt-110 pb-110 position-relative z-1 mb-60">
            <div className="row ">
              <div className="col-auto text-center wow fadeInUp justify-content-center d-flex flex-column align-items-center">
                <h2 className="text-white">Best Car Rent Deals</h2>
                <h6 className="text-white">
                  Save 15% or more when you book and ride <br />
                  before 1 April 2025
                </h6>
                <Link
                  className="btn btn-primary rounded-pill btn-lg mt-20"
                  to="/listing"
                >
                  Find Early 2025 Deals
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width={25}
                    height={24}
                    viewBox="0 0 25 24"
                    fill="none"
                  >
                    <path
                      d="M12.5 19L19.5 12L12.5 5M19.5 12L5.5 12"
                      stroke="black"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </Link>
              </div>
            </div>
          </div>
        </section>
        {/* Testimonials */}
        <Testimonials />
        {/* CTA section-2 */}
        <section className="section-cta-7 background-body mt-60 mb-60">
          <div className="box-cta-6">
            <div className="container">
              <div className="row align-items-center">
                <div className="col-lg-6">
                  <div className="box-swiper">
                    <Swiper
                      {...swiperGroup1}
                      className="swiper-container swiper-group-1"
                    >
                      {swiperImages.map((image, index) => (
                        <SwiperSlide key={index} className="swiper-slide">
                          <img
                            className="rounded-12"
                            src={image}
                            alt={`Slide ${index + 1}`}
                          />
                        </SwiperSlide>
                      ))}
                      <div className="position-absolute end-0 bottom-0 p-40">
                        <div className="box-button-slider box-button-slider-team justify-content-end">
                          <div
                            className="swiper-button-prev swiper-button-prev-style-1 swiper-button-prev-2"
                            tabIndex={0}
                            role="button"
                            aria-label="Previous slide"
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width={16}
                              height={16}
                              viewBox="0 0 16 16"
                              fill="none"
                            >
                              <path
                                d="M7.99992 3.33325L3.33325 7.99992M3.33325 7.99992L7.99992 12.6666M3.33325 7.99992H12.6666"
                                stroke="currentColor"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                            </svg>
                          </div>
                          <div
                            className="swiper-button-next swiper-button-next-style-1 swiper-button-next-2"
                            tabIndex={0}
                            role="button"
                            aria-label="Next slide"
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width={16}
                              height={16}
                              viewBox="0 0 16 16"
                              fill="none"
                            >
                              <path
                                d="M7.99992 12.6666L12.6666 7.99992L7.99992 3.33325M12.6666 7.99992L3.33325 7.99992"
                                stroke="currentColor"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                            </svg>
                          </div>
                        </div>
                      </div>
                    </Swiper>
                  </div>
                </div>
                <div className="col-lg-6 ps-lg-5 mt-lg-0 mt-4">
                  <h4 className="mb-4 neutral-1000">Plan Your Trip with Us</h4>
                  <p className="text-lg-medium neutral-500 mb-4">
                    Let us help you make your next journey smooth and
                    enjoyable—get started today.
                  </p>
                  <div className="row">
                    <div className="col">
                      <ul className="list-ticks-green list-ticks-green-2">
                        {features.map((feature, index) => (
                          <li key={index} className="neutral-1000 pe-0">
                            {feature}
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>
                  <Link className="btn btn-primary mt-2" to="/listing">
                    Get Started Now
                    <svg
                      width={16}
                      height={16}
                      viewBox="0 0 16 16"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M8 15L15 8L8 1M15 8L1 8"
                        stroke="currentColor"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </Link>
                </div>
              </div>
            </div>
            <div className="bg-overlay position-absolute bottom-0 end-0 h-75 background-brand-2 opacity-25 z-0 rounded-start-pill" />
          </div>
        </section>
        {/* Counter Section */}
        <section className="section-static-1 background-body background-2 pt-80 pb-80">
          <div className="container">
            <div className="row">
              <div>
                <div className="wow fadeIn">
                  <div className="d-flex align-items-center justify-content-around flex-wrap">
                    <div className="mb-4 mb-lg-0 d-block px-lg-5 px-3">
                      <div className="d-flex justify-content-center justify-content-md-start">
                        <h3 className="count neutral-1000">
                          <CounterUp count={45} />
                        </h3>
                        <h3 className="neutral-1000">+</h3>
                      </div>
                      <div className="text-md-start text-center">
                        <p className="text-lg-bold neutral-1000">Global</p>
                        <p className="text-lg-bold neutral-1000">Branches</p>
                      </div>
                    </div>
                    <div className="mb-4 mb-lg-0 d-block px-lg-5 px-3">
                      <div className="d-flex justify-content-center justify-content-md-start">
                        <h3 className="count neutral-1000">
                          <CounterUp count={29} />
                        </h3>
                        <h3 className="neutral-1000">K</h3>
                      </div>
                      <div className="text-md-start text-center">
                        <p className="text-lg-bold neutral-1000">
                          Destinations
                        </p>
                        <p className="text-lg-bold neutral-1000">
                          Collaboration
                        </p>
                      </div>
                    </div>
                    <div className="mb-4 mb-lg-0 d-block px-lg-5 px-3">
                      <div className="d-flex justify-content-center justify-content-md-start">
                        <h3 className="count neutral-1000">
                          <CounterUp count={20} />
                        </h3>
                        <h3 className="neutral-1000">+</h3>
                      </div>
                      <div className="text-md-start text-center">
                        <p className="text-lg-bold neutral-1000">Years</p>
                        <p className="text-lg-bold neutral-1000">Experience</p>
                      </div>
                    </div>
                    <div className="mb-4 mb-lg-0 d-block px-lg-5 px-3">
                      <div className="d-flex justify-content-center justify-content-md-start">
                        <h3 className="count neutral-1000">
                          <CounterUp count={168} />
                        </h3>
                        <h3 className="neutral-1000">K</h3>
                      </div>
                      <div className="text-md-start text-center">
                        <p className="text-lg-bold neutral-1000">Happy</p>
                        <p className="text-lg-bold neutral-1000">Customers</p>
                      </div>
                    </div>
                    <div className="mb-4 mb-lg-0 d-block px-lg-5 px-3">
                      <div className="d-flex justify-content-center justify-content-md-start">
                        <h3 className="count neutral-1000">
                          <CounterUp count={15} />
                        </h3>
                        <h3 className="neutral-1000">M</h3>
                      </div>
                      <div className="text-md-start text-center">
                        <p className="text-lg-bold neutral-1000">User</p>
                        <p className="text-lg-bold neutral-1000">Account</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* Blogs */}
        <Blog />
      </div>
    </Layout>
  );
};

export default Services;
