import {
  Car as CarIcon,
  File as FileIcon,
  Shield as ShieldIcon,
  Package,
} from "lucide-react";

export default function HowItWorks({ cls }) {
  return (
    <>
      <section className={`section-box box-why-book-22 background-body ${cls}`}>
        <div className="container">
          <div className="text-center wow fadeInUp">
            <p className="text-xl-medium neutral-500 wow fadeInUp">
              HOW IT WORKS
            </p>
            <h3 className="neutral-1000 wow fadeInUp">
              Presenting Your New Go-To Car <br className="d-none d-lg-block" />
              Rental Experience
            </h3>
          </div>
          <div className="row mt-40">
            <div className="col-lg-3 col-sm-6">
              <div className="card-why wow fadeIn" data-wow-delay="0.1s">
                <div>
                  <CarIcon
                    className="howitWkCardIcn icon-stroke-light"
                    size={24}
                  />
                </div>
                <div className="card-info">
                  <h6 className="text-xl-bold neutral-1000">
                    Browse Available Cars
                  </h6>
                  <p className="text-md-medium neutral-500">
                    Select from our fleet of well-maintained, fuel-efficient
                    vehicles for your rideshare or delivery needs.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-sm-6">
              <div className="card-why wow fadeIn" data-wow-delay="0.2s">
                <div>
                  <FileIcon
                    className="howitWkCardIcn icon-stroke-light"
                    size={24}
                  />
                </div>
                <div className="card-info">
                  <h6 className="text-xl-bold neutral-1000">
                    Submit Your Documents
                  </h6>
                  <p className="text-md-medium neutral-500">
                    Provide necessary documents, including a valid driver's
                    license and insurance details, for verification.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-sm-6">
              <div className="card-why wow fadeIn" data-wow-delay="0.3s">
                <div>
                  <ShieldIcon
                    className="howitWkCardIcn icon-stroke-light"
                    size={24}
                  />
                </div>
                <div className="card-info">
                  <h6 className="text-xl-bold neutral-1000">
                    Insurance & Approval
                  </h6>
                  <p className="text-md-medium neutral-500">
                    Our team will verify your documents and confirm insurance
                    before finalizing your booking.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-sm-6">
              <div className="card-why wow fadeIn" data-wow-delay="0.4s">
                <div>
                  <Package
                    className="howitWkCardIcn icon-stroke-light"
                    size={24}
                  />
                </div>
                <div className="card-info">
                  <h6 className="text-xl-bold neutral-1000">Pick Up & Drive</h6>
                  <p className="text-md-medium neutral-500">
                    Once approved, visit our designated location, sign the
                    agreement, and start earning with your leased car.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
