import React, { useState, useEffect, lazy, Suspense } from 'react';

const Odometer = lazy(() => import('react-odometerjs'));

const CounterUp = ({ count, duration = 5000 }) => {
  const [currentValue, setCurrentValue] = useState(0);

  useEffect(() => {
    let start = 0;
    const increment = count / (duration / 1000); // Updates every 50ms
    const interval = setInterval(() => {
      start += increment;
      if (start >= count) {
        start = count; // Ensure the final value matches the target
        clearInterval(interval);
      }
      setCurrentValue(Math.round(start)); // Update the state
    }, 50);

    return () => clearInterval(interval); // Cleanup on unmount
  }, [count, duration]);

  return (
    <Suspense fallback={<span>0</span>}>
      <Odometer value={currentValue} className="odometer" />
    </Suspense>
  );
};

export default CounterUp;