import { useState } from "react";
import { FaEye, FaEyeSlash } from "react-icons/fa"; // Import eye icons
import Layout from "../Layout/Layout";
import { useLocation } from "react-router-dom";
import {
  server_post_data,
  check_resetlink_vaild,
  change_password_by_forgot,
} from "../../ServiceConnection/serviceconnection.js";
import {
  handlePasswordChange,
  check_vaild_save,
  combiled_form_data,
  handleLinkClick,
} from "../../CommonJquery/CommonJquery";
export default function Login() {
  const location = useLocation();
  const currentUrl = location.pathname.substring(1);
  const [isLogin, setIsLogin] = useState(true);
  const [error, setError] = useState("");
  const [formData, setFormData] = useState({
    Currentpassword: "",
    password: "",
    confirmPassword: "",
  });
  const [currentpasswordVisible, setcurrentpasswordVisible] = useState(false);
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [confirmPasswordVisible, setConfirmPasswordVisible] = useState(false);
  const [forgotPassword, setForgotPassword] = useState(false);

  // Validation error states
  const [errors, setErrors] = useState({
    Currentpassword: "",
    password: "",
    confirmPassword: "",
  });
  const [shake, setShake] = useState(false); // Track the shake animation

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));

    // Remove error message when user starts typing
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: "",
    }));
  };
  const [showLoaderAdmin, setshowLoaderAdmin] = useState(false);
  const handleSignUp = async (e) => {
    e.preventDefault();
    let valid = true;
    const newErrors = { ...errors };

    if (!forgotPassword && formData.Currentpassword === "") {
      newErrors.Currentpassword = "Please enter the current password.";
      valid = false;
    }
    if (formData.password === "") {
      newErrors.password = "Please enter the new password.";
      valid = false;
    }

    if (formData.confirmPassword === "") {
      newErrors.confirmPassword = "Please confirm the new password.";
      valid = false;
    }

    if (formData.password !== formData.confirmPassword) {
      newErrors.confirmPassword = "Passwords do not match.";
      valid = false;
    }

    setErrors(newErrors);

    if (!valid) {
      setShake(true);
      setTimeout(() => setShake(false), 300);
      return;
    }

    if (valid) {
      setshowLoaderAdmin(true);
      let fd_from = new FormData();
      fd_from.append("current_url_token", currentUrl.split("/")[1]);
      fd_from.append("new_password", formData.password);
      fd_from.append("confirm_new_password", formData.Currentpassword);
      await server_post_data(change_password_by_forgot, fd_from)
        .then((Response) => {
          setshowLoaderAdmin(false);
          setFormData({
            Currentpassword: "",
            password: "",
            confirmPassword: "",
          });
          handleLinkClick("/login");
        })
        .catch((error) => {
          setshowLoaderAdmin(false);
        });
    }
  };

  const handleForgotPasswordClick = () => {
    setForgotPassword(true); // Set forgotPassword state to true when clicked
    setFormData({ ...formData, Currentpassword: "" }); // Optionally reset current password field
  };

  return (
    <>
      <Layout>
        <div className="container pt-70 pb-70">
          <div className="row">
            <div className="col-lg-5 mx-auto">
              <div className="register-content border rounded-3 px-md-5 px-3 ptb-50">
                <div className="text-center">
                  <p className="neutral-1000 px-4 py-2 bg-2 text-sm-bold rounded-12 d-inline-flex align-items-center">
                    {forgotPassword ? "Save Password" : "Reset Password"}
                  </p>
                  <h4 className="neutral-1000">
                    {forgotPassword ? "Set New Password" : "Enter New Password"}
                  </h4>
                  {error && <p className="text-danger text-center">{error}</p>}
                </div>
                <div className="form-login mt-30">
                  <form onSubmit={handleSignUp}>
                    {/* Current Password */}
                    {!forgotPassword && (
                      <div className="form-group position-relative">
                        <input
                          className={`form-control confirm-password ${
                            shake && errors.Currentpassword ? "shake" : ""
                          }`}
                          type={currentpasswordVisible ? "text" : "password"}
                          placeholder="Current Password"
                          name="Currentpassword"
                          value={formData.Currentpassword}
                          onChange={handleChange}
                        />
                        {errors.Currentpassword && (
                          <span className="text-danger">
                            {errors.Currentpassword}
                          </span>
                        )}
                        <span
                          className="position-absolute eye-icon"
                          onClick={() =>
                            setcurrentpasswordVisible(!currentpasswordVisible)
                          }
                          style={{
                            right: "10px",
                            top: "50%",
                            transform: "translateY(-50%)",
                            cursor: "pointer",
                          }}
                        >
                          {currentpasswordVisible ? <FaEyeSlash /> : <FaEye />}
                        </span>
                      </div>
                    )}

                    {/* New Password */}
                    <div className="form-group position-relative">
                      <input
                        className={`form-control confirm-password ${
                          shake && errors.password ? "shake" : ""
                        }`}
                        type={passwordVisible ? "text" : "password"}
                        placeholder="New Password"
                        name="password"
                        value={formData.password}
                        onChange={handleChange}
                      />
                      {errors.password && (
                        <span className="text-danger">{errors.password}</span>
                      )}
                      <span
                        className="position-absolute eye-icon"
                        onClick={() => setPasswordVisible(!passwordVisible)}
                        style={{
                          right: "10px",
                          top: "50%",
                          transform: "translateY(-50%)",
                          cursor: "pointer",
                        }}
                      >
                        {passwordVisible ? <FaEyeSlash /> : <FaEye />}
                      </span>
                    </div>

                    {/* Confirm Password */}
                    <div className="form-group position-relative">
                      <input
                        className={`form-control confirm-password ${
                          shake && errors.confirmPassword ? "shake" : ""
                        }`}
                        type={confirmPasswordVisible ? "text" : "password"}
                        placeholder="Confirm Password"
                        name="confirmPassword"
                        value={formData.confirmPassword}
                        onChange={handleChange}
                      />
                      {errors.confirmPassword && (
                        <span className="text-danger">
                          {errors.confirmPassword}
                        </span>
                      )}
                      <span
                        className="position-absolute eye-icon"
                        onClick={() =>
                          setConfirmPasswordVisible(!confirmPasswordVisible)
                        }
                        style={{
                          right: "10px",
                          top: "50%",
                          transform: "translateY(-50%)",
                          cursor: "pointer",
                        }}
                      >
                        {confirmPasswordVisible ? <FaEyeSlash /> : <FaEye />}
                      </span>
                    </div>

                    {/* Forgot Password Link */}
                    {!forgotPassword && (
                      <div className="form-group">
                        <div className="box-remember-forgot">
                          <div
                            className="forgotpass"
                            onClick={handleForgotPasswordClick} // Handle click event
                          >
                            <p className="text-xs-medium neutral-500">
                              Forgot password?
                            </p>
                          </div>
                        </div>
                      </div>
                    )}

                    <div className="form-group mt-40 mb-30">
                      <button type="submit" className="btn btn-primary w-100">
                        {forgotPassword ? "Save Password" : "Reset Password"}
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
}
