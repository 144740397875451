import React, { useEffect, useState } from "react";
import Banner from "../../Assests/imgs/page-header/banner6.png";
import {
  APL_LINK,
  get_favorite_car_website,
  server_post_data,
} from "../../ServiceConnection/serviceconnection";
import ByPagination from "../Elements/ByPagination";
import CarCard from "../Elements/CarCard";
import useCarFilter from "../Elements/useCarFilter";
import Layout from "../Layout/Layout";

const Favorites = () => {
  const [favouritedata, setfavouritedata] = useState([]);
  const [favState, setfavState] = useState();
  useEffect(() => {
    fetchFavorites();
  }, []);
  const [carsImage, setCarsImage] = useState([]);
  // Fetch favorites using flag=2
  const fetchFavorites = async () => {
    try {
      const payload = new FormData();
      payload.append("flag", 2); // Set flag=2 for fetching favorites

      const response = await server_post_data(
        get_favorite_car_website,
        payload
      );
      console.log("response", response);
      if (response.error) {
        console.error(
          "Failed to fetch favorites:",
          response.message.booking_data
        );
        return;
      }
      // setfavouritedata(response.message.booking_data);
      // Assuming response contains a list of cars
      const fetchedCars = response.message.booking_data;
      const fetchedimage = response.message.image_link;
      setfavouritedata(fetchedCars);
      setCarsImage(APL_LINK + fetchedimage);
      setLikedCars(fetchedCars.map((car) => car.id)); // Store liked car IDs
    } catch (error) {
      console.error("Error fetching favorites:", error);
    }
  };

  const [likedCars, setLikedCars] = useState([]);

  const {
    filter,
    setFilter,
    sortCriteria,
    setSortCriteria,
    itemsPerPage,
    setItemsPerPage,
    currentPage,
    setCurrentPage,
    uniqueNames,
    uniqueFuelTypes,
    uniqueAmenities,
    uniqueLocations,
    uniqueRatings,
    uniqueCarTypes,
    filteredCars,
    sortedCars,
    totalPages,
    startIndex,
    endIndex,
    paginatedCars,
    handleCheckboxChange,
    handleSortChange,
    handlePriceRangeChange,
    handleItemsPerPageChange,
    handlePageChange,
    handlePreviousPage,
    handleNextPage,
    handleClearFilters,
    startItemIndex,
    endItemIndex,
  } = useCarFilter(favouritedata);

  const [selectedCata, setselectedCata] = useState("all");

  const handleCata = (cata) => {
    setselectedCata(cata);
  };

  return (
    <Layout headerStyle={2} footerStyle={1}>
      <div>
        <div className="page-header-2 pt-30 background-body">
          <div className="custom-container position-relative mx-auto">
            <div className="bg-overlay rounded-12 overflow-hidden">
              <img
                className="w-100 h-100 img-fluid img-banner"
                src={Banner}
                alt="Carento"
              />
            </div>
            <div className="container position-absolute z-1 top-50 start-50 pb-70 translate-middle text-center">
              <span className="text-sm-bold bg-2 px-4 py-3 rounded-12">
                Find rental cars available near you.
              </span>
              <h2 className="text-white mt-4">
                Locate the Car That Fits You Best
              </h2>
              <span className="text-white text-lg-medium">
                Search and find your best car rental with an easy way
              </span>
            </div>
          </div>
        </div>

        {/* cars-listing-head */}
        <section className="section-box pt-50 background-body">
          <div className="container">
            <div className="row align-items-end">
              <div className="col-md-9 mb-30 wow fadeInUp">
                <h4 className="title-svg neutral-1000 mb-15">
                  Explore Our Diverse Vehicle Fleet
                </h4>
                <p className="text-lg-medium text-bold neutral-500">
                  Find the ideal vehicle for your journey—reliable, versatile,
                  and ready for any adventure.
                </p>
              </div>
            </div>
          </div>
        </section>
        <section className="box-section block-content-tourlist background-body">
          <div className="container-lg">
            <div className="box-content-main pt-20">
              <div className="content-right">
                <div className="box-grid-tours wow fadeIn">
                  <div className="row">
                    {favouritedata.map((car, index) => (
                      <div className="col-lg-4 col-md-6" key={index}>
                        {/* {console.log("sjkdhkjsh",carsImage + car.car_data[0].image)} */}
                        <CarCard
                          car={car.car_data && car.car_data[0]}
                          imgLink={carsImage}
                          match_and_return_seo_link={(id) =>
                            `/cars/car_details/${id}`
                          }
                          likedCars={likedCars}
                          setLikedCars={setLikedCars}
                          index={car.id}
                          fetchFavorites={fetchFavorites}
                        />
                      </div>
                    ))}
                  </div>
                </div>
                <ByPagination
                  handlePreviousPage={handlePreviousPage}
                  totalPages={totalPages}
                  currentPage={currentPage}
                  handleNextPage={handleNextPage}
                  handlePageChange={handlePageChange}
                />
              </div>
            </div>
          </div>
        </section>
      </div>
    </Layout>
  );
};

export default Favorites;
