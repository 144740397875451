import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import {
  server_post_data,
  get_faq_testimonial_data,
} from "../../ServiceConnection/serviceconnection";
import { handleError } from "../../CommonJquery/CommonJquery";

function FAQ() {
  const [isAccordion, setIsAccordion] = useState(0);
  const [showLoaderAdmin, setShowLoaderAdmin] = useState(false);
  const [faqData, setFaqData] = useState([]);

  useEffect(() => {
    master_data_get();
  }, []);

  const master_data_get = async () => {
    setShowLoaderAdmin(true);
    const fd = new FormData();
    fd.append("flag", 1);
    await server_post_data(get_faq_testimonial_data, fd)
      .then((Response) => {
        if (Response.error) {
          handleError(Response.message);
        } else {
          setFaqData(Response.message.faq_data);
        }
        setShowLoaderAdmin(false);
      })
      .catch((error) => {
        console.log(error);
        setShowLoaderAdmin(false);
      });
  };

  const handleAccordion = (key) => {
    setIsAccordion((prevState) => (prevState === key ? null : key));
  };

  return (
    <section className="section-box box-faqs background-body pt-0">
      <div className="box-faqs-inner">
        <div className="container">
          <div className="text-center">
            <span className="text-sm-bold bg-2 p-3 rounded-12">
              Our Support
            </span>
            <h3 className="mt-4 neutral-1000">Frequently Asked Questions</h3>
          </div>
          <div className="block-faqs">
            <div className="accordion" id="accordionFAQ">
              {faqData &&
                faqData.length > 0 &&
                faqData.map((faq, index) => (
                  <div
                    key={index}
                    className="accordion-item wow fadeInUp border-bottom-0"
                  >
                    <h5
                      className="accordion-header"
                      id={`heading${index}`}
                      onClick={() => handleAccordion(index)}
                    >
                      <button
                        className={`accordion-button text-heading-5 ${
                          isAccordion === index ? "collapsed" : ""
                        }`}
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target={`#collapse${index}`}
                        aria-expanded={isAccordion === index}
                        aria-controls={`collapse${index}`}
                      >
                        <h3>
                          {index < 9 && 0}
                          {index + 1}
                        </h3>
                        <p>{faq.question_name}</p>
                      </button>
                    </h5>
                    <div
                      className={`accordion-collapse collapse ${
                        isAccordion === index ? "show" : ""
                      }`}
                      id={`collapse${index}`}
                      aria-labelledby={`heading${index}`}
                      data-bs-parent="#accordionFAQ"
                    >
                      <div className="accordion-body">{faq.answer_name}</div>
                    </div>
                  </div>
                ))}
            </div>
          </div>
          <div className="row">
            <div className="col-12 wow fadeInUp mt-4">
              <div className="d-flex justify-content-center gap-2">
                <Link className="btn btn-primary mt-2" to="/contact">
                  Contact Us
                  <svg
                    width={16}
                    height={16}
                    viewBox="0 0 16 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M8 15L15 8L8 1M15 8L1 8"
                      stroke="currentColor"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default FAQ;
