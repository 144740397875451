import { useState } from "react";

const useCarFilter = (carsData) => {
  const [filter, setFilter] = useState({
    names: [],
    fuelType: [],
    amenities: [],
    locations: [],
    priceRange: [0, 500],
    ratings: [],
    carType: [],
  });
  const [sortCriteria, setSortCriteria] = useState("name");
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);

  const uniqueNames = [...new Set(carsData.map((car) => car.car_name))];
  const uniqueFuelTypes = [...new Set(carsData.map((car) => car.fuel_type))];
  const uniqueRatings = [...new Set(carsData.map((car) => car.review))];
  const uniqueCarTypes = [...new Set(carsData.map((car) => car.Price))];

  const filteredCars = carsData.filter((car) => {
    return (
      (filter.names.length === 0 || filter.names.includes(car.car_name)) &&
      (filter.fuelType.length === 0 ||
        filter.fuelType.includes(car.fuel_type)) &&
      car.Price >= filter.priceRange[0] &&
      car.Price <= filter.priceRange[1] &&
      (filter.ratings.length === 0 || filter.ratings.includes(car.review))
    );
  });

  const sortedCars = [...filteredCars].sort((a, b) => {
    if (sortCriteria === "name") {
      return a.car_name.localeCompare(b.car_name);
    } else if (sortCriteria === "price") {
      return a.Price - b.Price;
    } else if (sortCriteria === "rating") {
      return b.review - a.review;
    }
    return 0;
  });

  const totalPages = Math.ceil(sortedCars.length / itemsPerPage);
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const paginatedCars = sortedCars.slice(startIndex, endIndex);

  const handleCheckboxChange = (field, value) => (e) => {
    const checked = e.target.checked;
    setFilter((prevFilter) => {
      const values = prevFilter[field];
      if (checked) {
        return { ...prevFilter, [field]: [...values, value] };
      } else {
        return {
          ...prevFilter,
          [field]: values.filter((item) => item !== value),
        };
      }
    });
  };

  const handleSortChange = (e) => {
    setSortCriteria(e.target.value);
  };

  const handlePriceRangeChange = (values) => {
    setFilter((prevFilter) => ({
      ...prevFilter,
      priceRange: values,
    }));
  };

  const handleItemsPerPageChange = (e) => {
    setItemsPerPage(Number(e.target.value));
    setCurrentPage(1);
  };

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handleClearFilters = () => {
    setFilter({
      names: [],
      fuelType: [],
      amenities: [],
      locations: [],
      priceRange: [0, 500],
      ratings: [],
      carType: [],
    });
    setSortCriteria("name");
    setItemsPerPage(4);
    setCurrentPage(1);
  };

  const startItemIndex = (currentPage - 1) * itemsPerPage + 1;
  const endItemIndex = Math.min(
    startItemIndex + itemsPerPage - 1,
    sortedCars.length
  );

  return {
    filter,
    setFilter,
    sortCriteria,
    setSortCriteria,
    itemsPerPage,
    setItemsPerPage,
    currentPage,
    setCurrentPage,
    uniqueNames,
    uniqueFuelTypes,
    uniqueRatings,
    uniqueCarTypes,
    filteredCars,
    sortedCars,
    totalPages,
    startIndex,
    endIndex,
    paginatedCars,
    handleCheckboxChange,
    handleSortChange,
    handlePriceRangeChange,
    handleItemsPerPageChange,
    handlePageChange,
    handlePreviousPage,
    handleNextPage,
    handleClearFilters,
    startItemIndex,
    endItemIndex,
  };
};

export default useCarFilter;
