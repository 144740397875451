import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Banner from "../../Assests/imgs/page-header/bann.jpeg";
import RightArrow from "../../Assests/imgs/template/icons/arrow-right.svg";
import { handleError } from "../../CommonJquery/CommonJquery";
import {
  APL_LINK,
  get_all_cars_website,
  view_all_carmodel_type,
  server_post_data,
} from "../../ServiceConnection/serviceconnection";
import ByPagination from "../Elements/ByPagination";
import CarCard from "../Elements/CarCard";
import HeroSearch from "../Elements/HeroSearch";
import SortCarsFilter from "../Elements/SortCarsFilter";
import useCarFilter from "../Elements/useCarFilter";
import Layout from "../Layout/Layout";
import { retrieveData } from "../../LocalConnection/LocalConnection";
import { useLocation } from "react-router-dom";

const Listing = () => {
  const location = useLocation();
  // Retrieve details from local storage
  const storedSearchDetails = localStorage.getItem("vehicleSearchDetails");
  const parsedSearchDetails = storedSearchDetails
    ? JSON.parse(storedSearchDetails)
    : {};
  const idss = location.state?.id;
  const [showLoaderAdmin, setShowLoaderAdmin] = useState(false);
  const [likedCars, setLikedCars] = useState([]);
  const [cars, setcars] = useState([]);
  const [imgLink, setImgLink] = useState();
  const [SEOloop, setSEOloop] = useState([]);
  const [carDetails, setCarDetails] = useState([]);
  let idsssssssssss = retrieveData("SelectCarTypeId");
  let carnamess = retrieveData("SelectCarTypeName");
  const [selectedCata, setselectedCata] = useState("all");
  const [selectedCataid, setselectedCataid] = useState(
    parsedSearchDetails.categoryId ||
      location.state?.id ||
      retrieveData("SelectCarTypeId") ||
      0
  );
  const carsData = cars.map((car) => ({
    ...car,
    rating: parseFloat(car.rating),
  }));
  useEffect(() => {
    setselectedCata(carnamess);
    setselectedCataid(idss);
  }, []);
  // Destructuring the custom hook to manage car filters and pagination
  const {
    filter,
    setFilter,
    sortCriteria,
    setSortCriteria,
    itemsPerPage,
    setItemsPerPage,
    currentPage,
    setCurrentPage,
    uniqueNames,
    uniqueFuelTypes,
    uniqueAmenities,
    uniqueLocations,
    uniqueRatings,
    uniqueCarTypes,
    filteredCars,
    sortedCars,
    totalPages,
    startIndex,
    endIndex,
    paginatedCars,
    handleCheckboxChange,
    handleSortChange,
    handlePriceRangeChange,
    handleItemsPerPageChange,
    handlePageChange,
    handlePreviousPage,
    handleNextPage,
    handleClearFilters,
    startItemIndex,
    endItemIndex,
  } = useCarFilter(carsData);

  const handleCata = (cata, id) => {
    setselectedCata(cata);
    setselectedCataid(id);

    // Update local storage with new category
    const existingSearchDetails = JSON.parse(
      localStorage.getItem("vehicleSearchDetails") || "{}"
    );

    localStorage.setItem(
      "vehicleSearchDetails",
      JSON.stringify({
        ...existingSearchDetails,
        categoryId: id,
      })
    );
  };
  const match_and_return_seo_link = (v_id) => {
    let data_seo_link_final = "/cars/car_details/" + v_id;
    let data_seo_link = data_seo_link_final;
    if (SEOloop) {
      const matchedItem = SEOloop.find((data) => {
        return data_seo_link === data.call_function_name;
      });

      if (matchedItem) {
        data_seo_link_final = matchedItem.pretty_function_name;
      }
    }
    return data_seo_link_final;
  };

  useEffect(() => {
    master_data_get(0);
    master_data_get_details();
  }, []);
  useEffect(() => {
    master_data_get(1);
  }, [selectedCataid]);

  const master_data_get = async (flag) => {
    setShowLoaderAdmin(true);
    const fd = new FormData();
    if (flag == 1) {
      console.log("idsssssssssss", selectedCataid);
      fd.append("model_id", selectedCataid);
    } else {
      fd.append("model_id", 0);
    }
    await server_post_data(get_all_cars_website, fd)
      .then((Response) => {
        if (Response.error) {
          handleError(Response.message);
        } else {
          setcars(Response.message.car_data);
          setSEOloop(Response.message.seo_loop);
          console.log(Response.message.car_data);
          setImgLink(APL_LINK + Response.message.Image_link);
        }
        setShowLoaderAdmin(false);
      })
      .catch((error) => {
        console.log(error);
        setShowLoaderAdmin(false);
      });
  };

  const master_data_get_details = async () => {
    setShowLoaderAdmin(true);
    const fd = new FormData();
    await server_post_data(view_all_carmodel_type, fd)
      .then((Response) => {
        if (Response.error) {
          handleError(Response.message);
        } else {
          setCarDetails(Response.message.car_data);
        }
        setShowLoaderAdmin(false);
      })
      .catch((error) => {
        console.log(error);
        setShowLoaderAdmin(false);
      });
  };
  return (
    <Layout headerStyle={2} footerStyle={1}>
      <div>
        <div className="page-header-2 pt-30 background-body">
          <div className="custom-container position-relative mx-auto">
            <div className="bg-overlay rounded-12 overflow-hidden">
              <img
                className="w-100 h-100 img-fluid img-banner"
                src={Banner}
                alt="Carento"
              />
            </div>
            <div className="container position-absolute z-1 top-50 start-50 pb-70 translate-middle text-center">
              <span className="text-sm-bold bg-2 px-4 py-3 rounded-12 listTOpHEad reposniveClss">
                Get a rental car with ease - hassle-free and ready to drive!
              </span>
              <h2 className="text-white mt-4">
                Locate the Car That Fits You Best
              </h2>
              <span className="text-white text-lg-medium">
                Search and find your best car rental with an easy way
              </span>
            </div>
            <div className="background-body position-absolute z-1 top-100 start-50 translate-middle px-3 py-2 rounded-12 border d-flex gap-3 d-none d-md-flex">
              <Link href="/" className="neutral-700 text-md-medium">
                Home
              </Link>
              <span className="@@ds-prev-page">
                <img src={RightArrow} alt="Carento" />
              </span>
              <Link href="#" className="neutral-1000 text-md-bold">
                @@prev-page
              </Link>
              <span>
                <img src={RightArrow} alt="Carento" />
              </span>
              <Link href="#" className="neutral-1000 text-md-bold text-nowrap">
                @@current-page
              </Link>
            </div>
          </div>
        </div>
        {/* Search Box */}
        <section className="box-section box-search-advance-home10 background-body">
          <div className="container">
            <div className="box-search-advance background-card wow fadeIn">
              <div className="box-top-search">
                <div className="left-top-search">
                  <Link
                    className={`category-link text-sm-bold btn-click ${
                      selectedCata === "all" ? "active" : ""
                    }`}
                    onClick={() => handleCata("all", 0)}
                  >
                    All cars
                  </Link>
                  {carDetails &&
                    carDetails.length > 0 &&
                    carDetails.map((car, index) => (
                      <Link
                        key={index}
                        className={`category-link text-sm-bold btn-click ${
                          selectedCata === car.name ? "active" : ""
                        }
                        }`}
                        onClick={() => handleCata(car.name, car.primary_id)}
                      >
                        {car.name}
                      </Link>
                    ))}
                  {/* <Link
                    className={`category-link text-sm-bold btn-click ${
                      selectedCata === "suv" ? "active" : ""
                    }`}
                    onClick={() => handleCata("suv")}
                  >
                    SUV
                  </Link> */}
                </div>
                <div className="right-top-search d-none d-md-flex">
                  <Link className="text-sm-medium need-some-help" to="/contact">
                    Need help?
                  </Link>
                </div>
              </div>
              <HeroSearch selectedCataid={selectedCataid} />
            </div>
          </div>
        </section>
        {/* cars-listing-head */}
        <section className="section-box pt-50 background-body">
          <div className="container">
            <div className="row align-items-end">
              <div className="col-md-9 mb-30 wow fadeInUp">
                <h4 className="title-svg neutral-1000 mb-15">
                  Our Vehicle Fleet
                </h4>
                <p className="text-lg-medium text-bold neutral-500">
                  Turning dreams into reality with versatile vehicles.
                </p>
              </div>
            </div>
          </div>
        </section>
        <section className="box-section block-content-tourlist background-body">
          <div className="container">
            <div className="box-content-main pt-20">
              <div className="content-right">
                <div className="box-filters mb-25 pb-5 border-bottom border-1">
                  <SortCarsFilter
                    sortCriteria={sortCriteria}
                    handleSortChange={handleSortChange}
                    itemsPerPage={itemsPerPage}
                    handleItemsPerPageChange={handleItemsPerPageChange}
                    handleClearFilters={handleClearFilters}
                    startItemIndex={startItemIndex}
                    endItemIndex={endItemIndex}
                    sortedCars={sortedCars}
                  />
                </div>
                <div className="box-grid-tours wow fadeIn">
                  <div className="row">
                    {paginatedCars.map((car, index) => (
                      <div className="col-lg-4 col-md-6" key={car.id}>
                        <CarCard
                          index={index}
                          key={index}
                          car={car}
                          imgLink={imgLink}
                          match_and_return_seo_link={match_and_return_seo_link}
                          setLikedCars={setLikedCars}
                          likedCars={likedCars}
                        />
                      </div>
                    ))}
                  </div>
                </div>
                <ByPagination
                  handlePreviousPage={handlePreviousPage}
                  totalPages={totalPages}
                  currentPage={currentPage}
                  handleNextPage={handleNextPage}
                  handlePageChange={handlePageChange}
                />
              </div>
            </div>
          </div>
        </section>
      </div>
    </Layout>
  );
};

export default Listing;
