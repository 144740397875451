import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Autoplay, Navigation, Pagination } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import { formatDateString, handleError } from "../../CommonJquery/CommonJquery";
import {
  APL_LINK,
  get_blog_data_website,
  server_post_data,
} from "../../ServiceConnection/serviceconnection";

const swiperGroup3 = {
  modules: [Autoplay, Pagination, Navigation],
  slidesPerView: 3,
  spaceBetween: 30,
  slidesPerGroup: 1,
  loop: true,
  navigation: {
    nextEl: ".swiper-button-next",
    prevEl: ".swiper-button-prev",
  },
  autoplay: {
    delay: 10000,
  },
  breakpoints: {
    1199: {
      slidesPerView: 3,
    },
    800: {
      slidesPerView: 2,
    },
    400: {
      slidesPerView: 1,
    },
    250: {
      slidesPerView: 1,
    },
  },
};

export default function Blog() {
  const [showLoaderAdmin, setShowLoaderAdmin] = useState(false);
  const [blogData, setBlogData] = useState([]);
  const [SEOloop, setSEOloop] = useState([]);
  const [imgLink, setImgLink] = useState();

  const match_and_return_seo_link = (v_id) => {
    let data_seo_link_final = "/Blogs/blogdetail/" + v_id;
    let data_seo_link = data_seo_link_final;
    if (SEOloop) {
      const matchedItem = SEOloop.find((data) => {
        return data_seo_link === data.call_function_name;
      });

      if (matchedItem) {
        data_seo_link_final = matchedItem.pretty_function_name;
      }
    }
    return data_seo_link_final;
  };

  useEffect(() => {
    master_data_get();
  }, []);

  const master_data_get = async () => {
    setShowLoaderAdmin(true);
    const fd = new FormData();
    fd.append("flag", 1);
    await server_post_data(get_blog_data_website, fd)
      .then((Response) => {
        if (Response.error) {
          handleError(Response.message);
        } else {
          setBlogData(Response.message.blog_list);
          setSEOloop(Response.message.seo_loop);
          setImgLink(APL_LINK + Response.message.blog_image);
        }
        setShowLoaderAdmin(false);
      })
      .catch((error) => {
        console.log(error);
        setShowLoaderAdmin(false);
      });
  };
  const showNavigationButtons = blogData.length > 3;

  return (
    <section className="section-box box-news background-body">
      <div className="container">
        <div className="row align-items-end">
          <div className="col-md-9 mb-30 wow fadeInUp">
            <h3 className="title-svg neutral-1000 mb-15">
              {/* Blogs &amp; Articles */}
              From the RCL Knowledge Hub
            </h3>
            <p className="text-lg-medium text-bold neutral-500">
              Your go-to resource for gig economy news, car leasing tips, and
              industry updates.
            </p>
          </div>
          {showNavigationButtons && (
            <div className="col-md-3 position-relative mb-30 wow fadeInUp">
              <div className="box-button-slider box-button-slider-team justify-content-end">
                <div
                  className="swiper-button-prev swiper-button-prev-style-1 swiper-button-prev-2"
                  tabIndex={0}
                  role="button"
                  aria-label="Previous slide"
                  aria-controls="swiper-wrapper-f147def6ba09c37a"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width={16}
                    height={16}
                    viewBox="0 0 16 16"
                    fill="none"
                  >
                    <path
                      d="M7.99992 3.33325L3.33325 7.99992M3.33325 7.99992L7.99992 12.6666M3.33325 7.99992H12.6666"
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </div>
                <div
                  className="swiper-button-next swiper-button-next-style-1 swiper-button-next-2"
                  tabIndex={0}
                  role="button"
                  aria-label="Next slide"
                  aria-controls="swiper-wrapper-f147def6ba09c37a"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width={16}
                    height={16}
                    viewBox="0 0 16 16"
                    fill="none"
                  >
                    <path
                      d="M7.99992 12.6666L12.6666 7.99992L7.99992 3.33325M12.6666 7.99992L3.33325 7.99992"
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </div>
              </div>
            </div>
          )}
        </div>
        <div className="box-list-news wow fadeInUp mt-5">
          <div className="box-swiper">
            <Swiper
              {...swiperGroup3}
              className="swiper-container swiper-group-3"
              navigation={
                showNavigationButtons
                  ? {
                      nextEl: ".swiper-button-next",
                      prevEl: ".swiper-button-prev",
                    }
                  : false
              }
              loop={showNavigationButtons}
            >
              <div className="swiper-wrapper">
                {blogData.map((blog, index) => (
                  <SwiperSlide key={index} className="swiper-slide pt-2">
                    <Link to={match_and_return_seo_link(blog.primary_id)}>
                      <div className="card-news background-card hover-up">
                        <div className="card-image">
                          <img
                            src={blog.image_url}
                            alt={blog.title}
                            className="maxHeightClassBLog"
                            style={{ maxHeight: "250px", height: "250px" }}
                          />
                        </div>
                        <div className="card-info">
                          <Link
                            className="bg-2 rounded-12 position-absolute top-0 end-0 translate-middle-y px-3 py-2 me-4 text-sm-bold"
                            to="/blogs"
                          >
                            {blog.keyword}
                          </Link>
                          <div className="card-meta">
                            <span className="post-date neutral-1000">
                              {formatDateString(blog.entry_date)}
                            </span>
                            <span className="post-time neutral-1000">
                              {blog.read_minit} mins
                            </span>
                          </div>
                          <div className="card-title">
                            <Link
                              className="text-xl-bold neutral-1000"
                              to={match_and_return_seo_link(blog.primary_id)}
                            >
                              {blog.title_name
                                .split(" ")
                                .slice(0, 12)
                                .join(" ") +
                                (blog.title_name.split(" ").length > 12
                                  ? "..."
                                  : "")}
                            </Link>
                          </div>
                          <div className="card-program">
                            <div className="endtime">
                              <div className="card-author">
                                <img
                                  style={{
                                    width: "32px",
                                    height: "32px",
                                    borderRadius: "50%",
                                  }}
                                  src={blog.author_image}
                                  alt={blog.author}
                                />
                                <p className="text-sm-bold neutral-1000">
                                  {blog.author}
                                </p>
                              </div>
                              <div className="card-button">
                                <Link
                                  className="btn btn-gray"
                                  to={match_and_return_seo_link(
                                    blog.primary_id
                                  )}
                                >
                                  Keep Reading
                                </Link>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Link>
                  </SwiperSlide>
                ))}
              </div>
            </Swiper>
          </div>
        </div>
      </div>
    </section>
  );
}
