import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import AllCars from "../../Assests/imgs/categories/categories-1/car1.jpg";
import { handleError } from "../../CommonJquery/CommonJquery";
import {
  APL_LINK,
  server_post_data,
  view_all_carmodel_type,
} from "../../ServiceConnection/serviceconnection";

export default function BrowesTypes() {
  const [showLoaderAdmin, setShowLoaderAdmin] = useState(false);
  const [carTypes, setcarTypes] = useState([]);
  const [imgLink, setImgLink] = useState();
  const [totalCars, setTotalCars] = useState(0);
  useEffect(() => {
    master_data_get();
  }, []);

  const master_data_get = async () => {
    setShowLoaderAdmin(true);
    const fd = new FormData();
    await server_post_data(view_all_carmodel_type, fd)
      .then((Response) => {
        if (Response.error) {
          handleError(Response.message);
        } else {
          setcarTypes(Response.message.car_data);

          const totalCars = Response.message.car_data.reduce(
            (acc, car) => acc + parseInt(car.total || 0),
            0
          );
          setTotalCars(totalCars);
          setImgLink(APL_LINK + Response.message.car_image_link);
        }
        setShowLoaderAdmin(false);
      })
      .catch((error) => {
        console.log(error);
        setShowLoaderAdmin(false);
      });
  };

  return (
    <>
      <section className="section-box background-body py-96">
        <div className="container">
          <div className="row align-items-end mb-40">
            <div className="col-md-8">
              <h3 className="neutral-1000 wow fadeInUp">
                Find Your Work-Ready Vehicle
              </h3>
              <p className="text-xl-medium neutral-500 wow fadeInUp">
                Explore cars built for rideshare, gig work, or your daily
                hustle.
              </p>
            </div>
            <div className="col-md-4">
              <div className="d-flex justify-content-md-end mt-md-0 mt-4">
                <Link className="btn btn-primary wow fadeInUp" to="/listing">
                  View More
                  <svg
                    width={16}
                    height={16}
                    viewBox="0 0 16 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M8 15L15 8L8 1M15 8L1 8"
                      stroke="currentColor"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </Link>
              </div>
            </div>
          </div>
          <div className="box-list-populars">
            <div className="row">
              <div className="col-lg-3 col-sm-6">
                <div
                  className="card-popular background-card hover-up wow fadeIn"
                  data-wow-delay="0.1s"
                >
                  <div className="card-image CarImgSizeAdjust">
                    <Link className="card-title" to="/listing">
                      <img
                        src={AllCars}
                        alt="All Cars"
                        title="All Cars On Rent"
                      />
                    </Link>
                  </div>
                  <div className="card-info">
                    <Link className="card-title" to="/listing">
                      All Types
                    </Link>
                    <div className="card-meta">
                      <div className="meta-links">
                        <Link to="/listing">{totalCars} Vehicles</Link>
                      </div>
                      <div className="card-button">
                        <Link to="/listing">
                          <svg
                            width={10}
                            height={10}
                            viewBox="0 0 10 10"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M5.00011 9.08347L9.08347 5.00011L5.00011 0.916748M9.08347 5.00011L0.916748 5.00011"
                              stroke="currentColor"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                          </svg>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {carTypes.map((car, index) => (
                <div className="col-lg-3 col-sm-6 CarImgSizeAdjust" key={index}>
                  <div
                    className="card-popular background-card hover-up wow fadeIn"
                    data-wow-delay={`0.${index + 2}s`}
                  >
                    <div className="card-image">
                      <Link className="card-title" to="/listing">
                        <img
                          src={imgLink + car.image}
                          alt={car.name}
                          title={`${car.name}s on Rent`}
                        />
                      </Link>
                    </div>
                    <div className="card-info">
                      <Link className="card-title" to="/listing">
                        {car.name}
                      </Link>
                      <div className="card-meta">
                        <div className="meta-links">
                          <Link to="/listing">{car.total} Vehicles</Link>
                        </div>
                        <div className="card-button">
                          <Link to="/listing">
                            <svg
                              width={10}
                              height={10}
                              viewBox="0 0 10 10"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M5.00011 9.08347L9.08347 5.00011L5.00011 0.916748M9.08347 5.00011L0.916748 5.00011"
                                stroke="currentColor"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                            </svg>
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
