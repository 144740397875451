import React, { useState,useEffect } from "react";
import Dropdown from "react-bootstrap/Dropdown";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Link } from "react-router-dom";

export default function HeroSearch({ selectedCataid }) {
  const [startDate, setStartDate] = useState(() => {
    const storedDetails = localStorage.getItem('vehicleSearchDetails');
    return storedDetails 
      ? new Date(JSON.parse(storedDetails).pickupDate) 
      : new Date();
  });

  const [timePeriod, setTimePeriod] = useState(() => {
    const storedDetails = localStorage.getItem('vehicleSearchDetails');
    return storedDetails 
      ? JSON.parse(storedDetails).timePeriod 
      : "1 Days";
  });

  const [currentCategoryId, setCurrentCategoryId] = useState(() => {
    const storedDetails = localStorage.getItem('vehicleSearchDetails');
    return storedDetails 
      ? JSON.parse(storedDetails).categoryId 
      : selectedCataid;
  });

  useEffect(() => {
    const searchDetails = {
      pickupDate: startDate.toISOString(),
      timePeriod: timePeriod,
      categoryId: currentCategoryId || selectedCataid
    };

    localStorage.setItem('vehicleSearchDetails', JSON.stringify(searchDetails));
  }, [startDate, timePeriod, currentCategoryId, selectedCataid]);

  // Update category ID if prop changes
  useEffect(() => {
    if (selectedCataid) {
      setCurrentCategoryId(selectedCataid);
    }
  }, [selectedCataid]);

  const handleTimePeriodChange = (event) => {
    setTimePeriod(event.target.value);
  };


  
  return (
    <div className="box-bottom-search background-card">
      {/* Pick Up Location */}
      <div className="item-search">
        <label className="text-sm-bold neutral-500">Pick Up Location</label>
        <a
          href={`https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(
            "1400 Renaissance Dr UNIT 201, Park Ridge, IL 60068, United States"
          )}`}
          target="_blank"
          rel="noopener noreferrer"
          className="text-blue-500 underline"
        >
          <Dropdown className="dropdown">
            <Dropdown.Toggle
              as="div"
              className="btn btn-secondary dropdown-toggle btn-dropdown-search location-search"
              aria-expanded="false"
            >
              Illinois,Chicago
            </Dropdown.Toggle>
          </Dropdown>
        </a>
      </div>

      {/* Pick Up Date & Time */}
      <div className=" item-search-3 item-search-2 newFlex datehero">
        <div>
          {/* <label className="text-sm-bold neutral-500">
          Pick Up Date &amp; Time
        </label> */}
          <label className="text-sm-bold neutral-500">Pick Up Date</label>
          <div className="d-flex">
            <div className="box-calendar-date">
              <DatePicker
                selected={startDate}
                onChange={(date) => setStartDate(date)}
                className={`
              search-input datepicker
            `}
            minDate={new Date()}
                showMonthDropdown
                showYearDropdown
                dropdownMode="select"
                // dateFormat="yyyy-MM-dd"
              />
            </div>
            {/* <div className="box-calendar-date">
            <select
              className="search-input datepicker"
              value={startTime}
              onChange={handleTimeChange}
            >
              {timeSlots.map((time, index) => (
                <option key={index} value={time}>
                  {time}
                </option>
              ))}
            </select>
          </div> */}
          </div>
        </div>
      </div>

      {/* Return Date & Time */}
      <div className=" item-search-3 bd-none newFlex">
        <div>
          {/* <label className="text-sm-bold neutral-500">
          Return Date &amp; Time
        </label> */}
          <label className="text-sm-bold neutral-500">Select Time Period</label>
          {/* <div className="d-flex">
          <div className="box-calendar-date">
            <DatePicker
              selected={endDate}
              onChange={(date) => setEndDate(date)}
              className={`
              search-input datepicker
            `}
            />
          </div>
          <div className="box-calendar-date">
            <select
              className="search-input datepicker"
              value={endTime}
              onChange={handleTimeEnd}
            >
              {timeSlots.map((time, index) => (
                <option key={index} value={time}>
                  {time}
                </option>
              ))}
            </select>
          </div>
        </div> */}
        <div className="day_icon_svg"> <select
            className="form-select selct_peried_drop_down"
            value={timePeriod}
            onChange={handleTimePeriodChange}
          >
            <option value="Few Days">Few days</option>
            <option value="Few Week">Few Week</option>
            <option value="Few Month">Few Month</option>
          </select></div>
     
        
        </div>
      </div>

      {/* Search Button */}
      <div
        className="item-search bd-none d-flex justify-content-end  text-white"
        style={{ marginLeft: "auto" }}
      >
        <Link to="/listing" state={{ 
            id: currentCategoryId,
            pickupDate: startDate.toISOString(),
            timePeriod: timePeriod
          }}>
          <button className="btn btn-brand-2 text-nowrap">
            <svg
              className="me-2"
              width={20}
              height={20}
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M19 19L14.6569 14.6569M14.6569 14.6569C16.1046 13.2091 17 11.2091 17 9C17 4.58172 13.4183 1 9 1C4.58172 1 1 4.58172 1 9C1 13.4183 4.58172 17 9 17C11.2091 17 13.2091 16.1046 14.6569 14.6569Z"
                stroke="#000000"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
            Find a Vehicle
          </button>
        </Link>
      </div>
    </div>
  );
}
