import React, { useState, useEffect, useRef } from "react";
import Layout from "../Layout/Layout";
import "bootstrap/dist/css/bootstrap.min.css";
import "../CustomCSS/Profile.css"
import {
  server_post_data,
  save_profile,
  get_profile_data,
  APL_LINK
} from "../../ServiceConnection/serviceconnection";
import { Camera } from "lucide-react"
import { handleAphabetsChange, handleError, handleNumbersChange } from "../../CommonJquery/CommonJquery";
const Profile = () => {
  const [formData, setFormData] = useState({
    email: "",
    name: "",
    mobile: "",
  });
  const [errors, setErrors] = useState({
    email: "",
    name: "",
    mobile: "",
  });

  const [ProfileData, setProfileData] = useState([]);
  const [profileImage, setProfileImage] = useState(null);
  const [profileImageAppend, setProfileImageAppend] = useState(null);
  const fileInputRef = useRef(null);
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
    setIsEdited(true);
    if (errors[name]) {
      setErrors({
        ...errors,
        [name]: ''
      });
    }

  };

  const handleNameKeyDown = (e) => {
    const key = e.key;
    if (key !== "Backspace" && !/^[a-zA-Z\s]$/.test(key)) {
      e.preventDefault();
    }
  };

  const handleMobileKeyDown = (e) => {
    const key = e.key;
    if (key !== "Backspace" && !/^[0-9]$/.test(key)) {
      e.preventDefault();
    }
  };

  const handleEmailKeyDown = (e) => {
    const key = e.key;
    if (key !== "Backspace" && !/^[a-zA-Z0-9._@-]$/.test(key)) {
      e.preventDefault();
    }
  };
  const handleEmailChange = (e) => {
    setFormData({ ...formData, email: e.target.value });
  };
  const [isEdited, setIsEdited] = useState(false);
  const [activeTab, setActiveTab] = useState("profile");
  const [otpSent, setOtpSent] = useState(false);
  const [timeLeft, setTimeLeft] = useState(60);
  // Handle form submission

  const sendOtp = () => {
    setOtpSent(true);
    // Start a 1-minute countdown to hide the OTP sent message
    const timer = setInterval(() => {
      setTimeLeft((prevTime) => {
        if (prevTime <= 1) {
          clearInterval(timer);
          return 0;
        }
        return prevTime - 1;
      });
    }, 1000);
  };


  const handleImageClick = () => {
    fileInputRef.current?.click();
  };

  const handleImageChange = (event) => {
    const file = event.target.files?.[0];
    setProfileImageAppend(file)
    if (file) {
      const imageUrl = URL.createObjectURL(file);
      setProfileImage(imageUrl);
      setIsEdited(true);
    }
  };

  useEffect(() => {
    master_data_get();
  }, []);

  const master_data_get = async () => {
    const fd = new FormData();
    // fd.append("flag", 1);
    await server_post_data(get_profile_data, fd)
      .then((Response) => {
        console.log("Response", Response);
        console.log("profliimgg", APL_LINK + Response.message.image_link + Response.message.data.user_image

        )
        if (Response.error) {
          handleError(Response.message);
        } else {
          setProfileData(Response.message.data);
          setFormData({
            email: Response.message.data.email,
            name: Response.message.data.name,
            mobile: Response.message.data.mobile_no,
          });
          setProfileImage(APL_LINK + Response.message.image_link + Response.message.data.user_image)
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  // console.log("profileImage", profileImage)
  // Change profile 
  const handleSubmit = (e) => {
    console.log("changePassTrigger")
  };
  const handleSaveProfileData = (e) => {
    e.preventDefault();
    const newErrors = {};
    if (!formData.name || formData.name.trim() === '') {
      newErrors.name = 'Please fill this field';
    }

    if (!formData.email || formData.email.trim() === '') {
      newErrors.email = 'Please fill this field';
    }

    if (!formData.mobile || formData.mobile.trim() === '') {
      newErrors.mobile = 'Please fill this field';
    }
    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return;
    }
    handleSaveChangesdynamic();
    handleSaveChangesdynamic()
  };
  const handleSaveChangesdynamic = async () => {

    let fd_from = new FormData();
    fd_from.append("firstName ", formData.name);
    fd_from.append("email", formData.email);
    fd_from.append("phone", formData.mobile);
    fd_from.append("profile_image", profileImageAppend);
    await server_post_data(save_profile, fd_from)
      .then((Response) => {

        if (Response.error) {
          console.log(Response.data.message);
        } else {
          setFormData({
            email: "",
            name: "",
            mobile: "",
          });
        }
      })
      .catch((error) => {
        console.log(error);


      });

  };
  return (
    <Layout>
      <div>
        <div className="profileHeading">
          <h2>Profile</h2>

          <div className="container-lg">
            <div className="ProfileTabbs">
              <button
                className={activeTab === "profile" ? "active" : ""}
                onClick={() => setActiveTab("profile")}
              >
                Profile
              </button>
              <button
                className={activeTab === "password" ? "active" : ""}
                onClick={() => setActiveTab("password")}
              >
                Change Password
              </button>
            </div>
          </div>
        </div>

        <div className="profiledataContrr">
          <div className="container-lg">
            {activeTab === "profile" && (
              <form className="form-cnfrom" noValidate onSubmit={handleSaveProfileData}>

                <div className="profile_img_section">
                  <div className="profile-image-container">
                    <div onClick={handleImageClick} className="profile-image-upload">
                      {profileImage ? (
                        <img src={profileImage || "/placeholder.svg"} alt="Profile" className="profile-image" />
                      ) : (
                        <div className="upload-icon">
                          <Camera size={32} />
                          <span className="upload-text">Upload Photo</span>
                        </div>
                      )}
                      <input type="file" ref={fileInputRef} onChange={handleImageChange} className="hidden" accept="image/*" />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6 mb-3">
                    <label htmlFor="email" className="form-label">
                      Email Address
                    </label>
                    <input
                      maxLength={50}
                      type="email"
                      className="form-control"
                      id="email"
                      name="email"
                      defaultValue={formData.email}
                      onChange={handleChange}

                      required
                      onInput={handleEmailChange}
                    />
                    {errors.email && <span className="error-text">{errors.email}</span>}
                  </div>

                  <div className="col-md-6 mb-3">
                    <label htmlFor="name" className="form-label">
                      Name
                    </label>
                    <input
                      maxLength={50}
                      type="text"
                      className="form-control"
                      id="name"
                      name="name"
                      defaultValue={formData.name}
                      onChange={handleChange}

                      required
                      onInput={handleAphabetsChange}
                    />
                    {errors.name && <span className="error-text">{errors.name}</span>}
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-6 mb-3">
                    <label htmlFor="mobile" className="form-label">
                      Mobile Number
                    </label>
                    <input
                      maxLength={10}
                      type="text"
                      className="form-control"
                      id="mobile"
                      name="mobile"
                      defaultValue={formData.mobile}
                      onChange={handleChange}

                      onInput={handleNumbersChange}
                      required

                    />
                    {errors.mobile && <span className="error-text">{errors.mobile}</span>}
                  </div>
                </div>
                <div className="col-md-4 SubmtBttnClass">
                  <button

                    type="submit"
                    className="btn btn-primary w-100"
                    style={{ opacity: isEdited ? 1 : 0.5 }}
                    disabled={!isEdited}
                  >
                    Submit
                  </button>
                </div>
              </form>
            )}

            {activeTab === "password" && (
              // Show the Change Password form
              <form className="form-cnfrom" onSubmit={handleSubmit} noValidate>
                {!otpSent && (
                  <div className="row">
                    <div className="col-md-6 mb-3">
                      <label htmlFor="email" className="form-label">
                        Email Address
                      </label>
                      <input
                        type="email"
                        className="form-control"
                        id="email"
                        name="email"
                        value={formData.email}
                        onChange={handleEmailChange}
                        onKeyDown={handleEmailKeyDown}
                        required
                      />
                    </div>
                  </div>
                )}

                {!otpSent ? (
                  <div className="col-md-4 SubmtBttnClass">
                    <button
                      type="button"
                      className="btn btn-primary w-100"
                      onClick={sendOtp}
                    >
                      Send OTP
                    </button>
                  </div>
                ) : (
                  // If OTP is sent, show the message
                  <div className="col-md-12 mb-3">
                    <span style={{ color: "green" }}>
                      Reset password link sent to your email.
                    </span>
                  </div>
                )}

                {/* Timer logic to hide the message after 1 minute */}
                {otpSent && timeLeft > 0 && (
                  <div className="col-md-12 mb-3">
                    <span style={{ color: "gray" }}>
                      (Link expires in {timeLeft} seconds)
                    </span>
                  </div>
                )}

                {otpSent && timeLeft === 0 && (
                  <div className="col-md-12 mb-3">
                    <span style={{ color: "gray" }}>
                      (The link has expired. Please request a new one.)
                    </span>
                  </div>
                )}
              </form>
            )}
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Profile;
