import { useState, useEffect } from "react";
import {
  Eye,
  Calendar,
  User,
  Phone,
  Mail,
  FileText,
  Plus,
  ChevronDown,
  Clock,
  Upload,
  CheckCircle,
  CreditCard,
  ChevronRight,
  DollarSign,
  Sliders,
  Settings,
  CheckSquare,
  ArrowUp,
  ArrowDown,
  Filter,
  PenToolIcon as Tool,
} from "lucide-react";
import "../../Assests/css/Maintenance.css";
import Layout from "../Layout/Layout";
import dummYImg from "../../Assests/imgs/cars-details/banner.png";

import {
  APL_LINK,
  maintenance_data_user,
  server_post_data,
} from "../../ServiceConnection/serviceconnection";
import { handleError } from "../../CommonJquery/CommonJquery";
import { useLocation } from "react-router-dom";
import PaymentHistrory from "../Sections/PaymentHistrory";
import MaintenanceRecord from "../Sections/MaintenanceRecord.js";
export default function MaintenanceDashboard() {
  const [showMaintenanceModal, setShowMaintenanceModal] = useState(false);
  const [currentStep, setCurrentStep] = useState(0);

  const [activeTab, setActiveTab] = useState("payments");
  const location = useLocation();
  const [carData, setcarData] = useState([]);
  const [paymentHistryData, setpaymentHistryData] = useState([]);
  const [maintainRecord, setmaintainRecord] = useState([]);
  // let newid;
  useEffect(() => {
    if (location.state) {
      const passedData = location.state;
      setcarData(passedData.bookingData);
      let newid = passedData.bookingData.primary_id;
      console.log("newid", newid);
      if (newid !== undefined) {
        master_data_get(newid);
      }
    }
  }, [location]);

  const master_data_get = async (newid) => {
    const fd = new FormData();
    fd.append("primary_id", newid);
    await server_post_data(maintenance_data_user, fd)
      .then((Response) => {
        if (Response.error) {
          handleError(Response.message);
        } else {
          setpaymentHistryData(Response.message.payment);
          setmaintainRecord(Response.message.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleNext = () => {
    if (currentStep < 3) {
      setCurrentStep(currentStep + 1);
    }
  };

  const handlePrevious = () => {
    if (currentStep > 0) {
      setCurrentStep(currentStep - 1);
    }
  };

  const toggleMaintenanceModal = () => {
    setShowMaintenanceModal(!showMaintenanceModal);
    if (!showMaintenanceModal) {
      setCurrentStep(0);
    }
  };

  return (
    <Layout>
      <div className="mainTainceContrr container-lg">
        <div className="dashboard-container">
          <div className="car-profile-card">
            <div className="car-profile-header">
              <div className="car-brand-logo">
                {/* <img src={dummYImg} alt="Car Brand Logo" /> */}
                <img
                  src={`${APL_LINK}assets/carimage/${carData.image}`}
                  alt="Car"
                  className="car-image carImageBooking"
                />
                {/* <div className="car-brand-badge">{carDetails.brand}</div> */}
              </div>
              <div className="car-details">
                <div className="car-model-info">
                  <h2>
                    {carData.car_name}
                    {/* <span className="car-year">{carDetails.year}</span> */}
                  </h2>
                </div>
                <div className="car-owner">
                  <div className="owner-name">
                    <User className="icon" />
                    <span>
                      {carData.first_name} {carData.last_name}
                    </span>
                  </div>
                  <div className="pickup-date">
                    <Phone className="icon" />
                    <span>Mo: {carData.mobile_no}</span>
                  </div>
                  <div className="pickup-date">
                    <Calendar className="icon" />
                    <span>Pickup: {carData.from_date}</span>
                  </div>
                  <div className="pickup-date">
                    <Calendar className="icon" />
                    <span>Rental Rate: {carData.payment_package}</span>
                  </div>

                </div>
                <div className="car-contact-details">

                  <div className="contact-item">
                    <Mail className="icon" />
                    <span>{carData.email}</span>
                  </div>
                  <div className="contact-item">
                    <FileText className="icon" />
                    <span>License: {carData.driving_licence}</span>
                  </div>
                  <div className="contact-item">
                    <FileText className="icon" />
                    <span>VIN Number: {carData.vin}</span>
                  </div>
                  <div className="contact-item">
                    <FileText className="icon" />
                    <span>Insurance Policy Number: {carData.insurance_policy}</span>
                  </div>
                </div>
              </div>
            </div>
            {/* <div className="car-profile-actions">
              <button
                className="action-button primary"
                onClick={toggleMaintenanceModal}
              >
                <Plus className="icon" />
                Add Maintenance
              </button>
            </div> */}
          </div>

          <div className="dashboard-tabs">
            <div className="tab-buttons">
              <button
                className={`tab-button ${activeTab === "payments" ? "active" : ""
                  }`}
                onClick={() => setActiveTab("payments")}
              >
                <CreditCard className="tab-icon" />
                Payment History
              </button>
              <button
                className={`tab-button ${activeTab === "maintenance" ? "active" : ""
                  }`}
                onClick={() => setActiveTab("maintenance")}
              >
                <Tool className="tab-icon" />
                Maintenance Records
              </button>
            </div>

            <div className="tab-content">
              {activeTab === "payments" && (
                <PaymentHistrory paymentHistryData={paymentHistryData} />
              )}

              {activeTab === "maintenance" && (
                <MaintenanceRecord
                  maintainRecord={maintainRecord}
                  carData={carData}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}
