import React, { useState } from "react";
import {
  Eye,
  Calendar,
  User,
  Clock,
  Upload,
  CheckCircle,
  ChevronRight,
  DollarSign,
  Sliders,
  ArrowUp,
  ArrowDown,
  Settings,
  XCircle,
  AlertCircle,
  Car,
  Droplets,
  Gauge,
  Lightbulb,
  Wrench,
  FileText,
  X,
  CheckCircle2,
  AlertTriangle,
  PenToolIcon as Tool,
} from "lucide-react";
import { formatDateString } from "../../CommonJquery/CommonJquery";
const MaintenanceRecord = ({ maintainRecord, carData }) => {
  const [selectedStatus, setSelectedStatus] = useState({});
  const [data, setData] = useState([]);

  const [showMaintenanceModal, setShowMaintenanceModal] = useState(false);
  const [currentStep, setCurrentStep] = useState(0);

  console.log("mainRecod_carData", carData);
  const handleStatusChange = (id, value) => {
    setSelectedStatus((prev) => ({
      ...prev,
      [id]: value,
    }));
  };
  console.log("mainchildData", maintainRecord);

  const handleNext = () => {
    if (currentStep < 3) {
      setCurrentStep(currentStep + 1);
    }
  };

  const handlePrevious = () => {
    if (currentStep > 0) {
      setCurrentStep(currentStep - 1);
    }
  };
  const toggleMaintenanceModal = () => {
    setShowMaintenanceModal(!showMaintenanceModal);
    if (!showMaintenanceModal) {
      setCurrentStep(0);
    }
  };

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = (maintenance) => {
    setData(maintenance);
    setShow(!show);
  };

  const formatDate = (dateString) => {
    if (!dateString) return "—";
    const date = new Date(dateString);
    return date.toLocaleDateString("en-US", {
      year: "numeric",
      month: "short",
      day: "numeric",
    });
  };

  // Status badge component
  const StatusBadge = ({ status }) => {
    if (status.toLowerCase().includes("good")) {
      return (
        <span className="badge bg-success-light text-success d-flex align-items-center gap-1">
          <CheckCircle2 className="badge-icon" />
          {status}
        </span>
      );
    } else if (status.toLowerCase().includes("need")) {
      return (
        <span className="badge bg-danger-light text-danger d-flex align-items-center gap-1">
          <XCircle className="badge-icon" />
          {status}
        </span>
      );
    } else if (status.toLowerCase().includes("low")) {
      return (
        <span className="badge bg-warning-light text-warning d-flex align-items-center gap-1">
          <AlertTriangle className="badge-icon" />
          {status}
        </span>
      );
    } else {
      return (
        <span className="badge bg-secondary" style={{ padding: "7px" }}>
          {status}
        </span>
      );
    }
  };

  // Section title component
  const SectionTitle = ({ icon, title }) => (
    <div className="d-flex align-items-center gap-2 fw-medium fs-5  text-primary">
      {icon}
      <h3 className="m-0">{title}</h3>
    </div>
  );

  // Info row component
  const InfoRow = ({ label, value }) => (
    <div className="d-flex justify-content-between py-2 border-bottom border-light last-item-no-border">
      <span className="text-muted">{label}</span>
      <span className="fw-medium">{value || "—"}</span>
    </div>
  );

  // Status row component
  const StatusRow = ({ label, value }) => (
    <div className="d-flex justify-content-between py-2 border-bottom border-light last-item-no-border">
      <span className="text-muted">{label}</span>
      {value ? <StatusBadge status={value} /> : <span>—</span>}
    </div>
  );

  return (
    <div>
      {" "}
      <div className="maintenance-tab">
        <div className="tab-header">
          <h3>Maintenance Records</h3>
          <div className="tab-actions">
            <div className="search-container">
              <input
                type="text"
                placeholder="Search maintenance..."
                className="search-input"
              />
            </div>
            {/* <button className="filter-button">
              <Filter className="icon" />
              Filter
            </button> */}
          </div>
        </div>

        <div className="upcoming-maintenance">
          <div className="upcoming-icon">
            <Clock />
          </div>
          <div className="upcoming-content">
            <h4>Upcoming Maintenance</h4>
            <p>
              Regular service scheduled for{" "}
              <span className="highlight-date">
                {carData.next_maintenance_date}
              </span>
            </p>
          </div>
          <button className="upcoming-action" onClick={toggleMaintenanceModal}>
            Reschedule Maintenance
            <ChevronRight className="icon" />
          </button>
        </div>

        <div className="table-container">
          <table className="data-table maintenance-table">
            <thead>
              <tr>
                <th>
                  <div className="th-content">
                    <span>ID</span>
                    <div className="sort-icons">
                      <ArrowUp className="sort-icon" />
                      <ArrowDown className="sort-icon" />
                    </div>
                  </div>
                </th>
                <th>
                  <div className="th-content">
                    <span>Date</span>
                    <div className="sort-icons">
                      <ArrowUp className="sort-icon" />
                      <ArrowDown className="sort-icon" />
                    </div>
                  </div>
                </th>
                <th>
                  <div className="th-content">
                    <span>Reason</span>
                    <div className="sort-icons">
                      <ArrowUp className="sort-icon" />
                      <ArrowDown className="sort-icon" />
                    </div>
                  </div>
                </th>
                <th>
                  <div className="th-content">
                    <span>Mechanic </span>
                    <div className="sort-icons">
                      <ArrowUp className="sort-icon" />
                      <ArrowDown className="sort-icon" />
                    </div>
                  </div>
                </th>

                <th>
                  <div className="th-content">
                    <span>Status</span>
                  </div>
                </th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {maintainRecord.map((maintenance, index) => (
                <tr key={index} className="data-row">
                  <td>
                    <div className="id-cell">#{index + 1}</div>
                  </td>
                  <td>
                    <div className="date-cell">
                      <Calendar className="cell-icon" />
                      {formatDateString(maintenance.entry_date)}
                    </div>
                  </td>
                  <td>
                    <div className="type-cell">
                      {maintenance.maintenance_reasion}
                    </div>
                  </td>
                  <td>
                    <div className="mechanic-cell">
                      <div className="mechanic-avatar">
                        {maintenance.assign_staff_name.charAt(0)}
                      </div>
                      {maintenance.assign_staff_name}
                    </div>
                  </td>

                  <td>
                    <div
                      className={`status-dropdown ${maintenance.step_for_maintenance}`}
                    >
                      {maintenance.step_for_maintenance == 0 && (
                        <div className="blue-icon">
                          <Clock className="status-icon" />
                          Pending for Payment
                        </div>
                      )}
                      {maintenance.step_for_maintenance == 1 && (
                        <div className="yellow-icon">
                          <Settings className="status-icon" />
                          In Process
                        </div>
                      )}
                      {maintenance.step_for_maintenance == 2 && (
                        <div className="green-icon">
                          <CheckCircle className="status-icon" />
                          Completed
                        </div>
                      )}

                      {maintenance.step_for_maintenance == 10 && (
                        <div className="red-icon">
                          <XCircle className="status-icon" />
                          Failed
                        </div>
                      )}
                    </div>
                  </td>

                  <td>
                    <div className="actions-cell">
                      <button
                        className="action-btn view-btn"
                        onClick={() => handleShow(maintenance)}
                      >
                        <Eye className="action-icon" />
                      </button>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        <div className="table-footer">
          <div className="pagination">
            <button className="pagination-btn prev">Previous</button>
            <div className="pagination-pages">
              <button className="pagination-page active">1</button>
              <button className="pagination-page">2</button>
              <button className="pagination-page">3</button>
            </div>
            <button className="pagination-btn next">Next</button>
          </div>
          <div className="items-per-page">
            <span>Show:</span>
            <select className="items-select">
              <option>10</option>
              <option>20</option>
              <option>50</option>
            </select>
            <span>items</span>
          </div>
        </div>
      </div>
      {showMaintenanceModal && (
        <div className="modal-overlay">
          <div className="custom-modal">
            <div className="modal-header">
              <h3>Add New Maintenance Record</h3>
              <button className="close-button" onClick={toggleMaintenanceModal}>
                ×
              </button>
            </div>

            <div className="modal-progress">
              <div className="progress-steps">
                <div
                  className={`progress-step ${currentStep >= 0 ? "active" : ""
                    }`}
                >
                  <div className="step-number">1</div>
                  <div className="step-label">Details</div>
                </div>
                <div className="progress-line"></div>
                <div
                  className={`progress-step ${currentStep >= 1 ? "active" : ""
                    }`}
                >
                  <div className="step-number">2</div>
                  <div className="step-label">Service</div>
                </div>
                <div className="progress-line"></div>
                <div
                  className={`progress-step ${currentStep >= 2 ? "active" : ""
                    }`}
                >
                  <div className="step-number">3</div>
                  <div className="step-label">Images</div>
                </div>
                <div className="progress-line"></div>
                <div
                  className={`progress-step ${currentStep >= 3 ? "active" : ""
                    }`}
                >
                  <div className="step-number">4</div>
                  <div className="step-label">Confirm</div>
                </div>
              </div>
            </div>

            <div className="modal-body">
              {currentStep === 0 && (
                <div className="step-content">
                  <h4 className="step-title">Maintenance Details</h4>
                  <div className="form-row">
                    <div className="form-group">
                      <label htmlFor="maintenance-date">Maintenance Date</label>
                      <div className="input-with-icon">
                        <Calendar className="input-icon" />
                        <input
                          type="date"
                          id="maintenance-date"
                          className="form-control"
                        />
                      </div>
                    </div>
                    <div className="form-group">
                      <label htmlFor="mechanic">Mechanic</label>
                      <div className="input-with-icon">
                        <User className="input-icon" />
                        <input
                          type="text"
                          id="mechanic"
                          className="form-control"
                          placeholder="Enter mechanic name"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="form-group">
                    <label>Maintenance Type</label>
                    <div className="maintenance-types">
                      <label className="maintenance-type-option">
                        <input
                          type="radio"
                          name="maintenanceType"
                          defaultChecked
                        />
                        <div className="option-content">
                          <div className="option-icon">
                            <Settings />
                          </div>
                          <div className="option-label">Regular Service</div>
                        </div>
                      </label>
                      <label className="maintenance-type-option">
                        <input type="radio" name="maintenanceType" />
                        <div className="option-content">
                          <div className="option-icon">
                            <Sliders />
                          </div>
                          <div className="option-label">Oil Change</div>
                        </div>
                      </label>
                      <label className="maintenance-type-option">
                        <input type="radio" name="maintenanceType" />
                        <div className="option-content">
                          <div className="option-icon">
                            <Tool />
                          </div>
                          <div className="option-label">Brake Service</div>
                        </div>
                      </label>
                      <label className="maintenance-type-option">
                        <input type="radio" name="maintenanceType" />
                        <div className="option-content">
                          <div className="option-icon">
                            {/* <PenTool /> */}
                            <p>Pen</p>
                          </div>
                          <div className="option-label">Other</div>
                        </div>
                      </label>
                    </div>
                  </div>
                </div>
              )}

              {currentStep === 1 && (
                <div className="step-content">
                  <h4 className="step-title">Service Details</h4>
                  <div className="form-group">
                    <label htmlFor="description">Description</label>
                    <textarea
                      id="description"
                      className="form-control"
                      rows="4"
                      placeholder="Describe the maintenance work in detail"
                    ></textarea>
                  </div>
                  <div className="form-row">
                    <div className="form-group">
                      <label htmlFor="cost">Cost (₹)</label>
                      <div className="input-with-icon">
                        <DollarSign className="input-icon" />
                        <input
                          type="number"
                          id="cost"
                          className="form-control"
                          placeholder="Enter cost"
                        />
                      </div>
                    </div>
                    <div className="form-group">
                      <label htmlFor="parts">Parts Replaced</label>
                      <div className="input-with-icon">
                        <Tool className="input-icon" />
                        <input
                          type="text"
                          id="parts"
                          className="form-control"
                          placeholder="Enter parts replaced"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              )}

              {currentStep === 2 && (
                <div className="step-content">
                  <h4 className="step-title">Upload Images</h4>
                  <div className="file-upload-area">
                    <div className="upload-icon-container">
                      <Upload className="upload-icon" />
                    </div>
                    <h5>Drag and drop files here</h5>
                    <p>or</p>
                    <button className="browse-button">Browse Files</button>
                    <p className="file-info">
                      Maximum file size: 10MB (JPG, PNG, PDF)
                    </p>
                  </div>
                  <div className="uploaded-files">
                    <div className="uploaded-file">
                      <div className="file-preview">
                        <img
                          src="/placeholder.svg?height=40&width=40"
                          alt="Preview"
                        />
                      </div>
                      <div className="file-details">
                        <div className="file-name">image-01.jpg</div>
                        <div className="file-size">2.4 MB</div>
                      </div>
                      <button className="remove-file">×</button>
                    </div>
                  </div>
                </div>
              )}

              {currentStep === 3 && (
                <div className="step-content">
                  <h4 className="step-title">Confirmation</h4>
                  <div className="confirmation-card">
                    <div className="confirmation-header">
                      <h5>Maintenance Summary</h5>
                    </div>
                    <div className="confirmation-body">
                      <div className="confirmation-row">
                        <div className="confirmation-label">
                          Maintenance Type
                        </div>
                        <div className="confirmation-value">
                          Regular Service
                        </div>
                      </div>
                      <div className="confirmation-row">
                        <div className="confirmation-label">Date</div>
                        <div className="confirmation-value">12-03-2023</div>
                      </div>
                      <div className="confirmation-row">
                        <div className="confirmation-label">Mechanic</div>
                        <div className="confirmation-value">Roberto</div>
                      </div>
                      <div className="confirmation-row">
                        <div className="confirmation-label">Description</div>
                        <div className="confirmation-value">
                          Regular maintenance including oil change, filter
                          replacement, and brake inspection.
                        </div>
                      </div>
                      <div className="confirmation-row">
                        <div className="confirmation-label">Cost</div>
                        <div className="confirmation-value highlight">
                          ₹4,500
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="confirmation-alert">
                    <CheckCircle className="alert-icon" />
                    <div className="alert-message">
                      All details look good. Click Save to add this maintenance
                      record.
                    </div>
                  </div>
                </div>
              )}
            </div>

            <div className="modal-footer">
              <button
                className="btn-secondary"
                onClick={handlePrevious}
                disabled={currentStep === 0}
              >
                Previous
              </button>
              {currentStep < 3 ? (
                <button className="btn-primary" onClick={handleNext}>
                  Next
                  <ChevronRight className="btn-icon" />
                </button>
              ) : (
                <button
                  className="btn-success"
                  onClick={toggleMaintenanceModal}
                >
                  <CheckCircle className="btn-icon" />
                  Save Record
                </button>
              )}
            </div>
          </div>
        </div>
      )}
      {show && <div className="modal-backdrop show"></div>}
      <div className="mainModal_style">
        <div
          className={`modal fade ${show ? "show d-block" : ""}`}
          tabIndex="-1"
        >
          <div className="modal-dialog modal-xl modal-dialog-scrollable">
            <div className="modal-content">
              <div className="modal-header">
                <div>
                  <h5 className="modal-title fs-4 d-flex align-items-center gap-2">
                    <Car className="title-icon" />
                    Vehicle Maintenance Details
                  </h5>
                  <p className="text-muted mt-1">
                    License: {data.licence_plate}
                  </p>
                </div>
                <button
                  type="button"
                  className="btn-close"
                  onClick={handleClose}
                ></button>
              </div>

              <div className="modal-body">
                <div className="row g-4">
                  {/* General Information */}
                  <div className="col-md-6">
                    <SectionTitle
                      icon={<FileText className="section-icon" />}
                      title="General Information"
                    />
                    <div className="info-panel bg-light p-3 rounded">
                      <InfoRow
                        label="Maintenance Reason"
                        value={data.maintenance_reasion}
                      />
                      <InfoRow label="Date" value={formatDate(data.date)} />
                      <InfoRow
                        label="Entry Date"
                        value={formatDate(data.entry_date)}
                      />
                      <InfoRow label="Days Age" value={data.days_age} />
                      <InfoRow label="Booking ID" value={data.booking_id} />
                      <InfoRow
                        label="License Plate"
                        value={data.licence_plate}
                      />
                      <InfoRow label="City Sticker" value={data.city_stiker} />
                      <InfoRow
                        label="iPass (Last 5 Digits)"
                        value={data.last_5_digit_ipass}
                      />
                      <InfoRow
                        label="Assigned Staff"
                        value={data.assign_staff_name}
                      />
                      <InfoRow
                        label="Office Verification"
                        value={data.office_verify}
                      />
                    </div>
                  </div>

                  {/* Dashboard & Issues */}
                  <div className="col-md-6">
                    <SectionTitle
                      icon={<AlertCircle className="section-icon" />}
                      title="Dashboard & Issues"
                    />
                    <div className="info-panel bg-light p-3 rounded">
                      <InfoRow
                        label="Dashboard Light"
                        value={data.dashboard_light}
                      />
                      <InfoRow
                        label="Code on Dashboard"
                        value={data.code_on_dashboard}
                      />
                      <InfoRow
                        label="Remark Dashboard Code"
                        value={data.remark_dashboard_code}
                      />
                      <InfoRow
                        label="Name and Issue"
                        value={data.name_and_issue}
                      />
                      <InfoRow
                        label="Details for Repair"
                        value={data.details_for_repair}
                      />
                      <InfoRow
                        label="Damage Status"
                        value={data.damage_status}
                      />
                      <InfoRow
                        label="Notes for Future"
                        value={data.notes_for_future}
                      />
                      <InfoRow label="Remark" value={data.remark} />
                    </div>
                  </div>

                  {/* Lights */}
                  <div className="col-md-6">
                    <SectionTitle
                      icon={<Lightbulb className="section-icon" />}
                      title="Lights"
                    />
                    <div className="info-panel bg-light p-3 rounded">
                      <StatusRow
                        label="Front Left (Low)"
                        value={data.front_light_left_low}
                      />
                      <StatusRow
                        label="Front Left (High)"
                        value={data.front_light_left_high}
                      />
                      <StatusRow
                        label="Front Right (Low)"
                        value={data.front_light_right_low}
                      />
                      <StatusRow
                        label="Front Right (High)"
                        value={data.front_light_right_high}
                      />
                      <StatusRow
                        label="Blinker Left"
                        value={data.blinker_left}
                      />
                      <StatusRow
                        label="Blinker Right"
                        value={data.blinker_right}
                      />
                      <StatusRow label="Tail Left" value={data.tail_left} />
                      <StatusRow label="Tail Right" value={data.tail_right} />
                      <StatusRow
                        label="Tail Blinker Left"
                        value={data.tail_binker_left}
                      />
                      <StatusRow
                        label="Tail Blinker Right"
                        value={data.tail_binker_right}
                      />
                      <StatusRow
                        label="Reverse Light"
                        value={data.tail_reverse_light}
                      />
                    </div>
                  </div>

                  {/* Tires */}
                  <div className="col-md-6">
                    <SectionTitle
                      icon={<Gauge className="section-icon" />}
                      title="Tires"
                    />
                    <div className="info-panel bg-light p-3 rounded">
                      <InfoRow label="Tire Status" value={data.tire} />
                      <InfoRow label="Replace Tire" value={data.replace_tire} />

                      <hr className="my-2" />
                      <div className="fw-medium mb-1">Air Pressure (PSI)</div>
                      <InfoRow
                        label="Front Left"
                        value={data.tire_air_front_left}
                      />
                      <InfoRow
                        label="Front Right"
                        value={data.tire_air_front_right}
                      />
                      <InfoRow
                        label="Rear Left"
                        value={data.tire_air_rear_left}
                      />
                      <InfoRow
                        label="Rear Right"
                        value={data.tire_air_rear_right}
                      />

                      <hr className="my-2" />
                      <div className="fw-medium mb-1">Tread Depth (%)</div>
                      <InfoRow
                        label="Front Left"
                        value={data.tire_trade_front_left}
                      />
                      <InfoRow
                        label="Front Right"
                        value={data.tire_trade_front_right}
                      />
                      <InfoRow
                        label="Rear Left"
                        value={data.tire_trade_rear_left}
                      />
                      <InfoRow
                        label="Rear Right"
                        value={data.tire_trade_rear_right}
                      />
                    </div>
                  </div>

                  {/* Fluids */}
                  <div className="col-md-6">
                    <SectionTitle
                      icon={<Droplets className="section-icon" />}
                      title="Fluids"
                    />
                    <div className="info-panel bg-light p-3 rounded">
                      <StatusRow
                        label="Oil Level"
                        value={data.oil_level || data.oil_quantity}
                      />
                      <InfoRow
                        label="Oil Added Quantity"
                        value={data.oil_added_quantity}
                      />
                      <InfoRow
                        label="Oil Added by Driver"
                        value={data.oli_added_by_driver}
                      />
                      <InfoRow
                        label="Driver Oil Bottles"
                        value={data.driver_oil_bottles}
                      />
                      <InfoRow
                        label="Oil Changed By"
                        value={data.oil_changed_by}
                      />
                      <StatusRow label="Brake Fluid" value={data.break_fluid} />
                      <StatusRow
                        label="Coolant Fluid"
                        value={data.coolant_fluid}
                      />
                      <StatusRow
                        label="Windshield Washer"
                        value={data.windshield_washer}
                      />
                    </div>
                  </div>

                  {/* Other Components */}
                  <div className="col-md-6">
                    <SectionTitle
                      icon={<Wrench className="section-icon" />}
                      title="Other Components"
                    />
                    <div className="info-panel bg-light p-3 rounded">
                      <StatusRow label="AC Check" value={data.ac_check} />
                      <StatusRow
                        label="Wiper Working"
                        value={data.wiper_working}
                      />
                      <StatusRow
                        label="Wiper Blade Front Driver"
                        value={data.wiper_blade_front_driver}
                      />
                      <StatusRow
                        label="Wiper Blade Front Passenger"
                        value={data.wiper_blade_front_passenger}
                      />
                      <StatusRow
                        label="Wiper Blade Rear"
                        value={data.wiper_blade_rear}
                      />
                      <InfoRow
                        label="Fender Liners"
                        value={data.fender_liners}
                      />
                      <InfoRow label="Seat Cover" value={data.seat_cover} />
                      <InfoRow label="Snow Brush" value={data.snow_brush} />
                      <InfoRow
                        label="Reset Maintenance"
                        value={data.reset_maintainance}
                      />
                      <InfoRow
                        label="Odometer"
                        value={data.odo_metter || "—"}
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-outline-secondary"
                  onClick={handleClose}
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MaintenanceRecord;
