import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Autoplay, Navigation, Pagination } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper-bundle.css";
import { handleError } from "../../CommonJquery/CommonJquery";
import { storeData } from "../../LocalConnection/LocalConnection";
import {
  server_post_data,
  view_all_carmodel_type,
} from "../../ServiceConnection/serviceconnection";
import HeroSearch from "../Elements/HeroSearch";
export default function Hero() {
  const [showLoaderAdmin, setShowLoaderAdmin] = useState(false);
  const [selectedCataid, setselectedCataid] = useState(0);
  const swiperGroup1 = {
    modules: [Autoplay, Pagination, Navigation],
    slidesPerView: 1, // Number of slides visible at once (1 is correct for most banners)
    spaceBetween: 50, // Space between slides in px
    slidesPerGroup: 1, // Number of slides to transition at once (usually 1 for a banner)
    loop: true, // Enable looping of slides
    navigation: {
      nextEl: ".swiper-button-next",
      prevEl: ".swiper-button-prev",
    },
    pagination: {
      el: ".swiper-pagination-group-1",
      clickable: true, // Makes pagination clickable
    },
    autoplay: {
      delay: 10000, // Delay in ms between slide transitions (100000 is extremely long, 10000 is more typical)
      disableOnInteraction: false, // Ensures autoplay continues even after user interaction
    },
  };
  const [carDetails, setCarDetails] = useState([]);
  const [selectedCata, setselectedCata] = useState("all");

  const handleCata = (cata, id) => {
    setselectedCata(cata);
    storeData("SelectCarTypeId", id);
    storeData("SelectCarTypeName", cata);
    setselectedCataid(id);
  };

  useEffect(() => {
    master_data_get_details();
  }, []);
  const master_data_get_details = async () => {
    setShowLoaderAdmin(true);
    const fd = new FormData();
    await server_post_data(view_all_carmodel_type, fd)
      .then((Response) => {
        if (Response.error) {
          handleError(Response.message);
        } else {
          setCarDetails(Response.message.car_data);
        }
        setShowLoaderAdmin(false);
      })
      .catch((error) => {
        console.log(error);
        setShowLoaderAdmin(false);
      });
  };

  return (
    <>
      <section className="section-box box-banner-home7 background-body">
        <div className="container-banner-home7 position-relative">
          <div className="box-swiper">
            <Swiper
              {...swiperGroup1}
              className="swiper-container swiper-group-1"
            >
              <div className="swiper-wrapper">
                <SwiperSlide>
                  <div
                    className="item-banner-slide banner-1  bannerImg"
                    style={{ display: "flex", alignItems: "center" }}
                  >
                    {/* <div className="container hideInTab"> */}
                    <div className="container">
                      {/* <span className="btn background-brand-2 px-3 py-3 rounded-12 text-sm-bold text-dark"> */}
                      <span className="text-primary text-xl-bold">
                        Well Maintained Hybrid Cars
                      </span>
                      <h1 className="mt-20 mb-20 color-white">
                        Drive Smarter. Earn Faster.
                      </h1>
                      <h6 className="color-white heading-6-medium pb-lg-0 pb-4">
                        Unlimited Miles. No App Restrictions. Full Support.–
                        Starting at $350/Week
                      </h6>
                      <div className="d-flex gap-3 mt-20">
                        <Link class="btn btn-book bg-white newWIdth" to="/contact">
                          Apply Now
                          <svg
                            width="16"
                            height="16"
                            viewBox="0 0 16 16"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M8 15L15 8L8 1M15 8L1 8"
                              stroke="currentColor"
                              stroke-width="1.5"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            ></path>
                          </svg>
                        </Link>
                        <Link
                          class="btn btn-book background-brand-2 newWIdth"
                          to="/Listing"
                        >
                          Book A Car
                          <svg
                            width="16"
                            height="16"
                            viewBox="0 0 16 16"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M8 15L15 8L8 1M15 8L1 8"
                              stroke="currentColor"
                              stroke-width="1.5"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            ></path>
                          </svg>
                        </Link>
                      </div>
                      {/* <span className="text-lg-medium color-white d-lg-none">
                        Get 5% discount when{" "}
                        <Link className="text-primary" href="#install-app">
                          Booking via APP
                        </Link>
                      </span> */}
                    </div>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div
                    className="item-banner-slide banner-2 bannerImg"
                    style={{ display: "flex", alignItems: "center" }}
                  >
                    {/* <div className="container hideInTab"> */}
                    <div className="container">
                      {/* <span className="btn background-brand-2 px-3 py-3 rounded-12 text-sm-bold text-dark"> */}
                      <span className="text-primary text-xl-bold">
                        Best car rental company
                      </span>
                      <h1 className="mt-20 mb-20 color-white">
                        Start Driving Keep Earning.
                      </h1>
                      <h6 className="color-white heading-6-medium">
                        Freedom to Drive Any App — No Mileage Limits!
                        {/* journey, <br />
                        with expert assistance at every turn */}
                      </h6>
                      <div className="d-flex gap-3 mt-20">
                        <Link class="btn btn-book bg-white newWIdth" to="/contact">
                          Apply Now
                          <svg
                            width="16"
                            height="16"
                            viewBox="0 0 16 16"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M8 15L15 8L8 1M15 8L1 8"
                              stroke="currentColor"
                              stroke-width="1.5"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            ></path>
                          </svg>
                        </Link>
                        <Link
                          class="btn btn-book background-brand-2 newWIdth"
                          to="/Listing"
                        >
                          Book A Car
                          <svg
                            width="16"
                            height="16"
                            viewBox="0 0 16 16"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M8 15L15 8L8 1M15 8L1 8"
                              stroke="currentColor"
                              stroke-width="1.5"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            ></path>
                          </svg>
                        </Link>
                      </div>
                    </div>
                  </div>
                </SwiperSlide>
              </div>
            </Swiper>
          </div>
        </div>
        <div >
          <div className="container-lg">
            {/* <div
              className="d-flex  justify-content-center align-items-center"
              style={{ maxWidth: "100%", width: "100%" }}
            >
              <div className="box-search-advance background-card wow fadeInUp mb-5 w-100">
                <div className="box-top-search">
                  <div className="left-top-search">
                    <Link
                      className={`category-link text-sm-bold btn-click ${
                        selectedCata === "all" ? "active" : ""
                      }`}
                      onClick={() => handleCata("all", 0)}
                    >
                      All cars
                    </Link>
                    {carDetails &&
                      carDetails.length > 0 &&
                      carDetails.map((car, index) => (
                        <Link
                          key={index}
                          className={`category-link text-sm-bold btn-click ${
                            selectedCata == car.name ? "active" : ""
                          }
                        }`}
                          onClick={() => handleCata(car.name, car.primary_id)}
                        >
                          {car.name}
                        </Link>
                      ))}
                  </div>
                  <div className="right-top-search d-none d-md-flex">
                    <Link
                      className="text-sm-medium need-some-help"
                      to="/contact"
                    >
                      Need help?
                    </Link>
                  </div>
                </div>
                <HeroSearch selectedCataid={selectedCataid} />
              </div>
            </div> */}
            <div className="align-items-center justify-content-end pt-40 d-none d-lg-flex">
              {/* <span className="text-lg-medium color-white">
                Get 5% discount when{" "}
                <Link className="text-primary" href="#install-app">
                  Booking via APP
                </Link>
              </span> */}
              <div className="box-button-slider box-button-slider-team justify-content-end">
                <div
                  className="swiper-button-prev swiper-button-prev-style-1 swiper-button-prev-2"
                  tabIndex={0}
                  role="button"
                  aria-label="Previous slide"
                  aria-controls="swiper-wrapper-9c1b729b91027a37b"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width={16}
                    height={16}
                    viewBox="0 0 16 16"
                    fill="none"
                  >
                    <path
                      d="M7.99992 3.33325L3.33325 7.99992M3.33325 7.99992L7.99992 12.6666M3.33325 7.99992H12.6666"
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </div>
                <div
                  className="swiper-button-next swiper-button-next-style-1 swiper-button-next-2"
                  tabIndex={0}
                  role="button"
                  aria-label="Next slide"
                  aria-controls="swiper-wrapper-9c1b729b91027a37b"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width={16}
                    height={16}
                    viewBox="0 0 16 16"
                    fill="none"
                  >
                    <path
                      d="M7.99992 12.6666L12.6666 7.99992L7.99992 3.33325M12.6666 7.99992L3.33325 7.99992"
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
