import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";

import { Car, MapPin, Clock, Users, CalendarCheck } from "lucide-react";

export default function Achievement() {
  const achievements = [
    {
      icon: <Car />,
      text: "500+ Cars in Fleet",
      subtext: "Reliable and well-maintained vehicles for leasing.",
    },
    {
      icon: <MapPin />,
      text: "Serving Chicago & Nearby Areas",
      subtext: "Convenient locations for easy access.",
    },
    {
      icon: <Clock />,
      text: "14+ Years in Business",
      subtext: "Trusted by rideshare and delivery drivers.",
    },
    {
      icon: <Users />,
      text: "5,000+ Happy Drivers",
      subtext: "Growing community of satisfied customers.",
    },
    {
      icon: <CalendarCheck />,
      text: "Weekly Leasing Available",
      subtext: "Flexible rental options for Uber, Lyft, and delivery services.",
    },
  ];

  return (
    <div className="achievement-section">
      <div className="container-lg">
        {" "}
        <div className="text-center py-5">
          <div className="row justify-content-center">
            {achievements.map((achieve, index) => (
              <div key={index} className="col-md-4 col-sm-6">
                <div className="achievement-card p-4">
                  <div className="icon-box">{achieve.icon}</div>
                  <h6 className="mt-3">{achieve.text}</h6>
                  <p>{achieve.subtext}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}
