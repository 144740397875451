import React, { useState } from "react";
import { Link } from "react-router-dom";
import {
  combiled_form_data,
  empty_form,
} from "../../CommonJquery/CommonJquery";
import {
  save_contact,
  server_post_data,
} from "../../ServiceConnection/serviceconnection";
import SuccessModal from "../Elements/SuccessModal";

const successMsg =
  "Thank you for submitting your request. Our team will get back to you shortly..";
export default function ContactForm() {
  const [formData, setFormData] = useState({
    fname: "",
    lname: "",
    email: "",
    phone: "",
    message: "",
    driving_licence_image: "",
    agreed: true,
  });
  const [ShowLoaderAdmin, setShowLoaderAdmin] = useState([]);
  const [showSucessModal, setShowSucessModal] = useState(false);
  const handleShowSucessModal = () => setShowSucessModal(true);
  const handleCloseSuccModal = () => setShowSucessModal(false);
  const [errors, setErrors] = useState({});
  const [charCount, setCharCount] = useState(0);
  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    if (name === "message") {
      setCharCount(value.length);
    }
    if (name === "message" && errors.message) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        message: "",
      }));
    }
    setFormData((prev) => ({
      ...prev,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  const validate = () => {
    const newErrors = {};
    if (!formData.fname.trim()) {
      newErrors.firstName = "First name is required.";
    }
    if (!formData.lname.trim()) {
      newErrors.lastName = "Last name is required.";
    }
    if (!formData.email.trim()) {
      newErrors.email = "Email address is required.";
    } else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(formData.email)) {
      newErrors.email = "Enter a valid email address.";
    }
    if (!formData.phone.trim()) {
      newErrors.phone = "Phone number is required.";
    } else if (!/^\d{10}$/.test(formData.phone)) {
      newErrors.phone =
        "Enter a valid 10-digit U.S. phone number without spaces or dashes.";
    }
    if (!formData.message.trim()) {
      newErrors.message = "Message cannot be empty.";
    }
    // if (!formData.dropdown) {
    //   newErrors.purpose = "Please select a purpose.";
    // }
    if (!formData.agreed) {
      newErrors.agreed =
        "You must agree to the Terms of Service and Privacy Policy.";
    }
    return newErrors;
  };

  const handleSubmit = async (form_data) => {
    const newErrors = validate();
    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return;
    } else {
      setShowLoaderAdmin(true);
      let fd_from = combiled_form_data(form_data, null);
      await server_post_data(save_contact, fd_from)
        .then((Response) => {
          setShowLoaderAdmin(false);
          if (Response.error) {
            console.log(Response.error);
          } else {
            empty_form(form_data);
            setFormData({
              fname: "",
              lname: "",
              email: "",
              phone: "",
              message: "",
              driving_licence_image: "", // Reset to blank
              agreed: false,
            });
            setErrors({});
            handleShowSucessModal(); // Show success modal on successful submission
          }
        })
        .catch((error) => {
          console.log(error);
          setShowLoaderAdmin(false);
          handleShowSucessModal();
        });
    }
  };

  return (
    <section className="box-cta-5 background-body">
      <div className="bg-shape"></div>
      <div className="container position-relative z-1 pt-100 pb-100">
        <div className="row">
          <div className="col-lg-6 order-first order-lg-last">
            <form
              className="mb-30 background-card p-md-5 p-4 rounded-3 mt-lg-0 mt-30"
              id="contact_form"
            >
              <h5 className="neutral-1000 mb-2">Get in Touch</h5>
              <p className="text-sm-medium neutral-500 mb-25">
                1400 Renaissance Dr UNIT 201, Park Ridge, IL 60068, United
                States
              </p>
              <div className="form-contact">
                <div className="row">
                  {/* First Name */}
                  <div className="col-lg-6">
                    <div className="form-group">
                      <label className="text-sm-medium neutral-1000">
                        First Name
                      </label>
                      <input
                        className="form-control"
                        type="text"
                        placeholder="First Name"
                        name="fname"
                        value={formData.fname}
                        onChange={(e) => {
                          handleChange(e);
                          if (errors.firstName) {
                            setErrors((prevErrors) => ({
                              ...prevErrors,
                              firstName: "",
                            })); // Clear error when typing
                          }
                        }}
                        onKeyPress={(e) => {
                          if (!/^[a-zA-Z]*$/.test(e.key)) {
                            e.preventDefault(); // Allow only alphabets
                          }
                        }}
                      />
                      {errors.firstName && (
                        <p className="error">{errors.firstName}</p>
                      )}
                    </div>
                  </div>

                  {/* Last Name */}
                  <div className="col-lg-6">
                    <div className="form-group">
                      <label className="text-sm-medium neutral-1000">
                        Last Name
                      </label>
                      <input
                        className="form-control"
                        type="text"
                        placeholder="Last Name"
                        name="lname"
                        value={formData.lname}
                        onChange={(e) => {
                          handleChange(e);
                          if (errors.lastName) {
                            setErrors((prevErrors) => ({
                              ...prevErrors,
                              lastName: "",
                            }));
                          }
                        }}
                        onKeyPress={(e) => {
                          if (!/^[a-zA-Z]*$/.test(e.key)) {
                            e.preventDefault();
                          }
                        }}
                      />
                      {errors.lastName && (
                        <p className="error">{errors.lastName}</p>
                      )}
                    </div>
                  </div>

                  {/* Phone Number */}
                  <div className="col-lg-6">
                    <div className="form-group">
                      <label className="text-sm-medium neutral-1000">
                        Phone Number
                      </label>
                      <input
                        className="form-control"
                        type="text"
                        placeholder="Phone Number"
                        name="phone"
                        defaultValue={formData.phone}
                        maxLength={10}
                        onChange={(e) => {
                          handleChange(e);
                          if (errors.phone) {
                            setErrors((prevErrors) => ({
                              ...prevErrors,
                              phone: "",
                            })); // Clear error when typing
                          }
                        }}
                        onKeyPress={(e) => {
                          if (!/^[0-9]*$/.test(e.key)) {
                            e.preventDefault(); // Allow only numbers
                          }
                        }}
                      />
                      {errors.phone && <p className="error">{errors.phone}</p>}
                    </div>
                  </div>

                  {/* Email */}
                  <div className="col-lg-6">
                    <div className="form-group">
                      <label className="text-sm-medium neutral-1000">
                        Email
                      </label>
                      <input
                        className="form-control"
                        type="text"
                        placeholder="email@domain.com"
                        name="email"
                        value={formData.email}
                        maxLength={50}
                        onChange={(e) => {
                          handleChange(e);
                          if (errors.email) {
                            setErrors((prevErrors) => ({
                              ...prevErrors,
                              email: "",
                            })); // Clear error when typing
                          }
                        }}
                        onKeyPress={(e) => {
                          const allowedChars = /^[a-zA-Z0-9@.]*$/;
                          if (!allowedChars.test(e.key)) {
                            e.preventDefault(); // Prevent other special characters
                          }
                        }}
                        onBlur={(e) => {
                          const emailPattern =
                            /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
                          if (!emailPattern.test(e.target.value)) {
                            setErrors({
                              ...errors,
                              email: "Please enter a valid email address",
                            });
                          }
                        }}
                      />
                      {errors.email && <p className="error">{errors.email}</p>}
                    </div>
                  </div>
                  {/* purpose */}
                  <div className="col-lg-12">
                    <div className="form-group">
                      <label className="text-sm-medium neutral-1000">
                        Upload Drivers License (Optional, Helps Speed Up Processing)
                      </label>
                      <label
                        className="col mb-3"
                        style={{ alignItems: "center" }}
                      >
                        {/* <span>
                          Upload Drivers License{" "}
                          <span style={{ color: "red" }}>*</span>
                        </span> */}
                        <input
                          type="file"
                          name="driving_licence_image"
                          style={{ backgroundColor: "#fff" }}
                          accept="*/*"
                          onChange={(e) => {
                            const file = e.target.files[0];
                            if (file) {
                              handleChange(e);
                              setErrors((prevErrors) => ({
                                ...prevErrors,
                                driving_licence_image: "",
                              }));
                            }
                          }}
                          required
                        />
                      </label>
                    </div>
                  </div>

                  {/* Message */}
                  <div className="col-lg-12">
                    <div className="form-group">
                      <label className="text-sm-medium neutral-1000">
                        Your Message
                      </label>
                      <textarea
                        className="form-control"
                        rows="3"
                        placeholder="Leave us a message..."
                        name="message"
                        maxLength={250}
                        value={formData.message}
                        onChange={handleChange}
                        style={{ resize: "none" }}
                      >
                        {" "}
                        {/* Character count */}
                      </textarea>
                      <div
                        style={{
                          textAlign: "right",
                          color: charCount === 250 ? "red" : "gray",
                          fontSize: "12px",
                          marginTop: "5px",
                        }}
                      >
                        {charCount}/250
                      </div>
                      {errors.message && formData.message.trim() === "" && (
                        <p className="error">{errors.message}</p>
                      )}
                    </div>
                  </div>

                  {/* Agree Terms */}
                  <div className="box-remember-forgot">
                    <div className="form-group">
                      <div className="remember-me">
                        <label className="text-sm-medium neutral-500">
                          <input
                            className="cb-remember"
                            type="checkbox"
                            name="agreed"
                            checked={formData.agreed}
                            onChange={handleChange}
                          />
                          Agree to our{" "}
                          <Link
                            className="text-sm-medium neutral-1000"
                            to="/terms_of_use"
                          >
                            Terms of Service
                          </Link>{" "}
                          and{" "}
                          <Link
                            className="text-sm-medium neutral-1000"
                            to="/privacy_policy"
                          >
                            Privacy Policy
                          </Link>
                        </label>
                        {errors.agreed && (
                          <p className="error">{errors.agreed}</p>
                        )}
                      </div>
                    </div>
                  </div>

                  {/* Submit Button */}
                  <div className="col-lg-12">
                    <button
                      className="btn btn-book"
                      type="button"
                      onClick={() => handleSubmit("contact_form")}
                    >
                      Send message
                      <svg
                        width={17}
                        height={16}
                        viewBox="0 0 17 16"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M8.5 15L15.5 8L8.5 1M15.5 8L1.5 8"
                          stroke="#101010"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
          <div className="col-lg-6 order-last order-lg-first wow fadeInUp pt-lg-60">
            <div className="ps-lg-5">
              <h3 className="text-white mb-3">
                Need Assistance? <br className="d-none d-md-block" />
                We're Here to Help!
              </h3>
              <p className="text-lg-medium text-white">
                Have questions about vehicle availability, booking process, or
                lease terms? <br className="d-none d-md-block" />
                Our team is just a message away — ready to support your
                rideshare or delivery journey.
              </p>
              <h5 className="text-white mt-15">👉 Let’s Talk – We’re Happy to Help!</h5>
            </div>
          </div>
        </div>
      </div>
      <SuccessModal
        show={showSucessModal}
        handleCloseSuccModal={handleCloseSuccModal}
        title="Request Submitted"
        msg={successMsg}
      />
    </section>
  );
}
