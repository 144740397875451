import { useEffect, useState } from "react";
import { FaHeart, FaRegHeart } from "react-icons/fa";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import { Link } from "react-router-dom";
import { capitalizeFirstLetter } from "../../CommonJquery/CommonJquery";
import {
  save_delete_get_favorite,
  server_post_data,
} from "../../ServiceConnection/serviceconnection";
export default function CarCard({
  car,
  imgLink,
  match_and_return_seo_link,
  likedCars,
  setLikedCars,
  index,
  fetchFavorites,
}) {
  const [liked, setLiked] = useState(false);
  const [loading, setLoading] = useState(false);
  const [likeCars, setlikeCars] = useState([]);

  useEffect(() => {
    const likedCarIds =
      likedCars && likedCars.map((car) => car && car.car_id && car.car_id); // Extract primary_ids
    setlikeCars(likedCarIds); // Check if the current car's ID is in the liked list
  }, [likedCars, car]);

  // Handle heart icon click
  const handleHeartClick = async (car_id) => {
    try {
      const flag = 1; // Always send flag=1 for both like and unlike

      const payload = new FormData();
      payload.append("car_id", car_id);
      payload.append("flag", flag); // Send car_id and flag (1)

      const response = await server_post_data(
        save_delete_get_favorite,
        payload
      );
      console.log(response);
      if (response.error) {
        console.error("Failed to update favorite:", response.message);
        return;
      }
      fetchFavorites();

      setLikedCars((prevLikedCars) => {
        if (liked) {
          return prevLikedCars.filter((item) => item !== index); // Remove from favorites
        } else {
          return [...prevLikedCars, index]; // Add to favorites
        }
      });
    } catch (error) {
      console.error("Error updating favorite:", error);
    }
  };

  return (
    <>
      <div className="card-journey-small background-card hover-up">
        <div className="card-image position-relative">
          <div className="heartLikedContainer">
            {likeCars.includes(car.primary_id) ? (
              <FaHeart
                className="heart-icon liked"
                size={15}
                style={{ cursor: "pointer" }}
                onClick={() => handleHeartClick(car.primary_id)}
              />
            ) : (
              <FaRegHeart
                className="heart-icon not-liked"
                size={15}
                style={{ cursor: "pointer" }}
                onClick={() => handleHeartClick(car.primary_id)}
              />
            )}
          </div>
          <Link
            to={match_and_return_seo_link(car.primary_id)}
            className="lazyLoadingClss"
          >
            {/* <img
              className="car_card_img_Show"
              src={imgLink + car.image}
              alt={car.car_name}
            /> */}
            <LazyLoadImage
              className="car_card_img_Show"
              style={{ height: "270px" }}
              src={imgLink + car.image}
              alt={car.car_name}
              effect="blur"
              placeholderSrc={imgLink + "placeholder-image.jpg"}
              height="100%"
            />
          </Link>
        </div>
        <Link to={match_and_return_seo_link(car.primary_id)}>
          {" "}
          <div className="card-info p-4 pt-30">
            <div className="card-rating">
              <div className="card-left" />
              <div className="card-right">
                <span className="rating text-xs-medium rounded-pill">
                  {car.review} ({car.total_review})
                </span>
              </div>
            </div>
            <div className="card-title">
              <Link
                className="text-lg-bold neutral-1000 text-nowrap"
                to={match_and_return_seo_link(car.primary_id)}
              >
                {car.car_name}
              </Link>
            </div>
            <div className="card-program">
              <div className="card-location">
                <p className="text-location text-sm-medium neutral-500">
                  {/* {car.location} */}
                  Los Angeles, United States
                </p>
              </div>
              <div className="card-facitlities">
                {/* <p className="card-miles text-md-medium">
                  {car.car_mileage} Miles
                </p> */}
                <p className="card-gear text-md-medium">
                   Make Year {car.make_year}
                </p>
                <p className="card-fuel text-md-medium">
                  {car.fuel_type === "cng" ? (
                    "CNG"
                  ) : (
                    <>
                      {car &&
                        car.fuel_type &&
                        car.fuel_type.length > 0 &&
                        capitalizeFirstLetter(car.fuel_type)}
                    </>
                  )}
                </p>
                {/* <p className="card-seat text-md-medium">
                  {car.seating_capacity} Seats
                </p> */}
              </div>
              <div className="endtime">
                <div className="card-price">
                  <p className="text-md-medium neutral-500 me-2">From</p>
                  <h6 className="heading-6 neutral-1000">${car.Price}<span style={{fontSize:'15px',color:'#666'}}>/Day</span></h6>
                  <p className="text-md-medium neutral-500" />
                </div>
                <div className="card-button">
                  <Link
                    className="btn btn-gray"
                    to={match_and_return_seo_link(car.primary_id)}
                    style={{backgroundColor:'var(--primary-color)'}}
                  >
                    Book Now
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </Link>
      </div>
    </>
  );
}
