import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { handleError } from "../../CommonJquery/CommonJquery";
import {
  APL_LINK,
  get_all_cars_website,
  get_favorite_car_website,
  server_post_data,
} from "../../ServiceConnection/serviceconnection";
import CarCard from "../Elements/CarCard";

export default function CarsListing({ selectedCataid }) {
  const [showLoaderAdmin, setShowLoaderAdmin] = useState(false);
  const [likedCars, setLikedCars] = useState([]);
  const [cars, setcars] = useState([]);
  const [imgLink, setImgLink] = useState();
  const [SEOloop, setSEOloop] = useState([]);

  const match_and_return_seo_link = (v_id) => {
    let data_seo_link_final = "/cars/car_details/" + v_id;
    let data_seo_link = data_seo_link_final;
    if (SEOloop) {
      const matchedItem = SEOloop.find((data) => {
        return data_seo_link === data.call_function_name;
      });

      if (matchedItem) {
        data_seo_link_final = matchedItem.pretty_function_name;
      }
    }
    return data_seo_link_final;
  };
  // console.log('cat listing page ',selectedCataid)
  useEffect(() => {
    master_data_get();
    fetchFavorites();
  }, []);
  // Add function to fetch favorites
  const fetchFavorites = async () => {
    try {
      const payload = new FormData();
      payload.append("flag", 2); // Flag 2 for fetching favorites

      const response = await server_post_data(
        get_favorite_car_website,
        payload
      );
      if (!response.error) {
        // Extract car IDs from favorite cars and set them in likedCars state

        setLikedCars(response.message.booking_data);
      }
    } catch (error) {
      console.error("Error fetching favorites:", error);
    }
  };
  // console.log(selectedCataid)
  const master_data_get = async () => {
    // console.log('lksjdfkjflksjflkdsjflksdjflkdsjflksjflksjflkdsjf')
    setShowLoaderAdmin(true);
    const fd = new FormData();
    fd.append("flag", 0);
    fd.append("model_id", 0);
    await server_post_data(get_all_cars_website, fd)
      .then((Response) => {
        if (Response.error) {
          handleError(Response.message);
        } else {
          setcars(Response.message.car_data);
          setSEOloop(Response.message.seo_loop);
          setImgLink(APL_LINK + Response.message.Image_link);
        }
        setShowLoaderAdmin(false);
      })
      .catch((error) => {
        console.log(error);
        setShowLoaderAdmin(false);
      });
  };
  useEffect(() => {
    // Fetch both car listings and favorites when component mounts
    Promise.all([master_data_get(), fetchFavorites()]);
  }, []);
  return (
    <>
      <section className="section-box box-flights background-body">
        <div className="container">
          <div className="row align-items-end">
            <div className="col-md-8">
              <h3 className="neutral-1000">Featured Vehicles</h3>
              <p className="text-lg-medium neutral-500">
                Ready-to-Drive Cars for Your Gig Journey
              </p>
            </div>
            <div className="col-md-4 mt-md-0 mt-4">
              <div className="d-flex justify-content-end">
                <Link className="btn btn-primary" to="/listing">
                  View More
                  <svg
                    width={16}
                    height={16}
                    viewBox="0 0 16 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M8 15L15 8L8 1M15 8L1 8"
                      stroke="currentColor"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </Link>
              </div>
            </div>
          </div>
          <div className="row pt-30 wow fadeInUp">
            {cars.slice(0, 3).map((car, index) => (
              <div key={index} className="col-lg-4 col-md-6">
                <CarCard
                  index={car.id}
                  key={car.id}
                  car={car}
                  imgLink={imgLink}
                  match_and_return_seo_link={match_and_return_seo_link}
                  likedCars={likedCars}
                  setLikedCars={setLikedCars}
                  fetchFavorites={fetchFavorites}
                />
              </div>
            ))}
          </div>
        </div>
      </section>
    </>
  );
}
