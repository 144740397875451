import { createContext, useContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

const HistoryContext = createContext();

export const HistoryProvider = ({ children }) => {
  const location = useLocation();
  const [previousPath, setPreviousPath] = useState(null);

  useEffect(() => {
    setPreviousPath(location.pathname);
  }, [location]);

  return (
    <HistoryContext.Provider value={{ previousPath }}>
      {children}
    </HistoryContext.Provider>
  );
};

export const useHistory = () => useContext(HistoryContext);
