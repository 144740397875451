import React, { useEffect, useState } from "react";
import BookingCard from "../Elements/BookingCard";
import {
  APL_LINK,
  get_booking_data_website,
  server_post_data,
} from "../../ServiceConnection/serviceconnection";
import { handleError } from "../../CommonJquery/CommonJquery";
function Upcoming() {
  const [isSharePopupOpen, setSharePopupOpen] = useState(false);
  const [isSharePopupContent, setisSharePopupContent] = useState("");
  const [ImageLink, setImageLink] = useState("");
  const [cancelledData, setcancelledData] = useState([]);
  const [rupeyIcon, setRupeyIcon] = useState("");
  const [showLoaderAdmin, setShowLoaderAdmin] = useState(false);
  useEffect(() => {
    master_data_get();
  }, []);

  // Function to close the share popup

  const master_data_get = async () => {
    setShowLoaderAdmin(true);
    const fd = new FormData();
    fd.append("flag", "1");
    await server_post_data(get_booking_data_website, fd)
      .then((Response) => {
        if (Response.error) {
          handleError(Response.message);
        } else {
          setcancelledData(Response.message.finaldata);
          console.log(Response.message);
          setImageLink(APL_LINK + Response.message.image_link);
        }
        setShowLoaderAdmin(false);
      })
      .catch((error) => {
        console.log(error);
        setShowLoaderAdmin(false);
      });
  };

  return (
    <div>
      {cancelledData && cancelledData.length > 0 ? (
        cancelledData.map((item, index) => (
          <BookingCard
            key={index}
            datass={item}
            image={ImageLink}
          />
        ))
      ) : (
        <div className="nobookingDiv">
          {/* <img
            src={Nobookingdata}
            alt="No booking data available"
            style={{
              display: "block",
              margin: "20px auto",
              maxWidth: "100%",
              height: "auto",
            }}
          /> */}
        </div>
      )}
    </div>
  );
}

export default Upcoming;
