import React, { useEffect, useState } from "react";
// import Dropdown from "react-bootstrap/Dropdown";
// import ThemeSwitch from "../Elements/ThemeSwitch";
import { Link, useLocation } from "react-router-dom";
import MenuIcon from "../../Assests/imgs/template/icons/menu.svg";
import Logo from "../../Assests/imgs/template/rapidB.png";
import { retrieveData } from "../../LocalConnection/LocalConnection";

export default function Header2({
  scroll,
  isMobileMenu,
  handleMobileMenu,
  handleOffcanvas,
  isOffcanvas,
}) {
  const customer_id = retrieveData("customer_id");
  const location = useLocation();
  const [isLogin, setIsLogin] = useState(false);

  // import { useEffect } from "react";

  useEffect(() => {
    const storedCustomerId = localStorage.getItem("customer_id");
    const loginDateTime = localStorage.getItem("login_date_time");
    if (!storedCustomerId || storedCustomerId === "0") {
      setIsLogin(false);
      return;
    }
    if (loginDateTime) {
      const loginTime = new Date(loginDateTime).getTime();
      const currentTime = new Date().getTime();
      const timeDifference = currentTime - loginTime; // Difference in milliseconds
      const hoursPassed = timeDifference / (1000 * 60 * 60); // Convert to hours
      if (hoursPassed >= 20) {
        localStorage.clear();
        setIsLogin(false);
        console.log("Session expired. Logging out.");
      } else {
        setIsLogin(true);
      }
    } else {
      setIsLogin(false);
    }
  }, [customer_id]);


  const menuItems = [
    { path: "/", label: "Home" },
    { path: "/about", label: "About" },
    { path: "/services", label: "Services" },
    { path: "/blogs", label: "Blog" },
    // { path: "/contact", label: "Contact" },
  ];

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, [location]);

  const handleClick = (path) => {
    if (location.pathname === path) {
      window.scrollTo({ top: 0, behavior: "smooth" });
    }
  };
  return (
    <header
      className={`header sticky-bar header-home-2 ${scroll ? "stick" : ""}`}
    >
      <div className="top-bar top-bar-2 top-bar-3 @@bg">
        <div className="container-fluid"></div>
      </div>
      <div className="container-fluid background-body">
        <div className="main-header">
          <div className="header-left">
            <div className="header-logo">
              <Link className="d-flex" to="/" style={{ padding: "0.5rem" }}>
                <img
                  className="carLogoHeig light-mode"
                  alt="Rapid Cars"
                  src={Logo}
                />
                <img
                  style={{ height: "55px" }}
                  className="dark-mode"
                  alt="Rapid Cars"
                  src="/assets/imgs/template/logo-w.svg"
                />
              </Link>
            </div>
            {location.pathname !== "/reset_password" && (
              <>
                <div className="header-nav">
                  <nav className="nav-main-menu">
                    <ul className="main-menu">
                      {menuItems.map((item) => (
                        <li key={item.path}>
                          <Link
                            to={item.path}
                            onClick={() => handleClick(item.path)}
                          >
                            {item.label}
                          </Link>
                        </li>
                      ))}
                    </ul>
                  </nav>
                </div>
                <div className="header-right">
                  <div className="d-none d-xl-inline-block align-middle mr-15">
                    {!isLogin && (
                      <Link
                        className="btn btn-signin neutral-1000"
                        to={"/login"}
                      >
                        <svg
                          className="mb-1"
                          xmlns="http://www.w3.org/2000/svg"
                          width={12}
                          height={12}
                          viewBox="0 0 12 12"
                          fill="none"
                        >
                          <path
                            d="M1 12C1 12 0 12 0 11C0 10 1 7 6 7C11 7 12 10 12 11C12 12 11 12 11 12H1ZM6 6C6.79565 6 7.55871 5.68393 8.12132 5.12132C8.68393 4.55871 9 3.79565 9 3C9 2.20435 8.68393 1.44129 8.12132 0.87868C7.55871 0.316071 6.79565 0 6 0C5.20435 0 4.44129 0.316071 3.87868 0.87868C3.31607 1.44129 3 2.20435 3 3C3 3.79565 3.31607 4.55871 3.87868 5.12132C4.44129 5.68393 5.20435 6 6 6Z"
                            fill="#101010"
                          />
                        </svg>{" "}
                        Sign in
                      </Link>
                    )}
                    <Link
                      className="d-none d-xl-inline-block btn btn-signin background-brand-2 text-dark"
                      to="/contact"
                    >
                      Contact us
                    </Link>
                  </div>
                  <Link
                    className="d-xl-none btn btn-signin activebtncontqact neutral-1000 "
                    style={{ marginRight: "5px", whiteSpace: "nowrap" }}
                    to={"/contact"}
                  >
                    Contact us
                  </Link>
                  <div
                    className="burger-icon-2 burger-icon-white"
                    onClick={handleOffcanvas}
                  >
                    <img src={MenuIcon} alt="Rapid Cars" />
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </header>
  );
}
