import React, { useState } from "react";
import Banner from "../../Assests/imgs/page-header/Group.jpeg";
import {
  combiled_form_data,
  empty_form,
} from "../../CommonJquery/CommonJquery";
import {
  save_contact,
  server_post_data,
} from "../../ServiceConnection/serviceconnection";
import SuccessModal from "../Elements/SuccessModal";
import Layout from "../Layout/Layout";

const successMsg =
  "Thank you for submitting your request. Our team will get back to you shortly.";
const Contact = () => {
  const [formData, setFormData] = useState({
    fname: "",
    lname: "",
    email: "",
    phone: "",
    message: "",
    driving_licence_image: "", // Reset to blank
    agreed: true,
  });
  const [ShowLoaderAdmin, setShowLoaderAdmin] = useState([]);
  const [charCount, setCharCount] = useState(0);
  const [errors, setErrors] = useState({});
  const [showSucessModal, setShowSucessModal] = useState(false);
  const handleShowSucessModal = () => setShowSucessModal(true);
  const handleCloseSuccModal = () => setShowSucessModal(false);
  console.log(formData);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    if (name === "phone") {
      // Remove non-digit characters and limit to 10 digits
      const formattedPhone = value.replace(/\D/g, "").slice(0, 10);
      setFormData((prev) => ({
        ...prev,
        [name]: formattedPhone,
      }));

      // Clear phone number error when user starts typing
      if (errors.phone) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          phone: "",
        }));
      }
    }
    if (name === "message") {
      setCharCount(value.length);
    }
    if (name === "message" && errors.message) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        message: "",
      }));
    }
    if (name === "purpose" && errors.purpose) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        purpose: "",
      }));
    }

    if (name === "agreed") {
      setErrors((prevErrors) => ({
        ...prevErrors,
        agreed: "", // Clear the agreed error when checkbox is interacted with
      }));
    }

    setFormData((prev) => ({
      ...prev,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  const validate = () => {
    const newErrors = {};
    if (!formData.fname.trim()) {
      newErrors.firstName = "First name is required.";
    }
    if (!formData.lname.trim()) {
      newErrors.lastName = "Last name is required.";
    }
    if (!formData.email.trim()) {
      newErrors.email = "Email address is required.";
    } else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(formData.email)) {
      newErrors.email = "Enter a valid email address.";
    }
    // if (!formData.phone.trim()) {
    //   newErrors.phone = "Phone number is required.";
    // } else if (!/^-\d{10}$/.test(formData.phone)) {
    //   newErrors.phone =
    //     "Enter a valid 10-digit U.S. phone number without spaces or dashes.";
    // }
    if (!formData.phone.trim()) {
      newErrors.phone = "Phone number is required.";
    } else {
      // Remove non-digit characters
      const phoneNumber = formData.phone.replace(/\D/g, "");

      // Check phone number length and format
      if (phoneNumber.length !== 10) {
        newErrors.phone = "Phone number must be 10 digits long.";
      } else {
        // Additional validation for common US phone number formats
        const firstThreeDigits = phoneNumber.substring(0, 3);
        const invalidAreaCodes = ["000", "111", "911"];

        if (invalidAreaCodes.includes(firstThreeDigits)) {
          newErrors.phone = "Invalid phone number format.";
        }
      }
    }
    if (!formData.message.trim()) {
      newErrors.message = "Message cannot be empty.";
    }
    // Terms and Conditions Validation
    if (!formData.agreed) {
      newErrors.agreed =
        "Please agree to the Terms of Service and Privacy Policy.";
    }

    return newErrors;
  };

  const handleSubmit = async (form_data) => {
    const newErrors = validate();
    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return;
    }
    if (!formData.agreed) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        agreed: "Please agree to the Terms of Service and Privacy Policy.",
      }));
      return;
    } else {
      setShowLoaderAdmin(true);
      let fd_from = combiled_form_data(form_data, null);
      await server_post_data(save_contact, fd_from)
        .then((Response) => {
          setShowLoaderAdmin(false);
          if (Response.error) {
            console.log(Response.error);
          } else {
            empty_form(form_data);
            setFormData({
              fname: "",
              lname: "",
              email: "",
              phone: "",
              message: "",
              driving_licence_image: "",
              agreed: false,
            });
            setErrors({});
            handleShowSucessModal();
          }
        })
        .catch((error) => {
          console.log(error);
          setShowLoaderAdmin(false);
        });
    }
  };

  return (
    <Layout>
      <div>
        <div
          className="page-header pt-30 background-body"
          style={{ minHeight: "200px", height: "200px" }}
        >
          <div
            className="custom-container position-relative mx-auto"
            style={{ minHeight: "200px", height: "200px" }}
          >
            <div
              className="bg-overlay rounded-12 overflow-hidden"
              style={{ height: "200px" }}
            >
              <img
                className="rounded-12 img-banner"
                src={Banner}
                alt="Carento"
                style={{ height: "200px" }}
              />
            </div>
            <div className="container position-absolute z-1 top-50 start-50 translate-middle">
              <h2 className="text-white">Get in touch</h2>
            </div>
          </div>
        </div>
        <section className="box-section box-contact-form background-body">
          <div className="container">
            <div className="row">
              <div className="col-lg-6 mb-30">
                <div className="form-contact">
                  <form id="contact_form">
                    <div className="row">
                      {/* First Name */}
                      <div className="col-lg-6">
                        <div className="form-group">
                          <label className="text-sm-medium neutral-1000">
                            First Name
                          </label>
                          <input
                            name="fname"
                            className="form-control username"
                            type="text"
                            placeholder="First Name"
                            defaultValue={formData.fname}
                            onChange={(e) => {
                              handleChange(e);
                              if (errors.firstName) {
                                setErrors((prevErrors) => ({
                                  ...prevErrors,
                                  firstName: "",
                                })); // Clear error when typing
                              }
                            }}
                            onKeyPress={(e) => {
                              if (!/^[a-zA-Z]*$/.test(e.key)) {
                                e.preventDefault(); // Allow only alphabets
                              }
                            }}
                          />
                          {errors.firstName && (
                            <small className="text-danger">
                              {errors.firstName}
                            </small>
                          )}
                        </div>
                      </div>

                      {/* Last Name */}
                      <div className="col-lg-6">
                        <div className="form-group">
                          <label className="text-sm-medium neutral-1000">
                            Last Name
                          </label>
                          <input
                            name="lname"
                            className="form-control username"
                            type="text"
                            placeholder="Last Name"
                            defaultValue={formData.lname}
                            onChange={(e) => {
                              handleChange(e);
                              if (errors.lastName) {
                                setErrors((prevErrors) => ({
                                  ...prevErrors,
                                  lastName: "",
                                })); // Clear error when typing
                              }
                            }}
                            onKeyPress={(e) => {
                              if (!/^[a-zA-Z]*$/.test(e.key)) {
                                e.preventDefault(); // Allow only alphabets
                              }
                            }}
                          />
                          {errors.lastName && (
                            <small className="text-danger">
                              {errors.lastName}
                            </small>
                          )}
                        </div>
                      </div>

                      {/* Email */}
                      <div className="col-lg-6">
                        <div className="form-group">
                          <label className="text-sm-medium neutral-1000">
                            Email Address
                          </label>
                          <input
                            name="email"
                            className="form-control email"
                            type="email"
                            placeholder="email@domain.com"
                            defaultValue={formData.email}
                            maxLength={50}
                            onChange={(e) => {
                              handleChange(e);
                              if (errors.email) {
                                setErrors((prevErrors) => ({
                                  ...prevErrors,
                                  email: "",
                                })); // Clear error when typing
                              }
                            }}
                            onKeyPress={(e) => {
                              const allowedChars = /^[a-zA-Z0-9@.]*$/;
                              if (!allowedChars.test(e.key)) {
                                e.preventDefault(); // Prevent other special characters
                              }
                            }}
                            onBlur={(e) => {
                              const emailPattern =
                                /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
                              if (!emailPattern.test(e.target.value)) {
                                setErrors({
                                  ...errors,
                                  email: "Please enter a valid email address",
                                });
                              }
                            }}
                          />
                          {errors.email && (
                            <small className="text-danger">
                              {errors.email}
                            </small>
                          )}
                        </div>
                      </div>

                      {/* Phone Number */}
                      <div className="col-lg-6">
                        <div className="form-group">
                          <label className="text-sm-medium neutral-1000">
                            Phone Number
                          </label>
                          <input
                            name="phone"
                            className="form-control phone"
                            type="text"
                            placeholder="Phone number"
                            defaultValue={formData.phone}
                            maxLength={10}
                            onChange={(e) => {
                              handleChange(e);
                              if (errors.phone) {
                                setErrors((prevErrors) => ({
                                  ...prevErrors,
                                  phone: "",
                                })); // Clear error when typing
                              }
                            }}
                            onKeyPress={(e) => {
                              if (!/^[0-9]*$/.test(e.key)) {
                                e.preventDefault(); // Allow only numbers
                              }
                            }}
                          />
                          {errors.phone && (
                            <small className="text-danger">
                              {errors.phone}
                            </small>
                          )}
                        </div>
                      </div>

                      {/* purpose */}

                      <div className="col-lg-12">
                        <div className="form-group">
                          <label className="text-sm-medium neutral-1000">
                            Upload Drivers License (Optional, Helps Speed Up Processing)
                          </label>
                          <label className="col mb-3">
                            {/* <span>
                          Upload Drivers License{" "}
                          <span style={{ color: "red" }}>*</span>
                        </span> */}
                            <input
                              type="file"
                              name="driving_licence_image"
                              style={{ backgroundColor: "#fff" }}
                              accept="*/*"
                              onChange={(e) => {
                                const file = e.target.files[0];
                                if (file) {
                                  handleChange(e);
                                  setErrors((prevErrors) => ({
                                    ...prevErrors,
                                    driving_licence_image: "",
                                  }));
                                }
                              }}
                              required
                            />
                          </label>
                        </div>
                      </div>

                      <div className="col-lg-12">
                        <div className="form-group">
                          <label className="text-sm-medium neutral-1000">
                            Your Message
                          </label>
                          <textarea
                            name="message"
                            className="form-control"
                            rows={6}
                            maxLength={250}
                            placeholder="Leave us a message..."
                            defaultValue={formData.message}
                            onChange={handleChange}
                            style={{ resize: "none" }}
                          />
                          {/* Character count */}
                          <div
                            style={{
                              textAlign: "right",
                              color: charCount === 250 ? "red" : "gray",
                              fontSize: "12px",
                              marginTop: "5px",
                            }}
                          >
                            {charCount}/250
                          </div>
                          {errors.message && formData.message.trim() === "" && (
                            <small className="text-danger">
                              {errors.message}
                            </small>
                          )}
                        </div>
                      </div>

                      {/* Checkbox for agreement */}
                      <div className="box-remember-forgot">
                        <div className="form-group">
                          <div className="remeber-me">
                            <label className="text-sm-medium neutral-500">
                              <input
                                name="agreed"
                                className="cb-remember"
                                type="checkbox"
                                checked={formData.agreed}
                                onChange={handleChange}
                              />{" "}
                              Agree to our{" "}
                              <a
                                className="text-sm-medium neutral-1000"
                                href="/terms_of_use"
                              >
                                Terms of service
                              </a>{" "}
                              and{" "}
                              <a
                                className="text-sm-medium neutral-1000"
                                href="/privacy_policy"
                              >
                                Privacy Policy
                              </a>
                            </label>
                            {errors.agreed && (
                              <small className="text-danger mt-2">
                                {errors.agreed}
                              </small>
                            )}
                          </div>
                        </div>
                      </div>

                      {/* Submit Button */}
                      <div className="col-lg-12">
                        <button
                          className="btn btn-book"
                          type="button"
                          onClick={() => handleSubmit("contact_form")}
                        >
                          Send message
                          <svg
                            width={17}
                            height={16}
                            viewBox="0 0 17 16"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M8.5 15L15.5 8L8.5 1M15.5 8L1.5 8"
                              stroke="currentColor"
                              strokeWidth="1.5"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                          </svg>
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
              <div className="col-lg-6 mb-30">
                <div className="ps-lg-5">
                  <h4 className="neutral-1000">Our location</h4>
                  <div className="address-container">
                    {/* Registered Office Address Card */}
                    <div className="address-card">
                      <h6 className="address-title">Office Address:</h6>
                      <a
                        href="https://www.google.com/maps/search/?api=1&query=1400+Renaissance+Dr+UNIT+201,+Park+Ridge,+IL+60068"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="address-link"
                      >
                        1400 Renaissance Dr UNIT 201, <br />
                        Park Ridge, IL 60068, United States
                      </a>
                    </div>

                    {/* Vehicle Pickup Address Card */}
                    <div className="address-card">
                      <h6 className="address-title">Vehicle Pickup Address:</h6>
                      <a
                        href="https://www.google.com/maps/search/?api=1&query=1141+Lee+Street,+Des+Plaines,+IL+60016"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="address-link"
                      >
                        1141 Lee Street,Des Plaines, IL 60016
                      </a>
                    </div>
                  </div>
                  <h6 className="address-title">Vehicle Pickup Location</h6>
                  <iframe
                    className="h-520 rounded-3"
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2963.5023085305716!2d-87.89657642390753!3d42.03240797122529!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x880fb7a7635b19db%3A0x13741fc45a6ada65!2s1141%20Lee%20St!5e0!3m2!1sen!2sin!4v1742967377470!5m2!1sen!2sin"
                    width="100%"
                    height={390}
                    style={{ border: 0 }}
                    allowFullScreen
                    loading="lazy"
                    referrerPolicy="no-referrer-when-downgrade"
                  ></iframe>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      <SuccessModal
        show={showSucessModal}
        handleCloseSuccModal={handleCloseSuccModal}
        title="Request Submitted"
        msg={successMsg}
      />
    </Layout>
  );
};

export default Contact;
