import { useEffect, useState } from "react";
import { Autoplay, Navigation, Pagination } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import Star from "../../Assests/imgs/template/icons/star-black.svg";
import Author1 from "../../Assests/imgs/testimonials/testimonials-1/author-1.png";
import Author2 from "../../Assests/imgs/testimonials/testimonials-1/author-2.png";
import Author3 from "../../Assests/imgs/testimonials/testimonials-1/author-3.png";
import { handleError } from "../../CommonJquery/CommonJquery";
import {
  APL_LINK,
  get_faq_testimonial_data,
  server_post_data,
} from "../../ServiceConnection/serviceconnection";

export default function Testimonials() {
  // Swiper configuration directly in the component
  const swiperGroupAnimate = {
    modules: [Autoplay, Pagination, Navigation],
    spaceBetween: 24,
    slidesPerView: "auto",
    slidesPerGroup: 1,
    loop: true,
    speed: 1000,
    navigation: {
      nextEl: ".swiper-button-next-animate",
      prevEl: ".swiper-button-prev-animate",
    },
    autoplay: {
      delay: 4000,
    },
  };

  const [showLoaderAdmin, setShowLoaderAdmin] = useState(false);
  const [testimonials, setTestimonials] = useState([]);
  const [imgLink, setImgLink] = useState();

  useEffect(() => {
    master_data_get();
  }, []);

  const master_data_get = async () => {
    setShowLoaderAdmin(true);
    const fd = new FormData();
    fd.append("flag", 1);
    await server_post_data(get_faq_testimonial_data, fd)
      .then((Response) => {
        if (Response.error) {
          handleError(Response.message);
        } else {
          setTestimonials(Response.message.testimonial_data);
          setImgLink(APL_LINK + Response.message.image_link);
        }
        setShowLoaderAdmin(false);
      })
      .catch((error) => {
        console.log(error);
        setShowLoaderAdmin(false);
      });
  };

  return (
    <>
      <section className="section-box py-20 background-body">
        <div className="container">
          <div className="row align-items-end">
            <div className="col-md-9 col-sm-9 wow fadeInUp">
              <div className="box-author-testimonials">
                <img src={Author1} alt="Rapid Cars" />
                <img src={Author2} alt="Rapid Cars" />
                <img src={Author3} alt="Rapid Cars" />
                Testimonials
              </div>
              <h3 className="mt-8 neutral-1000">Voices from the Road</h3>
              <p className="text-xl-medium neutral-500 wow fadeInUp">
                See why gig workers trust Rapid Car Leasing for their hustle.
              </p>
            </div>
          </div>
        </div>

        <div className="block-testimonials wow fadeIn">
          <div className="container-testimonials">
            <div className="container-slider ps-0">
              <div className="box-swiper mt-30">
                <Swiper
                  {...swiperGroupAnimate}
                  className="swiper-container swiper-group-animate swiper-group-journey"
                >
                  <div className="swiper-wrapper">
                    {testimonials.map((testimonial, index) => (
                      <SwiperSlide key={index}>
                        <div className="card-testimonial background-card padding_clsss">
                          <div className="testi_data_contrr">
                            <div className="card-info">
                              <p className="text-xl-bold card-title neutral-1000">
                                {testimonial.title}
                              </p>
                              <p className="text-md-regular neutral-500 font_sizee">
                                {testimonial.message}
                              </p>
                            </div>
                            <div className="card-top  border-0 mb-0">
                              <div className="card-author">
                                <div className="card-image">
                                  <img
                                    src={imgLink + testimonial.image}
                                    alt={testimonial.name}
                                  />
                                </div>
                                <div className="card-info">
                                  <p className="text-lg-bold neutral-1000">
                                    {testimonial.name}
                                  </p>
                                  <p className="text-md-regular neutral-1000">
                                    {testimonial.city}
                                  </p>
                                </div>
                              </div>
                              <div className="card-rate">
                                {[...Array(parseInt(testimonial.ratting))].map(
                                  (_, idx) => (
                                    <img
                                      key={idx}
                                      className="background-brand-2 p-1"
                                      src={Star}
                                      alt="Rating"
                                    />
                                  )
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </SwiperSlide>
                    ))}
                  </div>
                </Swiper>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
