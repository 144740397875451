import React, { useState } from "react";
import "../../Assests/css/mybooking.css";
import Layout from "../Layout/Layout";
import Upcoming from "../Sections/Upcoming";
import Inhouse from "../Sections/Inhouse";
function MyBookings() {
  const [activeTab, setActiveTab] = useState("Booking");

  const handleTabChange = (tab) => {
    setActiveTab(tab);
  };

  return (
    <Layout>
      <div
        className="container-lg subHeadStick"
        style={{ backgroundColor: "var(--white)" }}
      >
        <h2>Booking details</h2>
        <div className="tabs">
          {["Booking", "History"].map((tab) => (
            <button
              key={tab}
              className={`tab ${activeTab === tab ? "active" : ""}`}
              onClick={() => handleTabChange(tab)}
            >
              {tab}
            </button>
          ))}
        </div>
      </div>
      <div className="container-lg">
        <div className="booking-details ">
          <div className="col-xl-7 col-lg-9 col-md-11">
            <div className="booking-cards">
              {activeTab === "Booking" && <Upcoming />}
              {activeTab === "History" && <Inhouse />}
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}

export default MyBookings;
