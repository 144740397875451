import { Link } from "react-router-dom";
import CarFees from "../../Assests/imgs/page/homepage2/moneyfees.png";
import CarMiles from "../../Assests/imgs/page/homepage2/unlimitedMiles.png";
import CarMechanic from "../../Assests/imgs/page/homepage2/mechanicCar.png";

export default function FeaturesSection() {
  return (
    <section className="section-cta-4 position-relative overflow-hidden">
      <div className="bg-shape"></div>
      <div className="container position-relative z-1">
        {/* <div className="text-center">
          <span className="text-sm-bold bg-white p-3 rounded-12 wow fadeInDown">
            Best Car Rental System
          </span>
          <h4 className="mt-4 wow fadeInUp">
            Receive a Competitive Offer <br />
            Sell Your Car to Us Today. Get a Car on Lease Today <br />
            Easy and Convenient!
          </h4>
        </div> */}
        <div className="row mt-60 ">
          <div className="col-lg-4 mb-4">
            <div className="bg-white rounded-12 p-5 d-flex justify-content-center align-items-center flex-column gap-4 borderTopNew">
              <span className="icon-shape icon_150 rounded-circle wow fadeIn">
                <img className="dark-invert" src={CarFees} alt="Rapid Cars" />
              </span>
              <h6 className="wow fadeInUp">No Hidden Fees</h6>
              <p className="text-md-regular text-center wow fadeInUp">
                Clear pricing with no extra costs. Enjoy an all-inclusive rental
                with insurance, maintenance, and support built in for peace of
                mind.
              </p>
            </div>
          </div>
          <div className="col-lg-4 mb-4">
            <div className="bg-white rounded-12 p-5 d-flex justify-content-center align-items-center flex-column gap-4 borderTopNew">
              <span className="icon-shape icon_150  rounded-circle wow fadeIn">
                <img className="dark-invert" src={CarMiles} alt="Rapid Cars" />
              </span>
              <h6 className="wow fadeInUp">Unlimited Miles</h6>
              <p className="text-md-regular text-center wow fadeInUp">
                Drive as much as you want without limits. Get the freedom to
                earn more with flexible rentals designed for rideshare and
                delivery drivers.
              </p>
            </div>
          </div>
          <div className="col-lg-4">
            <div className="bg-white rounded-12 p-5 d-flex justify-content-center align-items-center flex-column gap-4 borderTopNew">
              <span className="icon-shape icon_150  rounded-circle wow fadeIn">
                <img
                  className="dark-invert"
                  src={CarMechanic}
                  alt="Rapid Cars"
                />
              </span>
              <h6 className="wow fadeInUp">Mechanic Approved</h6>
              <p className="text-md-regular text-center wow fadeInUp">
                Our cars are regularly inspected for top performance. Stay safe
                on the road with well-maintained vehicles ready for your next
                trip.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
