import { Modal, Button } from "react-bootstrap";

export default function MVRPopup({ show, handleClose }) {
  return (
    <Modal show={show} onHide={handleClose} centered>
      <Modal.Body>
        <h5 className="mb-3"> Need MVR for Court Purpose</h5>
        <div
          style={{
            border: "1px solid #ccc",
            padding: "10px",
            borderRadius: "5px",
            marginBottom: "10px",
          }}
        >
          <p className="">
     
           <strong>Note:</strong> This application will not complete without MVR and a
            copy of your driver’s license for court purposes.
          </p>
        </div>
       <div  style={{
            border: "1px solid #ccc",
            padding: "10px",
            borderRadius: "5px",
            marginBottom: "10px",
          }}>
       <p>You can download MVR before you start the application.</p>
        <p>
          <strong>● Link for IL state MVR:</strong>{" "}
          <a
            href="https://apps.ilsos.gov/drivingrecord/"
            target="_blank"
            rel="noopener noreferrer"
            style={{color:'#0c64fc'}}
          >
            https://apps.ilsos.gov/drivingrecord/
          </a>{" "}
          (Available online: Fee is $21)
        </p>
        <p>
          ● Attach MVR with the application. (Call 708-312-3331 if you have any
          questions.)
        </p>
        <p>● If you have an out-of-state license, please let us know.</p>
        <p>
          For more information, please call or text{" "}
          <strong>708-312-3331</strong>.
        </p>
       </div>
        <div className="d-flex justify-content-center mt-3">
          <Button variant="primary" onClick={handleClose}>
            Close
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  );
}
